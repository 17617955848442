import { Location } from "@angular/common";
var jsonDiff = require('json-diff');
import * as timezone from 'moment-timezone';


import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { EventEmitter, HostListener, Injectable, ChangeDetectorRef } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Angular2Csv } from "angular2-csv/Angular2-csv";
import * as pluginDataLabels from "chartjs-plugin-datalabels";
import { Ng2FileInputService } from "ng2-file-input";
import { LabelType } from "ng5-slider";
import { ImageCroppedEvent } from "ngx-image-cropper";
import notie from "notie";
import { BehaviorSubject, Observable, throwError, Subscription } from "rxjs";
import { catchError, map } from "rxjs/operators";
import swal from "sweetalert2";
import { isNull, isUndefined } from "util";
import "../../assets/js/systemhelper.js";
import {
  OAccessUser,
  OAccessUserAccount,
  OAccessUserAccountOwner,
  OAccessUserAccountRole,
  OAccessUserAccountRolePermission,
  OAccessUserCountry,
  OAccessUserDevice,
  OAccessUserLocation,
  OAccountOverview,
  OAppUsersOverview,
  OCardTypeSalesSummary,
  OCoreCommon,
  OCoreHelper,
  OCoreParameter,
  OList,
  ORedeemOverview,
  OResponse,
  ORewardOverview,
  ORewardsSummary,
  ORewardTypeOverview,
  OSalesOverview,
  OSalesSummary,
  OSelect,
  OStorageContent,
  OTerminalStatusCount,
  OUserCounts,
  OUserDetails,
} from "./object.service";
declare var $: any;
declare var moment: any;
declare var SystemHelper: any;
import * as cloneDeep from 'lodash/cloneDeep';
import * as lodash from 'lodash';
import { InputFileComponent, InputFile } from 'ngx-input-file';
import { Address } from "ngx-google-places-autocomplete/objects/address";
import { Select2OptionData } from "ng2-select2";
import { networkInterfaces } from "os";
@Injectable()
export class HelperService {
  @HostListener("window:resize", ["$event"])
  public ContainerHeight: number;

  ObjectCreated: BehaviorSubject<boolean> = new BehaviorSubject(false);
  DateChanged: BehaviorSubject<any> = new BehaviorSubject(false);
  CustomerData: BehaviorSubject<any> = new BehaviorSubject(false);
  CashierDetails: BehaviorSubject<any> = new BehaviorSubject(false);
  private StoreAddressTemp = new BehaviorSubject<string>('');
  StoreAdd = this.StoreAddressTemp.asObservable();
  customerDisplayName: any;
  customerReferenceKey: any;
  customerReferenceId: any;
  MerchantID: any;

  updateStoreAddress(_AddressStore) {
    this.StoreAddressTemp.next(_AddressStore);
  }


  onResize(event) {
    this.ContainerHeight = window.innerHeight - 56;
  }
  defaultImage(event) {
    event.target.src = "assets/img/Dummy.png";

  }
  defaultBannerImage(event) {
    // event.target.src = "assets/images/DefaultImg.svg";

  }

  public SystemName = [];

  public VasProductName: string = '';
  public VasRewardPer: number;
  public VasTucCommission: number;
  public StatusName: number;
  public IconUrl: number;
  public UserRewardPercentage: number;
  public VasProductCategoryName: string = '';
  public LoginCheckVar: boolean = true;
  public ManagerBranchKey: string;
  public ManagerBranchId: number;
  public OnBoardMerchant: any = {};
  public OnBoardMerchantStoreMobileNumber: string = '';
  public MerchantReferenceKey: string = '';
  public MerchantReferenceId: string = '';
  public MerchantToken: string = '';
  public MerchantEmailAddress: string = '';
  public MerchantPassword: string = '';
  public StoreDetailParent: string = '';
  public SalesPerformances: boolean;
  navchange: EventEmitter<number> = new EventEmitter();
  BarDatechange: EventEmitter<number> = new EventEmitter();
  selectedLocation: any = {
    address: {},
  };

  public _IsStageActive(stageId: number, statusId: number): boolean {
    var statusIndex: number;
    switch (statusId) {
      case 488: statusIndex = 0;

        break;
      case 489: statusIndex = 1;

        break;
      case 491: statusIndex = 2;

        break;
      case 492: statusIndex = 3;

        break;
      case 493: statusIndex = 5;

        break;
      case 512: statusIndex = 4;

        break;

      default:
        break;
    }

    return (stageId <= statusIndex)

  }


  //#region leafletOperations

  lefletoptions: any = {};
  defaultToDarkFilter = [
    'brightness:100%',
    'invert:100%',
  ];
  layers: any[];
  marker: any;

  public CurrentDealDetails: any = {};
  public CustomerAnalytics: boolean = false;
  public DownloadAnalytics: boolean = false;
  public showAddNewPosBtn: boolean = false;
  public showAddNewStoreBtn: boolean = false;
  public showAddNewCashierBtn: boolean = false;
  public showAddNewSubAccBtn: boolean = false;
  public showSendNotificationBtn: boolean = false;

  public pendingReqCount = 0
  public showAddNewUserBtn: boolean = false;
  public showAddNewRoleBtn: boolean = false;

  public terminalsavecount: number = 0;
  public FullContainer = false;
  public ShowAsidePanel = true;
  public RandomNumber = null;
  public RandomPassword = null;
  public IsFormProcessing = false;
  public ToggleField = false;
  public AccessPin: string;
  public AccessPassword: string;
  public AccessKey: string;
  public PublicKey: string;
  public LoginTime: string;
  public User: OAccessUser;
  public UserAccount: OAccessUserAccount;
  public UserOwner: OAccessUserAccountOwner;
  public UserCountry: OAccessUserCountry;
  public UserLocation: OAccessUserLocation;
  public UserDevice: OAccessUserDevice;
  public UserRoles: Array<OAccessUserAccountRole>;
  public UserRolePermissions: OAccessUserAccountRolePermission[] = []; //Array<OUserAccountRolePermission>;
  public Select2Options_Multiple: Select2Options;
  public Select2Options_Multiple_Location: Select2Options;
  public Select2Options_state: Select2Options;
  public Select2Options_app: Select2Options;
  public Select2Options_city: Select2Options;
  public Select2Options_Single: Select2Options;
  public S2_Sort_Option: Select2Options;
  public Center: any = "center";
  public Select2Options_SearchColumn: Select2Options;
  public StatusSuccess = "Success";
  public StatusError = "Error";
  public CountryCode: any = null;
  DataLoad: boolean = true;

  public UserCountrycode: any;
  public UserCountryId: any;
  public UserCountrykey: any;
  public UserCountryname: any;

  public isDocComplete: boolean = false
  public isAccTypeComplete: boolean = false

  public acquirerProgramDetails: any = null
  public isProgramDetails: boolean = false
  public AppConfig = {
    
    
    // EmailPattern: '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
    EmailPattern: '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',


    //mailformat : '/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/',
    // PayStackKey: "pk_live_4acd36db0e852af843e16e83e59e7dc0f89efe12",
    PayStackKey: "pk_test_4cca266d686312285a54570e20b46a808ae0d0f6",
    TabId: 0,
    ShowMenu: true,
    ConfigurationKey: "rewardpercentage",
    MerchantConfigurations:
    {
      rewardcomissionpercentage: 'rewardcomissionpercentage'
    },
    DefaultStartTimeAll: new Date(2017, 0, 1, 0, 0, 0, 0),
    DefaultEndTimeToday: moment().endOf("day"),
    DefaultStartTime: moment().startOf("day"),
    DefaultEndTime: moment().endOf("day"),
    // CurrencySymbol: "&#8358;",
    CurrencySymbol: "",
    CurrencySymbolCustom: "",
    RangeInvoiceAmountMinimumLimit: 0,
    RangeInvoiceAmountMaximumLimit: 10000000,
    DealMinimumLimit: 0,
    DealMaximumLimit: 10000000,
    DealPurchaseMinimumLimit: 0,
    DealPurchaseMaximumLimit: 10000000,
    TotalAmountMin: 0,
    TotalAmountMax: 10000000,
    ActiveLoanMinLimit: 0,
    ActiveLoanMaxLimit: 10000,
    ClosedLoanMinLimit: 0,
    ClosedLoanMaxLimit: 10000,
    TUCFeesMinLimit: 0,
    TUCFeesMaxLimit: 10000000,
    CreditLimitMin: 0,
    CreditLimitMax: 10000000,
    loanAmountMin: 0,
    loanAmountMax: 10000000,
    paidAmountMin: 0,
    paidAmountMax: 10000000,
    SettlmentRangeMin: 0,
    SettlmentRangeMax: 10000000,
    RangeInvoiceAmountOptions: {
      floor: 0,
      ceil: 10000000,
      draggableRange: true,
      noSwitching: true,
      translate: (value: number, label: LabelType): string => {
        switch (label) {
          case LabelType.Low:
            return (
              "min purchase:" + this.AppConfig.CurrencySymbol + " " + value
            );
          case LabelType.High:
            return (
              "max purchase:</b>" + this.AppConfig.CurrencySymbol + " " + value
            );
          default:
            return this.AppConfig.CurrencySymbol + " " + value;
        }
      },
    },
    RangeAmountMinimumLimit: 0,
    RangeAmountMaximumLimit: 10000000,
    RangeAmountOptions: {
      floor: 0,
      ceil: 10000000,
      draggableRange: true,
      noSwitching: true,
      translate: (value: number, label: LabelType): string => {
        switch (label) {
          case LabelType.Low:
            return "min amount:" + this.AppConfig.CurrencySymbol + " " + value;
          case LabelType.High:
            return (
              "max amount:</b>" + this.AppConfig.CurrencySymbol + " " + value
            );
          default:
            return this.AppConfig.CurrencySymbol + " " + value;
        }
      },
    },
    RangeRewardAmountMinimumLimit: 0,
    RangeRewardAmountMaximumLimit: 10000000,
    RangeRewardAmountOptions: {
      floor: 0,
      ceil: 10000000,
      draggableRange: true,
      noSwitching: true,
      translate: (value: number, label: LabelType): string => {
        switch (label) {
          case LabelType.Low:
            return "min reward:" + this.AppConfig.CurrencySymbol + " " + value;
          case LabelType.High:
            return (
              "max reward:</b>" + this.AppConfig.CurrencySymbol + " " + value
            );
          default:
            return this.AppConfig.CurrencySymbol + " " + value;
        }
      },
    },
    RangeRedeemAmountMinimumLimit: 0,
    RangeRedeemAmountMaximumLimit: 10000000,
    RangeRedeemAmountOptions: {
      floor: 0,
      ceil: 10000000,
      draggableRange: true,
      noSwitching: true,
      translate: (value: number, label: LabelType): string => {
        switch (label) {
          case LabelType.Low:
            return "min redeem:" + this.AppConfig.CurrencySymbol + " " + value;
          case LabelType.High:
            return (
              "max redeem:</b>" + this.AppConfig.CurrencySymbol + " " + value
            );
          default:
            return this.AppConfig.CurrencySymbol + " " + value;
        }
      },
    },
    RangeRewardClaimAmountMinimumLimit: 0,
    RangeRewardClaimAmountMaximumLimit: 10000000,
    RangeRewardClaimAmountOptions: {
      floor: 0,
      ceil: 10000000,
      draggableRange: true,
      noSwitching: true,
      translate: (value: number, label: LabelType): string => {
        switch (label) {
          case LabelType.Low:
            return "min claim:" + this.AppConfig.CurrencySymbol + " " + value;
          case LabelType.High:
            return (
              "max claim:</b>" + this.AppConfig.CurrencySymbol + " " + value
            );
          default:
            return this.AppConfig.CurrencySymbol + " " + value;
        }
      },
    },

    PROVIDERS: [{ id: "", text: "All" }, { id: 'EvolveCredit', text: 'EvolveCredit' }, { id: 'ThankUCash', text: 'ThankUCash' }],

    PTSP: {
      ActiveMerchantReferenceId: null,
      ActiveMerchantReferenceKey: null,
      ActiveTerminalReferenceId: null,
      ActiveTerminalReferenceKey: null,
      ActiveStoreReferenceId: null,
      ActiveStoreReferenceKey: null,
    },
    Partner: {
      ActiveMerchantReferenceId: null,
      ActiveMerchantReferenceKey: null,
      ActiveTerminalReferenceId: null,
      ActiveTerminalReferenceKey: null,
      ActiveStoreReferenceId: null,
      ActiveStoreReferenceKey: null,
    },

    RewardPercentagePermission: null,
    StatusCode: null,
    TerminalPermission: null,
    SubAccountPermission: null,
    StorePermission: null,
    CashiersPermission: null,
    salesanalytics: null,
    Vas: null,
    Invoice: null,
    Loyality: null,
    ActiveOwnerId: null,
    ActiveOwnerAccountCode: null,
    ActiveOwnerAccountTypeCode: null,
    ActiveOwnerAccountTypeId: null,
    ActiveOwnerKey: null,
    ActiveOwnerDisplayName: null,
    ActiveOwnerIsTucPlusEnabled: null,
    ActiveOwnerRoleKey: null,

    ActiveAccountKey: null,
    ActiveReferenceAccountTypeCode: null,
    ActiveReferenceAccountTypeId: null,
    ActiveReferenceId: null,
    ActiveReferenceKey: null,
    RootCategoryId: null,
    RootCategoryKey: null,
    ActiveAccountId: null,
    ActiveMerchantReferenceId: null,
    ActiveProviderReferenceId: null,
    ActiveMerchantReferenceKey: null,
    ActiveSubAccountReferenceId: null,
    ActiveSubAccountReferenceKey: null,
    ActiveTerminalReferenceId: null,
    ActiveTerminalReferenceKey: null,
    ActiveStoreReferenceId: null,
    ActiveStoreReferenceKey: null,

    ActiveCashierReferenceId: null,
    ActiveCashierReferenceKey: null,

    ActiveBranchReferenceId: null,
    ActiveBranchReferenceKey: null,
    ActiveCustomerReferenceId: null,
    ActiveCustomerReferenceKey: null,
    ActiveManagerReferenceId: null,
    ActiveManagerReferenceKey: null,

    ActiveCampaignReferenceKey: null,
    ActiveCampaignReferenceId: null,

    ActiveReferenceListType: null,
    ActiveReferenceDisplayName: null,
    FlagStatusCode: {
      Green: "accountflag.green",
      Orange: "accountflag.orange",
      Red: "accountflag.red",
      Yellow: "accountflag.yellow",
      Black: "accountflag.black",
      Purple: "accountflag.purple",
    },
    ActiveSubReferenceKey: null,
    ActivePasswordResetRequest: null,
    ShowHeader: true,
    ActivePageName: "",
    Client: null,
    ClientHeader: {
      "Content-Type": "application/json; charset=utf-8",
      hcak: null,
      hcavk: null,
      hctk: null,
      hcudlt: null,
      hcudln: null,
      hcuak: null,
      hcupk: null,
      hcudk: null,
      hcuata: "",
      hcuatp: "",
    },
    Host: "",
    HostConnect: "",
    DealsWebSiteUrl: "",
    MediaManagerConnect: "",
    FolderNestingLevel: 3,
    DealBannerHeight: 500,
    DealBannerWidth: 700,
    DefaultPassword: "Welcome@ThankU",
    WebsiteUrl: "",
    AppName: "",
    AppVersion: "",
    TimeZone: "Africa/Lagos",
    TimeFormat: "h:mm a",
    DateDayFormat: "DD-MM",
    DateMonthFormat: "MM-YY",
    DateYearFormat: "YYYY",
    DateFormat: "DD-MM-YYYY",
    // DateTimeFormat: "DD-MM-YYYY HH:mm",
    // DateTimeFormat: "YYYY-MM-DD hh:mm a",
    DateTimeFormat: "DD-MM-YYYY hh:mm a",
    DateTimeFormatPromote: "DD-MM-YYYY HH:mm",
    DateTimeFormatForChart: "YYYY-MM-DD HH:mm:ss",
    DateTimeFormatForChartX: "DD MMM",
    //DateTimeFormat: "DD MMM YYYY h:mm a",
    //DateTimeFormatShort: "DD-MM-YYYY h:mm a",
    AppKey: "33f51da41c95415fba82ff8a9f4981a5",
    AppVersionKey: "18da648a59024f2ba93e2e07319b1764",
    DropDownListLimit: 1000,
    BooleanFalse:false,
    BooleanTrue:true,
    ListLimit: 4000,
    LimitFilter:20,
    SearchInputDelay: 1000,
    ListRecordLimit: [10, 25, 30, 50, 90, 100],
    ListRecordLimitC: [9, 10, 25, 30, 50, 90, 100],
    Center: "center",
    Alert_Position: this.Center,
    Alert_Animation: "",
    Alert_AllowAnimation: false,
    Alert_AllowOutsideClick: false,
    Alert_AllowEscapeKey: false,
    Color_Green: "#22b66e",
    Color_Primary: "#0368FA",
    Color_Red: "#f14d4d",
    Color_Grey: "#d1dade",
    Color_Blue: '#0168fa',
    StoreDetailParents: {
      Merchant: 'merchant',
      Transactions: 'transaction',
      Reward: 'reward',
      Redeem: 'redeem',
      claim: 'claim'
    },
    DatePickerTypes: {
      year: 'year',
      Rewardyear: 'rewardyear',
      month: 'month',
      week: 'week',
      day: 'day',
      hour: 'hour'
    },
    GraphTypes: {
      year: 'year',
      month: 'month',
      week: 'week',
      day: 'day',
      hour: 'hour'
    },
    TerminalTypes: {
      all: 'all',
      active: 'active',
      idle: 'idle',
      dead: 'dead',
      unused: 'unused'
    },
    CustomerTypes: {
      all: 'all',
      new: 'new',
      loyal: 'loyal',
      lost: 'lost',
      appdownloads: 'appdownloads',
      merchant: 'merchant',
      thankucash: 'thankucash'

    },
    TablesConfig: {
      DefaultClass: "table  table-hover mb-0",
      InfoClass: "fa fa-info-circle text-warn",
    },
    HostDomain: {
      App: "app.thankucash.com",
      Console: "console.thankucash.com",
      Merchant: "merchant.thankucash.com",
      Store: "store.thankucash.com",
      Cashier: "cashier.thankucash.com",
    },
    Icon_Cropper_Options: {
      MaintainAspectRatio: "true",
      AspectRatio: "1 / 1",
      MinimumWidth: 128,
      MinimumHeight: 128,
      ResizeToWidth: 128,
      Format: "png",
    },
    DealIcon_Cropper_Options: {
      MaintainAspectRatio: "true",
      AspectRatio: "1 / 1",
      MinimumWidth: 800,
      MinimumHeight: 400,
      MaximumWidth: 800,
      MaximumHeight: 400,
      ResizeToWidth: 800,
      Format: "png",
    },

    ImageGallery_Cropper_Options: {
      MaintainAspectRatio: "true",
      AspectRatio: "1 / 1",
      MinimumWidth: 0,
      MinimumHeight: 0,
      MaximumWidth: 0,
      MaximumHeight: 0,
      ResizeToWidth: 0,
      ResizeToHeight: 0,
      Format: "png",
    },

    Slider_Cropper_Options: {
      MaintainAspectRatio: "true",
      AspectRatio: "1 / 1",
      MinimumWidth: 800,
      MinimumHeight: 450,
      MaximumWidth: 800,
      MaximumHeight: 450,
      ResizeToWidth: 800,
      Format: "png",
    },
    Poster_Cropper_Options: {
      MaintainAspectRatio: "true",
      AspectRatio: "19 / 9",
      MinimumWidth: 800,
      MinimumHeight: 450,
      ResizeToWidth: 800,
      Format: "png",
    },
    DateRangeOptionsWithYear: {

      locale: {
        format: "DD-MM-YYYY",
        //  autoApply:true,
      },
      alwaysShowCalendars: false,
      showCustomRangeLabel: false,
      autoApply: true,
      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),
      dateLimit: {
        month: 1,
      },
      // minDate: moment().subtract(30, "days"),
      // maxDate: moment().add(30, "days"),
      // startDate: moment().startOf("month"),
      // endDate: moment().endOf("month"),
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "This Week": [moment().startOf("week").add("day", 1), moment().endOf("week").add("day", 1)],
        "Last Week": [
          moment().subtract(1, "weeks").startOf("isoWeek"),
          moment().subtract(1, "weeks").endOf("isoWeek"),
        ],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
          moment().startOf("month").subtract(1, "month"),
          moment().startOf("month").subtract(1, "days"),
        ],
        "This Year": [
          moment().startOf("year").startOf("day"),
          moment().endOf("year").endOf("day"),
        ],
        "Last Year": [
          moment().subtract(1, "year").startOf("year").startOf("day"),
          moment().subtract(1, "year").endOf("year").endOf("day"),
        ],
        // All: [new Date(2017, 0, 1, 0, 0, 0, 0), moment()],
      },
    },
    DateRangeOptionsMonth: {
      locale: { format: "YYYY-MM-DD" },
      alwaysShowCalendars: false,
      startDate: moment().startOf("month"),
      endDate: moment().endOf("month"),
      dateLimit: {
        month: 1,
      },
      // minDate: moment().subtract(30, "days"),
      // maxDate: moment().add(30, "days"),
      // startDate: moment().startOf("month"),
      // endDate: moment().endOf("month"),
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "This Week": [moment().startOf("week"), moment().endOf("week")],
        "Last Week": [
          moment().subtract(1, "weeks").startOf("isoWeek"),
          moment().subtract(1, "weeks").endOf("isoWeek"),
        ],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 15 Days": [moment().subtract(14, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
          moment().startOf("month").subtract(1, "month"),
          moment().startOf("month").subtract(1, "days"),
        ],
        // All: [new Date(2017, 0, 1, 0, 0, 0, 0), moment()]
      },
    },
    DateRangeOptionsRDate: {
      locale: {
        format: "DD-MM-YYYY",
      },
      alwaysShowCalendars: false,
      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),
      autoUpdateInput: false,
      drops: "up",
      dateLimit: {
        month: 1,
      },
      // minDate: moment().subtract(30, "days"),
      // maxDate: moment().add(30, "days"),
      // startDate: moment().startOf("month"),
      // endDate: moment().endOf("month"),
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "This Week": [moment().startOf("week").add("day", 1), moment().endOf("week").add("day", 1)],
        "Last Week": [
          moment().subtract(1, "weeks").startOf("isoWeek"),
          moment().subtract(1, "weeks").endOf("isoWeek"),
        ],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 15 Days": [moment().subtract(14, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
          moment().startOf("month").subtract(1, "month"),
          moment().startOf("month").subtract(1, "days"),
        ],
        All: [new Date(2017, 0, 1, 0, 0, 0, 0), moment()],
      },
    },
    DateRangeOptionsFn: function (id) {
      return { ...this.AppConfig.DateRangeOptions, parentEl: id }
    }.bind(this),
    DateRangeOptions: {
      parentEl: "#date_rangeoptionss",
      locale: {
        format: "DD-MM-YYYY",
        dayOfWeekStart: 0,
      },
      alwaysShowCalendars: false,
      startDate: moment().subtract(29, "days"),
      endDate: moment().endOf("day"),
      linkedCalendars: false,
      // dateLimit: {
      //   month: 3,
      // },

      // minDate: moment().subtract(30, "days"),
      // maxDate: moment().add(30, "days"),
      // startDate: moment().startOf("month"),
      // endDate: moment().endOf("month"),
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "This Week": [moment().startOf("week").add("day", 1), moment().endOf("week").add("day", 1)],
        "Last Week": [
          moment().subtract(1, "weeks").startOf("Week").add("day", 1),
          moment().subtract(1, "weeks").endOf("Week").add("day", 1),
        ],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 15 Days": [moment().subtract(14, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
          moment().startOf("month").subtract(1, "month"),
          moment().startOf("month").subtract(1, "days"),
        ],
        All: [new Date(2017, 0, 1, 0, 0, 0, 0), moment()],
      },
    },
    DateRangeOptionsFnAll: function (id) {
      return { ...this.AppConfig.DateRangeOptionsAll, parentEl: id }
    }.bind(this),
    DateRangeOptionsAll: {
      locale: {
        format: "DD-MM-YYYY",
        dayOfWeekStart: 0,
      },
      alwaysShowCalendars: false,
      startDate: new Date(2017, 0, 1, 0, 0, 0, 0),
      endDate: moment(),
      // dateLimit: {
      //   month: 3,
      // },

      // minDate: moment().subtract(30, "days"),
      // maxDate: moment().add(30, "days"),
      // startDate: moment().startOf("month"),
      // endDate: moment().endOf("month"),
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "This Week": [moment().startOf("week").add("day", 1), moment().endOf("week").add("day", 1)],
        "Last Week": [
          moment().subtract(1, "weeks").startOf("Week").add("day", 1),
          moment().subtract(1, "weeks").endOf("Week").add("day", 1),
        ],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 15 Days": [moment().subtract(14, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
          moment().startOf("month").subtract(1, "month"),
          moment().startOf("month").subtract(1, "days"),
        ],
        All: [new Date(2017, 0, 1, 0, 0, 0, 0), moment()],
      },
    },
    DateRangeOptionsCustom: {

      locale: {
        format: "DD-MM-YYYY",
        //  autoApply:true,
      },
      alwaysShowCalendars: false,
      showCustomRangeLabel: false,
      autoApply: true,
      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),
      dateLimit: {
        month: 1,
      },
      // minDate: moment().subtract(30, "days"),
      // maxDate: moment().add(30, "days"),
      // startDate: moment().startOf("month"),
      // endDate: moment().endOf("month"),
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "This Week": [moment().startOf("week").add("day", 1), moment().endOf("week").add("day", 1)],
        "Last Week": [
          moment().subtract(1, "weeks").startOf("isoWeek"),
          moment().subtract(1, "weeks").endOf("isoWeek"),
        ],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
          moment().startOf("month").subtract(1, "month"),
          moment().startOf("month").subtract(1, "days"),
        ],
        "This Year": [
          moment().startOf("year").startOf("day"),
          moment().endOf("year").endOf("day"),
        ],
        "Last Year": [
          moment().subtract(1, "year").startOf("year").startOf("day"),
          moment().subtract(1, "year").endOf("year").endOf("day"),
        ],
        // All: [new Date(2017, 0, 1, 0, 0, 0, 0), moment()],
      },
    },

    DateRangeOptionsCustomnew: {

      locale: {
        format: "DD-MM-YYYY",
        //  autoApply:true,
      },
      alwaysShowCalendars: false,
      showCustomRangeLabel: false,
      autoApply: true,
      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),
      dateLimit: {
        month: 1,
      },
      // minDate: moment().subtract(30, "days"),
      // maxDate: moment().add(30, "days"),
      // startDate: moment().startOf("month"),
      // endDate: moment().endOf("month"),
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "This Week": [moment().startOf("week").add("day", 1), moment().endOf("week").add("day", 1)],
        "Last Week": [
          moment().subtract(1, "weeks").startOf("isoWeek"),
          moment().subtract(1, "weeks").endOf("isoWeek"),
        ],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
          moment().startOf("month").subtract(1, "month"),
          moment().startOf("month").subtract(1, "days"),
        ],
        // "This Year": [
        //   moment().startOf("year").startOf("day"),
        //   moment().endOf("year").endOf("day"),
        // ],
        // "Last Year": [
        //   moment().subtract(1, "year").startOf("year").startOf("day"),
        //   moment().subtract(1, "year").endOf("year").endOf("day"),
        // ],
        // All: [new Date(2017, 0, 1, 0, 0, 0, 0), moment()],
      },
    },
    DateRangeOptionsCustomWYear: {

      locale: {
        format: "DD-MM-YYYY",
        //  autoApply:true,
      },
      alwaysShowCalendars: false,
      showCustomRangeLabel: true,
      autoApply: false,
      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),
      maxDate: moment(),
      dateLimit: {
        month: 1,
      },

      // minDate: moment().subtract(30, "days"),
      // maxDate: moment().add(30, "days"),
      // startDate: moment().startOf("month"),
      // endDate: moment().endOf("month"),
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "This Week": [moment().startOf("week").add("day", 1), moment().endOf("week").add("day", 1)],
        "Last Week": [
          moment().subtract(1, "weeks").startOf("isoWeek"),
          moment().subtract(1, "weeks").endOf("isoWeek"),
        ],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
          moment().startOf("month").subtract(1, "month"),
          moment().startOf("month").subtract(1, "days"),
        ],

      },
    },
    GrowthRevenueDateRangeOptionsCustom: {
      locale: { format: "DD-MM-YYYY" },
      alwaysShowCalendars: false,
      showCustomRangeLabel: false,
      startDate: moment().subtract(6, "days").startOf('day'),
      endDate: moment().endOf('day'),
      dateLimit: {
        month: 1,
      },

      ranges: {
        "Last 6 Days": [moment().subtract(6, "days").startOf('day'), moment().endOf('day')],
        // Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "This Week": [moment().startOf("week").add("day", 1), moment().endOf("week").add("day", 1)],
        "Last Week": [
          moment().subtract(1, "weeks").startOf("isoWeek"),
          moment().subtract(1, "weeks").endOf("isoWeek"),
        ],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
          moment().startOf("month").subtract(1, "month"),
          moment().startOf("month").subtract(1, "days"),
        ],
        "This Year": [
          moment().startOf("year").startOf("day"),
          moment().endOf("year").endOf("day"),
        ],
        "Last Year": [
          moment().subtract(1, "year").startOf("year").startOf("day"),
          moment().subtract(1, "year").endOf("year").endOf("day"),
        ],
        // All: [new Date(2017, 0, 1, 0, 0, 0, 0), moment()],
      },
    },

    DateRangeMonthOptions: {
      locale: { format: "YYYY-MM-DD" },
      alwaysShowCalendars: false,
      startDate: moment().startOf("month").subtract(6, "month"),
      endDate: moment().endOf("month"),
      dateLimit: {
        month: 12,
      },

      ranges: {
        "This Week": [moment().startOf("week"), moment().endOf("week")],
        "Last Week": [
          moment().subtract(1, "weeks").startOf("isoWeek"),
          moment().subtract(1, "weeks").endOf("isoWeek"),
        ],
        // "Last 2 Weeks": [moment().subtract(14, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "Last 3 Months": [
          moment().startOf("month").subtract(3, "month"),
          moment().endOf("month"),
        ],
        "Last 6 Months": [
          moment().startOf("month").subtract(6, "month"),
          moment().endOf("month"),
        ],
        "This Year": [moment().startOf("year"), moment().endOf("year")],
        "Last Year": [
          moment().startOf("year").subtract(1, "year"),
          moment().endOf("year").subtract(1, "year"),
        ],
        "Last 3 Year": [
          moment().startOf("year").subtract(3, "year"),
          moment().endOf("year"),
        ],
        "Last 6 Year": [
          moment().startOf("year").subtract(6, "year"),
          moment().endOf("year"),
        ],
        All: [new Date(2017, 0, 1, 0, 0, 0, 0), moment()],
      },
    },
    DateRangeFutureOptions: {
      parentEl: "#futureOptions",
      locale: { format: "DD-MM-YYYY" },
      alwaysShowCalendars: false,
      autoUpdateInput: false,
      startDate: this.DateInUTC(moment()),
      endDate: this.DateInUTC(moment().endOf("month")),
      minDate: moment(),
      ranges: {
        "This Week": [this.DateInUTC(moment().startOf("week")), this.DateInUTC(moment().endOf("week"))],
        "Next Week": [
          this.DateInUTC(moment().add(1, "weeks").startOf("isoWeek")),
          this.DateInUTC(moment().add(1, "weeks").endOf("isoWeek")),
        ],
        "Next 15 Days": [this.DateInUTC(moment()), this.DateInUTC(moment().add(14, "days"))],
        "Next 30 Days": [this.DateInUTC(moment()), this.DateInUTC(moment().add(29, "days"))],
        "This Month": [this.DateInUTC(moment().startOf("month")), this.DateInUTC(moment().endOf("month"))],
        "Next Month": [
          this.DateInUTC(moment().startOf("month").add(1, "month")),
          this.DateInUTC(moment().endOf("month").add(1, "month")),
        ],
      },
    },
    DateFuterPickerOptions: {
      singleDatePicker: true,
      timePicker: false,
      locale: { format: "DD-MM-YYYY" },
      alwaysShowCalendars: false,
      showDropdowns: true,
      startDate: this.DateInUTC(moment().startOf("day")),
      endDate: this.DateInUTC(moment().endOf("day")),
      minDate: new Date(),

    },
    DateFuterPickerOptionsTime: {
      singleDatePicker: true,
      timePicker: true,
      locale: { format: "DD-MM-YYYY" },
      alwaysShowCalendars: false,
      showDropdowns: true,
      startDate: this.DateInUTC(moment().startOf("day")),
      endDate: this.DateInUTC(moment().endOf("day")),
      minDate: new Date(),

    },
    DateFuterPickerOptionsTime_Campaign: {
      singleDatePicker: true,
      timePicker: true,
      locale: { format: "DD-MM-YYYY hh:mm:ss" },
      alwaysShowCalendars: false,
      showDropdowns: true,
      startDate: this.DateInUTC(moment().startOf("day")),
      endDate: this.DateInUTC(moment().endOf("day")),
      minDate: new Date(),

    },
    DatePickerOptions: {
      singleDatePicker: true,
      locale: { format: "DD-MM-YYYY" },
      alwaysShowCalendars: false,
      showDropdowns: true,
      startDate: this.DateInUTC(moment().startOf("day")),
      endDate: this.DateInUTC(moment().endOf("day")),
      minYear: 1901,
      maxYear: parseInt(moment().format("YYYY"), 10),
      maxDate: this.DateInUTC(moment()),
    },

    DatePickerOptionsForToday: {
      singleDatePicker: true,
      locale: { format: "DD-MM-YYYY" },
      alwaysShowCalendars: false,
      showDropdowns: true,
      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),
      minYear: 1901,
      maxYear: parseInt(moment().format("YYYY"), 10),
      maxDate: moment(),
    },

    DateRangeOptionsForYear: {
      locale: {
        format: "DD-MM-YYYY",
        dayOfWeekStart: 0,
      },
      alwaysShowCalendars: false,
      startDate: moment().subtract(1, "year").startOf("year").startOf("day"),
      endDate: moment(),
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "This Week": [moment().startOf("week").add("day", 1), moment().endOf("week").add("day", 1)],
        "Last Week": [
          moment().subtract(1, "weeks").startOf("Week").add("day", 1),
          moment().subtract(1, "weeks").endOf("Week").add("day", 1),
        ],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 15 Days": [moment().subtract(14, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
          moment().startOf("month").subtract(1, "month"),
          moment().startOf("month").subtract(1, "days"),
        ],
        "Last Year": [
          moment().subtract(1, "year").startOf("year").startOf("day"),
          moment().subtract(1, "year").endOf("year").endOf("day"),
        ],
        All: [new Date(2017, 0, 1, 0, 0, 0, 0), moment()],
      },
    },

    DateRangeOptionsForYeargiftcard: {
      locale: {
        format: "DD-MM-YYYY",
        dayOfWeekStart: 0,
      },
      alwaysShowCalendars: false,
      // startDate: moment().subtract(1, "year").startOf("year").startOf("day"),
      startDate: moment().startOf("year").startOf("day"),
      endDate: moment(),
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "This Week": [moment().startOf("week").add("day", 1), moment().endOf("week").add("day", 1)],
        "Last Week": [
          moment().subtract(1, "weeks").startOf("Week").add("day", 1),
          moment().subtract(1, "weeks").endOf("Week").add("day", 1),
        ],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 15 Days": [moment().subtract(14, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
          moment().startOf("month").subtract(1, "month"),
          moment().startOf("month").subtract(1, "days"),
        ],
        "Last Year": [
          moment().subtract(1, "year").startOf("year").startOf("day"),
          moment().subtract(1, "year").endOf("year").endOf("day"),
        ],
        All: [new Date(2017, 0, 1, 0, 0, 0, 0), moment()],
      },
    },

    ListToggleOption: {
      SortApply: "sortapply",
      SortOrder: "sortorder",
      CancelFilter: "cancelfilter",
      ApplyFilter: "applyfilter",
      SortReset: "sortreset",
      ToggleFilter: "togglefilter",
      Visiblity: "visiblity",
      Limit: "limit",
      Refresh: "refresh",
      ResetOffset: "resetoffset",
      Reset: "reset",
      Sort: "sort",
      Status: "status",
      Page: "page",
      Search: "search",
      Date: "date",
      DateRange: "daterange",
      Csv: "csv",
      SearchColumnChange: "searchcolumnchange",
      ColumnSearch: "columnsearch",
      MultiSelect: "multiselect",
      Other: "other",
      SalesRange: 'salesrange',
      // Specifics
      DateExpiry: "dateexpiry", //sold deal history
    },
    FilterTypeOption: {
      LoyaltyProgramMerchant: 'loyaltyprogrammerchant',
      LoyaltyProgramTransaction: 'loyaltyprogramtransaction',
      GetShipment: 'getshipment',
      AdminUsers: 'adminAdminUsers',
      AdminRoles: 'adminAdminRoles',
      Merchant: "adminMerchants",
      MerchantAcq: "MerchantAcq",
      SubAccounts: "adminSubaccounts",
      PartnerMerchants: "partnerMerchants",
      PartnerCustomers: "partnerCustomers",
      PartnerBulkMerchantUpload: "partnerBulkMerchantUpload",
      PartnerBulkCustomerUpload: "partnerBulkCustomerUpload",
      PartnerBulkCustomerFileUpload: "partnerBulkCustomerFileUpload",

      Terminal: "adminTerminal",
      Stores: "adminStores",
      Deals: "admindeals",
      Branch: "adminBranch",
      Manager: "adminManager",
      Campaign: "adminCampaign",
      giftcard: "adminGiftcard",
      MerchantSales: "adminMerchantSales",
      CustomerSale: "customerSale",
      AirtimeSales: "airtimesales",
      ElectricSales: "electricsales",
      TollGateSales: "tollsales",
      TVSales: "tvsales",
      SubAccount: "adminSubAccount",
      Variant: 'adminVariant',
      LogFeature: 'logFeature',
      Cashier: 'adminCashier',
      Customer: 'adminCustomer',
      RewardClaim: 'adminRewardClaim',
      Order: 'adminOrder',
      Product: 'adminProduct',
      Inventory: 'adminInventory',
      Session: 'adminSession',
      Usage: 'adminUsage',
      Version: 'adminVersion',
      Payments: 'adminPayment',
      Supercash: 'adminSupercash',
      CustomerCashout: 'customerCashout',
      MerchantCashout: 'merchantCashout',
      Invoices: 'adminInvoice',
      SoldHistory: 'adminSoldHistory',
      Acquirer: 'adminAcquirer',
      NotifHistory: 'adminNotifHistory',
      FaqCategory: 'adminFaqCategory',
      Faq: 'adminFaq',
      LoanRequest: 'loanRequest',
      LoanActive: 'loanActive',
      LoanDefault: 'loanDefault',
      LoanClosed: 'loanClosed',
      LoanAll: 'loanAll',
      EligibleCustomer: 'eligibleCustomer',
      BnplMerchant: 'bnplMerchant',
      LoanOverdue: 'loanOverdue',
      LoanPaid: 'loanPaid',
      Settlment_Filter: "Settlment_Filter",
      LoanHistory: 'loanHistory',
      Transaction: 'transaction',
      OrderHistory: 'orderHistory',
      GiftCardTransactions: "adminGiftCardTransactions",
      IssuedGiftCard: "adminIssuedGiftCard",
      GiftCardRedeems: "adminGiftCardRedeems",
      DealSubCategory: "DealSubCategory",
      LoginHistory: 'loginHistory',
      RootCategory: 'rootCategory',
      CampCustomerHistory: 'campCustomerHistory',
      merchantTopUpHistory: "merchantTopUpHistory",
      UserSessionHistory: "UserSessionHistory",
      customerRewardHistory: "customerRewardHistory",
      merchant_customerRedeemHistory: "merchant_customerRedeemHistory",
      merchant_customepurchaseHistory: "merchant_customepurchaseHistory",
      customerVasHistory: "customerVasHistory",
      customerBuyHistory: "customerBuyHistory",
      Reward_History: "rewardHistory",
      LoyaltySales: 'loyaltysales',
      GroupLoyaltyTransaction: 'grouployaltytransaction',
      GroupLoyaltyRewardTransaction: 'grouployaltyrewardtransaction',
      GroupLoyaltyRedeemTransaction: 'grouployaltyredeemtransaction',
      console_cashout: "console_cashout",
      Request_History: "requestHistory",
      Loyalty_Reward: "loyaltyreward",
      Loyalty_Redeem: "loyaltyredeem",
      Loyalty_Pending_Reward: "loyaltypendingreward",
      TUC_SUPER_CASH: "tucsupercash",
      REFUND: "refund"
    },
    OtherFilters: {
      SubAdmin: {
        Role: "role"
      },
      Merchant: {
        Owner: "owner"
      },
      Terminal: {
        Merchant: "merchant",
        Store: "store",
        Provider: "provider",
        Bank: 'bank'
      },
      Stores: {
        Owner: "owner"
      },
      Branch: {

      },
      Manager: {
        Branch: "branch",
        Owner: "owner"
      },
      Campaign: {

      },

      MerchantSales: {
        Merchant: "merchant",
        Partner:"partner",
        Carrier:"carrier",
        Store: "store",
        CardBank: "cardbank",
        TransactionType: "transactiontype",
        CardBrand: "cardbrand",
        Provider: "provider",
        Cashier: "cashier",
        Terminal: "terminal",
        Bank: "bank"
      },
      customerRewardHistory: {
        Merchant: "merchant",
        Store: "store",
        CardBank: "cardbank",
        TransactionType: "transactiontype",
        CardBrand: "cardbrand",
        Provider: "provider",
        Cashier: "cashier",
        Terminal: "terminal",
        Bank: "bank"
      },


      CustoemrSale: {
        Merchant: "merchant",
        Store: "store",
        CardBank: "cardbank",
        TransactionType: "transactiontype",
        CardBrand: "cardbrand",
        Provider: "provider",
        Cashier: "cashier",
        Terminal: "terminal",
        Bank: "bank"
      },


      AirtimeSales: {
        Merchant: "merchant",
        Store: "store",
        CardBank: "cardbank",
        TransactionType: "transactiontype",
        CardBrand: "cardbrand",
        Provider: "provider",
        Cashier: "cashier",
        Terminal: "terminal",
        Bank: "bank"
      },
      ElectricSales: {
        Merchant: "merchant",
        Store: "store",
        CardBank: "cardbank",
        TransactionType: "transactiontype",
        CardBrand: "cardbrand",
        Provider: "provider",
        Cashier: "cashier",
        Terminal: "terminal",
        Bank: "bank"
      },
      TVSales: {
        Merchant: "merchant",
        Store: "store",
        CardBank: "cardbank",
        TransactionType: "transactiontype",
        CardBrand: "cardbrand",
        Provider: "provider",
        Cashier: "cashier",
        Terminal: "terminal",
        Bank: "bank"
      },
      TollGateSales: {
        Merchant: "merchant",
        Store: "store",
        CardBank: "cardbank",
        TransactionType: "transactiontype",
        CardBrand: "cardbrand",
        Provider: "provider",
        Cashier: "cashier",
        Terminal: "terminal",
        Bank: "bank"
      },
    },
    ValidatorRegex: {
      Password:
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
      // WebsiteUrl: "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?",
      WebsiteUrl: "(https?://)?[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]*/?",
      WebsiteUrl1: "^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$"
    },
    Storage: {
      OReqH: "hcscoreqh",
      Device: "hcscd",
      Location: "hcsl",
      Account: "hca",
      RegInfo: "reginfo",
      Permissions: "hcap",
      ImageStorage: "hcimgstorage",
      ImageStoragePoster: "hcimgstorageposter",
      ActiveAppUser: "hcactiveappuser",
      ActiveMerchant: "hcactivemerchant",
      ActiveProvider: "hcactiveprovider",
      ActiveState: "hcactivestate",
      ActiveCampaign: "hcactivecampaign",
      ActiveStore: "hcactivestore",
      ActiveLoan: "hcactiveloan",
      ActiveBranch: "hcactivebranch",
      ActiveManager: "hcactivemanager",
      ActiveTerminal: "hcactiveterminal",
      ActivePgAccount: "hcactivepgaccount",
      ActivePosAccount: "hcactiveposaccount",
      ActiveAcquirer: "hcactiveacquirer",
      ActiveCashier: "hcactivecashier",
      ActiveSubAccount: "hcactivesubaccount",
      ActiveCustomer: "hcactivecustomer",
      ActiveProduct: "hcactiveproduct",
      ActiveCity: "hcactivecity",
      ActiveRootCategory: "hcarootcategory",
      StoreRegistrationData: 'hcstoreregistrationdata',
      VerifyNumber: "hcverifynumber",
      IconUrl: "IconUrl",
      IconImage: "iconimage",
      ActiveDeal: "ActiveDeal",
      ActiveApp: "ActiveApp",
      VasProduct: "VasProduct",
      MerchantPlan: "merchantplan",
      MerchantSettlment: "merchantsettlement",
      ActiveMerchantRootCategory: "hcamerchantrootcategory",
      ActiveDealRootCategory: "hcadealrootcategory",
      EditRole: "editrole"
    },
    HelperTypes: {
      Os: "hcore.os",
      Gender: "hcore.gender",
      Configuration: "hcore.configuration",
      ConfigurationValue: "hcore.configurationvalue",
      SettelementTypeCode: "daysafterredeem",
      ResponseCode: "hcore.responsecodes",
      AccountType: "hcore.accounttype",
      AccountOperationType: "hcore.accountoperationtype",
      RegistrationSource: "hcore.regsource",
      App: "hcore.app",
      AppVersion: "hcore.appversion",
      Api: "hcore.api",
      Feature: "hcore.feature",
      FeatureOption: "hcore.featureoption",
      FeatureApi: "hcore.featureapi",
      Role: "hcore.role",
      RoleFeature: "hcore.rolefeatures",
      RoleAccess: "hcore.roleaccess",
      TransactionType: "hcore.transactiontype",
      TransactionTypeReward: "reward",
      TransactionTypeRedeem: "redeem",
      CardBrand: "cardbrand",
      CardSubBrand: "cardsubbrand",
      CardType: "cardtype",
      CardBank: "cardbank",
      MerchantCategories: "merchantcategories",
      DealCategories: "dealcategories",
      SliderImage: "sliderimage",
      InvoiceType: "hcore.invoicetype",
      FAQCategories: "faqcategories",
      FAQs: "faqs",
    },
    DataType: {
      String: "text",
      Text: "text",
      Number: "number",
      Date: "date",
      Decimal: "decimal",
    },
    SystemDataType: {
      Text: "datatype.text",
      Number: "datatype.number",
      Date: "datatype.date",
      Boolean: "datatype.boolean",
      Decimal: "datatype.decimal",
    },
    Gender: {
      Male: "gender.male",
      Female: "gender.female",
      Other: "gender.other"
    },


    Campaign: {
      OneDay: "camapigntype.oneday",
      HourlyCampaign: "camapigntype.hourlycampaign",
      WeeklyCampaign: "camapigntype.weeklycampaign",
      CustomCampaign: "camapigntype.customcampaign",
    },

    AccountBookmark: {
      NonBookmarkId: 407,
      NonBookmark: "accountlevel.nonbookmark",
      BookmarkId: 406,
      Bookmark: "accountlevel.bookmark",
    },
    AccountFlag: {
      GreenId: 400,
      Green: "accountflag.green",
      OrangeId: 401,
      Orange: "accountflag.orange",
      RedId: 402,
      Red: "accountflag.red",
      YellowId: 403,
      Yellow: "accountflag.yellow",
      BlackId: 404,
      Black: "accountflag.black",
      PurpleId: 405,
      Purple: "accountflag.purple",
    },
    AccountType: {
      Merchanthistory: "merchant",
      Customer: "customer",
      Deal: "deal",
      Controller: "controller",
      ControllerId: 106,
      PTSPID: 114,
      Admin: "admin",
      AppUser: "appuser",
      AppUserId: 109,
      CardUser: "carduser",
      Merchant: "thankumerchant",
      MerchantId: 106,
      Store: "merchantstore",
      StoreId: 111,
      Manager: "manager",
      Branch: "branch",
      Campaign: "campaign",
      StoreSubAccount: "merchantstoresubaccount",
      Cashier: "merchantcashier",
      SubAccount: "merchantsubaccount",
      PGAccount: "pgaccount",
      PosAccount: "posaccount",
      PosSubAccount: "possubaccount",
      PosTerminal: "posterminal",
      PosTerminalId: 115,
      Acquirer: "acquirer",
      AcquirerId: 116,
      AcquirerSubAccount: "acquirersubaccount",
      MerchantSubAccount: "merchantsubaccount",
      RelationshipManager: "relationshipmanager",
      Product: "product",
      Ambassador: "ambassador",
      Partner: "partner",
      LoanProvider: "loanprovider"
    },
    ListType: {
      All: "all",
      Owner: "owner",
      Branch: "branch",
      SubOwner: "subowner",
      Bank: "bank",
      CreatedBy: "createdby",
      CreatedByOwner: "createdbyowner",
      RM: "rm",
      Manager: "manager",
      Controller: "controller",
      Admin: "admin",
      AppUser: "appuser",
      Merchant: "thankumerchant",
      Store: "merchantstore",
      StoreSubAccount: "merchantstoresubaccount",
      Cashier: "merchantcashier",
      Provider: "provider",
      PosSubAccount: "possubaccount",
      PosTerminal: "posterminal",
      Acquirer: "acquirer",
      AcquirerSubAccount: "acquirersubaccount",
      MerchantSubAccount: "merchantsubaccount",
      RelationshipManager: "relationshipmanager",
    },
    AccountOperationType: {
      Online: "accountoperationtype.online",
      Offline: "accountoperationtype.offline",
      OnlineAndOffline: "accountoperationtype.onlineandoffline",
    },
    RegistrationSource: {
      System: "regsource.system",
    },
    Status: {
      Active: "default.active",
      Inactive: "default.inactive",
      Suspended: "default.suspended",
      Blocked: "default.blocked",
      External: "external",
      TUC: "tuc",
      Group: "group",
      Product:
      {
        Inactive: 'product.inactive',
        Active: 'product.active',
        OutOfStock: 'product.outofstock',
        NotAvailable: 'product.notavailable',
      },

      Ninjastatus:
      {
        Approved: 'niinjaregstatus.approved',
        Rejected: 'niinjaregstatus.rejected',
        Pending: 'niinjaregstatus.pending',
        Blocked: 'niinjaregstatus.blocked',
      },


      Transaction: {
        Success: "transaction.success",
      },
      Campaign: {
        Creating: "campaign.creating",
        UnderReview: "campaign.underreview",
        Approved: "campaign.approved",
        Rejected: "campaign.rejected",
        Published: "campaign.published",
        Paused: "campaign.paused",
        LowBalance: "campaign.lowbalance",
        Expired: "campaign.expired",
        Archived: "campaign.archived",
      },
      WorkHorse: {
        New: "workhorsestatus.new",
        Acknowledge: "workhorsestatus.acknowledge",
        Rejected: "workhorsestatus.rejected",
        Approved: "workhorsestatus.approved",
        Inprogress: "workhorsestatus.inprogress",
        Finalizing: "workhorsestatus.finalizing",
        BetaTesting: "workhorsestatus.betatesting",
        ReadyToRelease: "workhosestatus.readytorelease",
        Released: "workhorsestatus.released",
        Cancelled: "workhorsestatus.cancelled",
        Duplicate: "workhorsestatus.duplicate",
      },
      DealCode: {
        Unused: "dealcode.unused",
        Used: "dealcode.used",
        Expired: "dealcode.expired",
        Blocked: "dealcode.blocked"
      },

    },

    AccountTypecode: {
      Merchant: "thankumerchant",
      // Inactive: "default.inactive",
      // Suspended: "default.suspended",
    },




    WorkHorseType: {
      SystemUpdates: "workhorsetype.systemupdates",
      NewFeatureRequest: "workhorsetype.newfeaturerequest",
      UpdateFeatureRequest: "workhorsetype.updatefeaturerequest",
      SystemErrorRequest: "workhorsetype.systemerrorrequest",
      FeatureDocumentation: "workhorsetype.documentation.feature",
      ComponentDocument: "workhorsetype.documentation.component",
    },
    WorkHorseSubType: {
      workhorseitem: "workhorseitem",
      workhorsesubitem: "workhorsesubitem",
      workhorseactivity: "workhorseactivity",
    },
    WorkHorsePriority: {
      Low: "workhorsepriority.low",
      Medium: "workhorsepriority.medium",
      High: "workhorsepriority.high",
      RedAlert: "workhorseprioriy.redalert",
    },
    TransactionMode: {
      Credit: "transaction.mode.credit",
      Debit: "transaction.mode.debit",
    },
    TransactionSource: {
      Merchant: "transaction.source.merchant",
      Settlement: "transaction.source.settlement",
      GiftCard: "transaction.source.giftcards",
    },
    AccountTypeCodes: [
      {
        id: 0,
        text: "Select Panel",
        code: "",
      },
      {
        id: 1,
        text: "Merchant",
        code: "thankumerchant",
      },
      {
        id: 2,
        text: "Acquirer",
        code: "acquirer",
      },
      {
        id: 3,
        text: "PTSP",
        code: "posaccount",
      },
      {
        id: 4,
        text: "PG Account",
        code: "pgaccount",
      },
      {
        id: 5,
        text: "Store",
        code: "merchantstore",
      },
      {
        id: 6,
        text: "Manager",
        code: "manager",
      },
      {
        id: 7,
        text: "Branch",
        code: "branch",
      },
      {
        id: 8,
        text: "Store SubAccount",
        code: "merchantstoresubaccount",
      },
      {
        id: 9,
        text: "Cashier",
        code: "merchantcashier",
      }
    ],



    StatusList: {
      defaultitem: 0,
      defaultaccountitem: 'default.active',
      defaultaccountitem1: 'deal.rejected',
      OnHoldTransaction: '695',
      default: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 1,
          text: "Inactive",
          code: "default.inactive",
        },
        {
          id: 2,
          text: "Active",
          code: "default.active",
        },
        {
          id: 3,
          text: "Blocked",
          code: "default.blocked",
        },
        {
          id: 4,
          text: "Suspended",
          code: "default.suspended",
        },
        // {
        //   id: 4,
        //   text: "Suspended",
        //   code: "default.suspended",
        // },
      ],
      LogFeature: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 1,
          text: "Inactive",
          code: "1",
        },
        {
          id: 2,
          text: "Active",
          code: "2",
        }
      ],
      ApiRequest: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 1,
          text: "Inactive",
          code: "1",
        },
        {
          id: 2,
          text: "Active",
          code: "2",
        },
        {
          id: 3,
          text: "Blocked",
          code: "3",
        },
        {
          id: 4,
          text: "Suspended",
          code: "4",
        },
        // {
        //   id: 4,
        //   text: "Suspended",
        //   code: "default.suspended",
        // },
      ],


      defaultSMSCampaign: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 1,
          text: "Not Used Yet",
          code: "default.inactive",
        },
        {
          id: 2,
          text: "Actively Using",
          code: "default.active",
        },
        {
          id: 3,
          text: "Stopped Using",
          code: "default.blocked",
        },

        // {
        //   id: 4,
        //   text: "Suspended",
        //   code: "default.suspended",
        // },
      ],


      defaultreferalCustomer: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },

        {
          id: 2,
          text: "Active",
          code: "default.active",
        },
        {
          id: 3,
          text: "Blocked",
          code: "default.blocked",
        },
        {
          id: 4,
          text: "Suspended",
          code: "default.suspended",
        },
      ],

      PromocodeStatus: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 1,
          text: "Expired",
          code: "default.inactive",
        },
        {
          id: 2,
          text: "Active",
          code: "default.active",
        },
        // {
        //   id: 3,
        //   text: "Blocked",
        //   code: "default.blocked",
        // },
        // {
        //   id: 4,
        //   text: "Suspended",
        //   code: "default.suspended",
        // },
      ],
      AppPromots: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 1,
          text: "Inactive",
          code: "default.inactive",
        },
        {
          id: 2,
          text: "Active",
          code: "default.active",
        },

      ],
      defaultlogin: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },

        {
          id: 2,
          text: "Active",
          code: "default.active",
        },
        {
          id: 3,
          text: "Blocked",
          code: "default.blocked",
        },
        // {
        //   id: 4,
        //   text: "Suspended",
        //   code: "default.suspended",
        // },
      ],

      defaultcustomer: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 1,
          text: "Inactive",
          code: "default.inactive",
        },
        {
          id: 2,
          text: "Active",
          code: "default.active",
        },
        {
          id: 3,
          text: "Blocked",
          code: "default.blocked",
        },
        {
          id: 4,
          text: "Suspended",
          code: "default.suspended",
        },
      ],
      ninjaStatus: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 'niinjaregstatus.pending',
          text: "Pending",
          code: "niinjaregstatus.pending",
        },
        {
          id: "niinjaregstatus.rejected",
          text: "Rejected",
          code: "niinjaregstatus.rejected",
        },
        {
          id: "niinjaregstatus.blocked",
          text: "Blocked",
          code: "niinjaregstatus.blocked",
        },
        {
          id: "niinjaregstatus.approved",
          text: "Approved",
          code: "niinjaregstatus.approved",
        },
      ],

      plan: [
        {
          id: 0,
          text: "All Plans",
          code: "",
        },
        {
          id: 2,
          text: "Basic",
          code: "2",
        },
        {
          id: 4,
          text: "Enterprise",
          code: "4",
        },
        {
          id: 3,
          text: "Premium",
          code: "3",
        },

      ],


      Campaign: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 710,
          text: "Creating",
          code: "creating",
        },
        {
          id: 711,
          text: "Deleting",
          code: "deleting",
        },
        {
          id: 712,
          text: "Draft",
          code: "draft",
        },
        {
          id: 713,
          text: "Waitingforreview",
          code: "waitingforreview",
        },
        {
          id: 714,
          text: "Inreview",
          code: "inreview",
        },
        {
          id: 715,
          text: "Approved",
          code: "approved",
        },
        {
          id: 716,
          text: "Rejected",
          code: "rejected",
        },
        {
          id: 717,
          text: "Published",
          code: "published",
        },
        {
          id: 718,
          text: "Running",
          code: "running",
        },
        {
          id: 719,
          text: "Paused",
          code: "paused",
        },
        {
          id: 720,
          text: "Cancelled",
          code: "cancelled",
        },
        {
          id: 721,
          text: "Expired",
          code: "expired",
        },
        {
          id: 722,
          text: "Completed",
          code: "completed",
        },

      ],

      Downloaddefault: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 1,
          text: "Preparing",
          code: "default.inactive",
        },
        {
          id: 2,
          text: "Available",
          code: "default.active",
        },

      ],

      Downloaddefault1: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        // {
        //   id: "download.processing",
        //   text: "Preparing",
        //   code: "download.processing",
        // },
        {
          id: 4,
          text: "Available",
          code: "default.active",
        },
        {
          id: "download.failed",
          text: "Preparing",
          code: "download.failed",
        },
        {
          id: "download.completed",
          text: "Completed",
          code: "download.completed",
        },
      ],


      Campaigns: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 297,
          text: "published",
          code: "campaign.published",
        },
        {
          id: 298,
          text: "Expired",
          code: "campaign.expired",
        },
        {
          id: 299,
          text: "Paused",
          code: "campaign.paused",
        },
        {
          id: 293,
          text: "Creating",
          code: "campaign.creating",
        },

      ],
      orderstatus: [
        {
          id: 0,
          text: 'All Status',
          code: ''
        },
        {
          id: 486,
          text: 'New',
          code: 'orderstatus.new'
        },
        {
          id: 487,
          text: 'Pending Confirmation',
          code: 'orderstatus.pendingconfirmation'
        },
        {
          id: 488,
          text: 'Confirmed',
          code: 'orderstatus.confirmed'
        },
        {
          id: 489,
          text: 'Preparing',
          code: 'orderstatus.preparing'
        },
        {
          id: 490,
          text: 'Ready To Deliver',
          code: 'orderstatus.ready'
        },
        {
          id: 491,
          text: 'Ready To Pickup',
          code: 'orderstatus.readytopickup'
        },
        {
          id: 492,
          text: 'Out For Delivery',
          code: 'orderstatus.outfordelivery'
        },
        {
          id: 493,
          text: 'Delivery Failed',
          code: 'orderstatus.deliveryfailed'
        },
        {
          id: 494,
          text: 'Cancelled By User',
          code: 'orderstatus.cancelledbyuser'
        },
        {
          id: 495,
          text: 'Cancelled By Seller',
          code: 'orderstatus.cancelledbyseller'
        },
        {
          id: 511,
          text: 'Cancelled By System',
          code: 'orderstatus.cancelledbysystem'
        },
        {
          id: 512,
          text: 'Delivered',
          code: 'orderstatus.delivered'
        }
      ],
      CashOut: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 678,
          text: "Pending",
          code: "cashoutstatus.initialized",
        },
        {
          id: 679,
          text: "Processing",
          code: "cashoutstatus.processing",
        },
        // {
        //   id: 680,
        //   text: "Success",
        //   code: "cashoutstatus.success",
        // },
        // {
        //   id: 681,
        //   text: "Failed",
        //   code: "cashoutstatus.failed",
        // },
        {
          id: 682,
          text: "Rejected",
          code: "cashoutstatus.rejected",
        },
        {
          id: 683,
          text: "Approved",
          code: "cashoutstatus.success",
        }
      ],
      Paymentdefaultitem: 'paymentstatus.success',
      payments: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 557,
          text: "Initialized",
          code: "paymentstatus.initialized",
        },
        {
          id: 558,
          text: "Processing",
          code: "paymentstatus.processing",
        },
        {
          id: 559,
          text: "Success",
          code: "paymentstatus.success",
        },
        // {
        //   id: 4,
        //   text: "Pending",
        //   code: "paymentstatus.pending",
        // },
        {
          id: 560,
          text: "Failed",
          code: "paymentstatus.failed",
        },
        // {
        //   id: 4,
        //   text: "Cancelled",
        //   code: "paymentstatus.cancelled",
        // },
        // {
        //   id: 4,
        //   text: "Error",
        //   code: "Error.error",
        // },
      ],
      smsnotificationitem: 0,
      smsnotification: [
        {
          text: "All Status",
          code: "",
        },
        {
          text: "Delivered TO Handset",
          code: "DELIVERED_TO_HANDSET",
        },
        {
          text: "Pending Wait Delivery",
          code: "PENDING_WAITING_DELIVERY",
        },
        {
          text: "Pending Enroute",
          code: "PENDING_ENROUTE",
        },
        {
          text: "Pending Accepted",
          code: "PENDING_ACCEPTED",
        },
        {
          text: "Undeliverable Rejected Operator",
          code: "UNDELIVERABLE_REJECTED_OPERATOR",
        },
        {
          text: "Undeliverable Not Delivered",
          code: "UNDELIVERABLE_NOT_DELIVERED",
        },
        {
          text: "Expired Expired",
          code: "EXPIRED_EXPIRED",
        },
        {
          text: "Expired DLR Unknown",
          code: "EXPIRED_DLR_UNKNOWN",
        },
        {

          text: "Rejected Network",
          code: "REJECTED_NETWORK",
        },
        {
          id: "11",
          text: "Rejected Prefix Missing",
          code: "REJECTED_PREFIX_MISSING",
        },
        {

          text: "Rejected DND",
          code: "REJECTED_DND",
        },
        {

          text: "Rejected Source",
          code: "REJECTED_SOURCE",
        },
        {

          text: "Rejected Not Enough Credits",
          code: "REJECTED_NOT_ENOUGH_CREDITS",
        },
        {

          text: "Rejected SENDER",
          code: "REJECTED_SENDER",
        },
        {

          text: "Rejected Destination",
          code: "REJECTED_DESTINATION",
        },
        {

          text: "Rejected Prepaid Package Expired",
          code: "REJECTED_PREPAID_PACKAGE_EXPIRED",
        },
        {

          text: "Rejected Destination Not Registered",
          code: "REJECTED_DESTINATION_NOT_REGISTERED",
        },
        {

          text: "Rejected Route Not Available",
          code: "REJECTED_ROUTE_NOT_AVAILABLE",
        },
        {

          text: "Rejected Flooding Filter",
          code: "REJECTED_FLOODING_FILTER",
        },
        {

          text: "Rejected System Error",
          code: "REJECTED_SYSTEM_ERROR",
        },
        {

          text: "Rejected Duplicate Message ID",
          code: "REJECTED_DUPLICATE_MESSAGE_ID",
        },
        {

          text: "Rejected Invalid UDH",
          code: "REJECTED_INVALID_UDH",
        },
        {

          text: "Rejected Message Too Long",
          code: "REJECTED_MESSAGE_TOO_LONG",
        },
        {

          text: "Missing To",
          code: "MISSING_TO",
        },



      ],
      BulkFiles: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 'Success',
          text: "Success",
          code: "Success",
        },
        {
          id: 'Error',
          text: "Error",
          code: "Error",
        }
      ],
      Bulk: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 1,
          text: "Pending",
          code: "pending",

        },
        {
          id: 3,
          text: "Success",
          code: "success",

        },
        {
          id: 4,
          text: "Error",
          code: "error",

        },
        {
          id: 2,
          text: "Processing",
          code: "processing",

        },
      ],
      transactiondefaultitemCode: 28,
      transactiondefaultitem: 'transaction.success',
      transaction: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 27,
          text: "Processing",
          code: "transaction.processing",
        },
        {
          id: 28,
          text: "Success",
          code: "transaction.success",
        },
        {
          id: 29,
          text: "Pending",
          code: "transaction.pending",
        },
        {
          id: 30,
          text: "Failed",
          code: "transaction.failed",
        },
        {
          id: 31,
          text: "Cancelled",
          code: "transaction.cancelled",
        },
        {
          id: 32,
          text: "Error",
          code: "transaction.error",
        },
        {
          id: 631,
          text: "Initialized",
          code: "transaction.initialized",
        },
      ],

      programtransaction: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 27,
          text: "Processing",
          code: "trans.processing",
        },
        {
          id: 28,
          text: "Success",
          code: "trans.success",
        },
        {
          id: 29,
          text: "Pending",
          code: "trans.pending",
        },
        {
          id: 30,
          text: "Failed",
          code: "trans.failed",
        },
        {
          id: 31,
          text: "Cancelled",
          code: "trans.cancelled",
        },
        {
          id: 32,
          text: "Error",
          code: "trans.error",
        },
      ],

      giftcardRedeem: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 27,
          text: "Processing",
          code: "",
        },
        {
          id: 28,
          text: "Success",
          code: "",
        },
        {
          id: 29,
          text: "Pending",
          code: "",
        },
        {
          id: 30,
          text: "Failed",
          code: "",
        },
        {
          id: 31,
          text: "Cancelled",
          code: "",
        },
        {
          id: 32,
          text: "Error",
          code: "",
        },
        {
          id: 631,
          text: "Initialized",
          code: "",
        },
      ],

      giftcard: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 27,
          text: "Processing",
          code: "",
          // code: "transaction.processing",
        },
        {
          id: 28,
          text: "Active",
          code: "",
          // code: "transaction.success",
        },
        {
          id: 29,
          text: "Pending",
          code: "",
          // code: "transaction.pending",
        },
        {
          id: 30,
          text: "Failed",
          code: "",
          // code: "transaction.failed",
        },
        {
          id: 31,
          text: "Cancelled",
          code: "",
          // code: "transaction.cancelled",
        },
        {
          id: 32,
          text: "Error",
          code: "",
          // code: "transaction.error",
        },
        {
          id: 631,
          text: "Initialized",
          code: "",
          // code: "transaction.initialized",
        },
      ],

      Merchantwallatetransaction: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: ' transaction.processing',
          text: "Processing",
          code: "transaction.processing",
        },
        {
          id: "transaction.success",
          text: "Success",
          code: "transaction.success",
        },
        {
          id: "transaction.pending",
          text: "Pending",
          code: "transaction.pending",
        },
        {
          id: "transaction.failed",
          text: "Failed",
          code: "transaction.failed",
        },
        {
          id: "transaction.cancelled",
          text: "Cancelled",
          code: "transaction.cancelled",
        },
        {
          id: "transaction.error",
          text: "Error",
          code: "transaction.error",
        },
        {
          id: "transaction.initialized",
          text: "Initialized",
          code: "transaction.initialized",
        },
      ],

      invoice: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 38,
          text: "Pending",
          code: "invoice.pending",
        },
        {
          id: 39,
          text: "Paid",
          code: "invoice.paid",
        },
        {
          id: 40,
          text: "Cancelled",
          code: "invoice.cancelled",
        },
      ],
      workhorsestatus: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 415,
          text: "New",
          code: "workhorsestatus.new",
        },
        {
          id: 416,
          text: "Acknowledged",
          code: "workhorsestatus.acknowledged",
        },
        {
          id: 417,
          text: "Rejected",
          code: "workhorsestatus.rejected",
        },
        {
          id: 418,
          text: "Approved",
          code: "workhorsestatus.approved",
        },
        {
          id: 419,
          text: "In progress",
          code: "workhorsestatus.inprogress",
        },
        {
          id: 420,
          text: "Finalizing",
          code: "workhorsestatus.finalizing",
        },
        {
          id: 421,
          text: "Beta Testing",
          code: "workhorsestatus.betatesting",
        },
        {
          id: 422,
          text: "Ready To Release",
          code: "workhorsestatus.readytorelease",
        },
        {
          id: 423,
          text: "Released",
          code: "workhorsestatus.released",
        },
        {
          id: 424,
          text: "Cancelled",
          code: "workhorsestatus.cancelled",
        },
        {
          id: 437,
          text: "Duplicate",
          code: "workhorsestatus.duplicate",
        },
      ],
      productcodestatus: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 461,
          text: "Unused",
          code: "productcodestatus.unused",
        },
        {
          id: 463,
          text: "Used",
          code: "productcodestatus.used",
        },
        {
          id: 462,
          text: "Blocked",
          code: "productcodestatus.blocked",
        },
        {
          id: 464,
          text: "Expired",
          code: "productcodestatus.expired",
        },
      ],
      Product: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 547,
          text: 'Inactive',
          code: 'product.inactive'
        },
        {
          id: 548,
          text: 'Active',
          code: 'product.active'
        },
        {
          id: 549,
          text: 'Out Of Stock ',
          code: 'product.outofstock'
        },
        {
          id: 550,
          text: 'Not Available',
          code: 'product.notavailable'
        },
      ],
      Terminal: [
        {
          id: 0,
          text: 'All',
          code: ''
        },
        {
          id: 547,
          text: 'Active',
          code: 'terminal.active'
        },
        {
          id: 548,
          text: 'Idle',
          code: 'terminal.idle'
        },
        {
          id: 549,
          text: 'Dead',
          code: 'terminal.dead'
        },
        {
          id: 550,
          text: 'Unused',
          code: 'terminal.unused'
        },
      ],
      DateRange: [
        {
          id: 0,
          text: 'day'
        }, {
          id: 1,
          text: 'week'
        }, {
          id: 2,
          text: 'month'
        },
        {
          id: 3,
          text: 'year'
        }
      ],
      Deal: [
        {
          id: 0,
          text: 'All',
          code: ''
        },
        {
          id: 'deal.upcoming',
          text: 'Upcoming',
          code: 'deal.upcoming'
        },
        {
          id: 'deal.draft',
          text: 'Draft',
          code: 'deal.draft'
        },
        {
          id: 'deal.approvalpending',
          text: 'Pending',
          code: 'deal.approvalpending'
        },
        {
          id: 'deal.approved',
          text: 'Approved',
          code: 'deal.approved'
        },
        {
          id: 'deal.published',
          text: 'Published',
          code: 'deal.published'
        },
        {
          id: 'deal.paused',
          text: 'Paused',
          code: 'deal.paused'
        },
        {
          id: 'deal.expired',
          text: 'Expired',
          code: 'deal.expired'
        },
        {
          id: 'deal.rejected',
          text: 'Rejected',
          code: 'deal.rejected'
        }
      ],
      DealCode: [
        {
          id: 0,
          text: 'All',
          code: ''
        },
        {
          id: 620,
          text: 'Blocked',
          code: 'dealcode.blocked'
        },
        {
          id: 621,
          text: 'Expired',
          code: 'dealcode.expired'
        },
        {
          id: 622,
          text: 'Used',
          code: 'dealcode.used'
        },
        {
          id: 623,
          text: 'Unused',
          code: 'dealcode.unused'
        }
      ],
      SoldHistoryDealCode: [
        {
          id: 999,
          text: 'Blocked',
          code: 'dealcode.blocked'
        },
        {
          id: 998,
          text: 'Expired',
          code: 'dealcode.expired'
        },
        {
          id: 997,
          text: 'Used',
          code: 'dealcode.used'
        },
      ],
      LoanRequest: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 740,
          text: "Active",
          code: "running",
        },
        {
          id: 739,
          text: "Rejected",
          code: "rejected",
        },
        {
          id: 741,
          text: "Closed",
          code: "closed",
        },
        {
          id: 738,
          text: "Approved",
          code: "approved",
        },
      ],
      GiftCardIssued: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 461,
          text: "Unused",
          code: "",
        },
        {
          id: 463,
          text: "Used",
          code: "",
        },
        {
          id: 462,
          text: "Blocked",
          code: "",
        },
        {
          id: 464,
          text: "Expired",
          code: "",
        },
      ]
    },
    NetworkLocation: {
      V1: {
        System: "api/v1/system/",
        ThankU: "api/v1/thanku/",
        acquirer: "api/v1/acquirer/",
      },
      V2: {
        System: "api/v2/system/",
        ThankU: "api/v2/thankucash/",
        TUCTransCore: "api/v2/tuctranscore/",
        // TUCTransCore: "api/v3/merchant/trans/",
        TUCAccCore: "api/v2/tucacccore/",
        TUCRmManager: "api/v2/rmmanager/",
        ThankUCashLead: "api/v2/tucashlead/",
        TUCCampaign: "api/v2/tuccampaign/",
        TUCAnalytics: "api/v2/tucanalytics/",
        TUCGiftPoints: "api/v2/tucgiftpoints/",
        CAProduct: "api/v2/caproduct/",
        HCWorkHorse: "api/v2/workhorse/",
        HCProduct: "api/v2/hcproduct/",
        HCAmbassador: "api/v2/hcproduct/",
        TUCTransCore2: "api/v2/tuctranscore/",

        LogFeature: "api/v3/tuc/hclchangelog/",
        TUCPending_Overview: "api/v2/tuctranscore/"


        // 
        // GetChangeLogs: "api/v3/tuc/hclchangelog/"
        // 
      },
      Console: {
        V3: {
          systemPackaging: "api/v3/plugins/lspackaging/",
          Promocode: "api/v3/tuc/promocode/",
          Accounts: "api/v3/con/accounts/",
          Carriers:"api/v3/plugins/lscarrier/",
          Deals: "api/v3/plugins/deals/",
          Referals:"api/v3/tuc/cop/",
          MadDeals: "api/maddeals/account/",
          Promote: "api/v3/plugins/dealpromotion/",
          AppPromote: "api/v3/tuc/apppromotion/",
          //DealsApprove:"api/v3/plugins/deals/approvedeal",
          MAnalytics: "api/v3/con/manalytics/",
          CustAnalytics: "api/v3/con/canalytics/",
          Analytics: "api/v3/con/analytics/",
          Analac: "api/v3/con/anac/",
          Profile: "api/v3/con/profile/",
          Admin: "api/v3/con/admin/",
          System: "api/v3/con/sys/",
          Configuration: "api/v3/tuc/config/",
          System1: "api/v3/tuc/categories/",
          System2: "api/v3/plugins/dealscat/",
          Vas: "api/v3/plugins/vas/",
          Customer: "api/v3/con/cust/",
          M: "api/v3/con/m/",
          Co: "api/v3/con/co/",
          Op: "api/v3/tuc/op/",
          accounts: "api/v3/con/accounts/",
          timeZone: "api/v3/tuc/timezone/",

          Country: "api/v3/tuc/country/",
          State: "api/v3/tuc/state/",
          City: "api/v3/tuc/city/",
          MerchantCategory: "api/v3/tuc/merchantcategories/",
          DealCategory: "api/v3/tuc/dealcategories/",
          Categories: "api/v3/tuc/categories/",

          accountloans: "api/v3/plugins/accountop/",

          MerchantWallet: "api/v2/tuctranscore/",

          bnpl: {
            merchants: "api/v3/plugins/merchant/",
            eligibleCustomers: "api/v3/plugins/customer/",
            repayments: "api/v3/plugins/loan/",
            loans: "api/v3/plugins/loan/",
            GetMerchantListOverview: "api/v3/plugins/merchant/",
            GetEligibleCustoerOverview: "api/v3/plugins/customer/",
            GetRepaymentOverview: "api/v3/plugins/loan/",
            Settlementlist: "api/v3/plugins/merchant/",
            Settlementlistoverview: "api/v3/plugins/merchant/",
            SettlementDetails: "api/v3/plugins/merchant/",

          },
          AccountPlan: {
            BNPLMerchant: "api/v3/tuc/accountplan/",
          },
          Bank: "api/v3/tuc/bankop/",

          MOnBoardV2: "api/v3/merchant/onboardingv2/",
          Rewardwallate: "api/v3/merchant/payments/",
        }
      },

      Partner: {

        V3: {
          Account: "api/v3/partner/accounts/",
          Branch: "api/v3/partner/branch/",
          SubAccounts: "api/v3/partner/subaccounts/",
          Analytics: "api/v3/partner/analytics/",
          OnBoard: "api/v3/partner/onboarding/",
          SystemMange: "api/v3/system/manage/",
          Accounts: {
            Manage: "api/v3/acc/manage/"
          }
        },
      },


      // HelperService.AppConfig.Pages.ThankUCash.PanelConsole.Administration.Apps
      V3: {
        Lsshipment: "api/v3/plugins/lsshipment/",
        Customer: "api/v3/cust/account/",
        SMS: "api/v3/campaigns/sms/",
        GC: "api/v3/plugins/gc/",
        Account: "api/v3/merchant/accounts/",
        Users:"api/v3/con/user/",
        storeAccount: "api/v3/con/accounts/",
        mostVisitedPlaces: "api/v3/con/custoverview/",
        frequentlyPurchasedCategory: "api/v3/con/custoverview/",
        customerCardCashTransaction: "api/v3/con/custoverview/",


        // new added for stores load
        Accountss: "api/v3/con/accounts/",
        //
        Branch: "api/v3/acquirer/branch/",
        PlOps: "api/v3/plugins/operations/",
        Maddeals: "api/v3/plugins/maddeals/",
        SubAccounts: "api/v3/acquirer/subaccounts/",
        Analytics: "api/v3/merchant/analytics/",
        OnBoard: "api/v3/acquirer/onboarding/",
        SystemMange: "api/v3/system/manage/",
        SystemFaq: "api/v3/sys/faq/",
        Operations: "api/v3/merchant/operations/",
        Op: "api/v3/tuc/op/",
        Configuration: "api/v3/tuc/configuration/",
        Subscription: "api/v3/merchant/subscription/",
        Subscriptions: "api/v3/tuc/subscriptions/",
        MTransaction: "api/v3/merchant/trans/",
        BuypointOverview: "api/v3/con/cust/",
        PTSPTrans: 'api/v3/ptsp/transaction/',
        // TransationReport:'api/v2/tuctranscore/',
        Accounts: {
          Manage: "api/v3/acc/manage/"
        },

        OrderHistory: 'api/v3/plugins/lsshipment/',
        OrderHistoryOverview: 'api/v3/plugins/lsshipment/',
        TransationReport: 'api/v2/tuctranscore/'
      },
      Acquirer: {
        V3: {
          Program: "api/v3/tuc/program/",
          Account: "api/v3/acquirer/accounts/",
          Branch: "api/v3/acquirer/branch/",
          SubAccounts: "api/v3/acquirer/subaccounts/",
          Analytics: "api/v3/acquirer/analytics/",
          OnBoard: "api/v3/acquirer/onboarding/",
          SystemMange: "api/v3/system/manage/",
          Accounts: {
            Manage: "api/v3/acc/manage/"
          }
        },
      },
      Customer: {
        V3: {
          AllCustomers: "api/v3/merchant/customers",
        }
      },
      Ptsp: {
        V3: {
          TUCPtspProduct: "api/v3/ptsp/terminalproduct/",
          TUCPtspAccount: "api/v3/ptsp/accounts/",
          TUCPtspAnalytics: "api/v3/ptsp/analytics/"
        }
      },

    },
    Api: {
      Login: "login",
      Logout: "logout",
      ThankUCash: {
        BNPL: {
          GetMerchantList: "getmerchantlist",
          GetEligibleCustomer: "geteligiblecustomerlist",
          GetBnplOverview: 'getbnploverview',
          GetDisbursementDistribution: 'getdisbursmentdistributionview',
          GetRepaymentStatusOverview: 'getrepaymentstatusoverview',
          GetLoanDisbursementByProvider: 'getloandisbursement',
          GetDisbursementByMonth: 'getdisbursementbymonth',
          GetTotalEligibleCustomerTillToday: 'geteligiblecustomersoverview',
          Getsettlementoverview: "getsettlementoverview",
          GetSettlementsLite: "getsettlementslite",
          GetSettlementOverviewLite: "getsettlementoverviewlite",
          SettlementInvoice: "settlementinvoice",

        },
        Gettransactions: "gettransactions",
        GetAccountLoans: "getaccountloans",
        GetLoanActivity: "getloanactivity",
        GetLoanDetails: "getloandetails",
        GetLoanRequestOverview: "getloanrequestoverview",
        GetLoanRequests: "getloanrequests",
        GetLoanOverview: "getallloansoverview",
        GetAccountLoansOverview: "getaccountloansoverview",
        GetRepaymentOverview: "getrepaymentsoverview",
        GetMerchantListOverview: "getmerchantlistoverview",
        GetEligibleCustoerOverview: "geteligiblecustomeroverview",
        getloans: "getallloans",
        getloanproviders: "getloanproviders",
        getrepayments: "getrepaymentlist",
        GetShipments: "getshipments",
        GetReferralAmount: "getreferralamount",
        SetReferralAmount:"setreferralamount",
        UpdateRefId:"updaterefid",
        getpromocodes: "getpromocodes",
        getgiftcards: "getgiftcards",
        getapppromotions: "getapppromotions",
        GetGroupItems: "getgroupitems",
        GetCampaignItems: "getcampaignitems",
        deletedealpromotion: "deletedealpromotion",
        deleteapppromotion: "deleteapppromotion",
        deletemerchantcategory: "deletemerchantcategory",
        deletedealcategory: "deletedealcategory",
        deletecategory: "deletecategory",

        Getconfiguration: "getconfiguration",
        GetSenderIds: "getsenderids",
        GetGroups: "getgroups",
        getappsliders: "getappsliders",
        GetCustomerCount: "getcustomercount",
        updatedealpromotion: "updatedealpromotion",
        getdealpromotion: "getdealpromotion",
        getsettlementlist: "getsettlementlist",
        Getmerchantsettlement: "getmerchantsettlement",

        Analytics: {
          getaccountrewardsummary: "getaccountrewardsummary",
          getaccountrewardhistory: "getaccountrewardhistory",
          getaccountsaleshistory: "getaccountsaleshistory",
          getaccountsalessummary: "getaccountsalessummary",
        },
        TransactionOverviews: {
          getpromocodesoverview: "getpromocodesoverview",
          getgiftcardoverview: "getgiftcardoverview",
          getrewardtransactionsoverview: 'getrewardtransactionsoverview',
          getpointpurchaseoverview: "getpointpurchaseoverview",
          gettransaction: "gettransaction",
          getrewardclaimtransactionsoverview: 'getrewardclaimtransactionsoverview',
          getredeemtransactionsoverview: 'getredeemtransactionsoverview',
          getsaletransactionsoverview: 'getsaletransactionsoverview',
          getcustomersoverview: 'getcustomersoverview',
          getcustomersstatusoverview: "getcustomersstatusoverview",
          getterminalsoverview: 'getterminalsoverview',
          getproductoverview: "getproductoverview",
          getorderoverview: "getorderoverview",
          GetRewardClaimTransctionsoverview: "getrewardclaimtransactionsoverview",
          GetCashiersOverview: "getcashiersoverview",
          GetMerchantsOverview: "getmerchantsoverview",
          Getstoreoverview: "getstoreoverview",
          Getreferralsoverview:"getreferralsoverview",
          GetPendingRewardTransactionsoverview: "getpendingrewardtransactionsoverview",
          getcommissionhistoryoverview: 'getcommissionhistoryoverview',
          getvasproductpurchasehistoryoverview: 'getvasproductpurchasehistoryoverview',
          gettransactionsoverview: "gettransactionsoverview",
          GetIssuedCardsOverview: "getgiftcardshistoryoverview",
          GetReedemOverview: "getredeemhistoryoverview",
          GetAcquirerRedeemTransactionsOverview: "getacquirerredeemtransactionsoverview",
          GetAcquirerRewardTransactionsOverview: "getacquirerrewardtransactionsoverview",
          GetAcquirerTransactionOverview: "getacquirertransactionoverview",
          CancelTransaction: 'canceltransaction'
        },
        GetCommissionHistory: "getcommissionhistory",
        createquickgiftcard: "createquickgiftcard",
        GetPendingRewardTransactions: "getpendingrewardtransactions",
        GetSmsNotifications: "getsmsnotifications",
        getcustomers: "getcustomers",
        GetOnBoardedCustomers: "getonboardedcustomers",
        GetonBoardedCustomerfiles: "getonboardedcustomerfiles",
        ValidateDealTitle: "validatedealtitle",
        SaveFlashDeal: "saveflashdeal",
        deletedealimage: "deletedealimage",
        RemoveFlashDeal: "removeflashdeal",
        updatedealstatus: "updatedealstatus",
        deletedeal: "deletedeal",
        getdealreviews: "getdealreviews",
        FlashDeals: "getflashdeals",
        Getdeals: "getdeals",
        GetDeal: "getdeal",
        GetDealPromotion: "getdealpromotion",
        getdealpromotions: "getdealpromotions",
        GetPurchaseHistory: "getpurchasehistory",
        GetNotifHistory: "getdealnotifications",
        SendDealNotification: "senddealnotification",
        DuplicateDeal: "duplicatedeal",
        GetPurchaseHistoryOverview: "getpurchasehistoryoverview",
        GetCustomers: "getcustomers",
        GetAcquirers: "getacquirers",
        GetPtsps: "getptsps",
        GetPssps: "getpssps",
        GetPartners: "getpartners",
        GetDealPartners:"getdealpartners",
        GetCarriers:"getcarriers",
        SaveTerminalProduct: "saveterminalproduct",
        GetTerminalProducts: "getterminalproducts",
        GetMerchantsTerminals: "getterminals",
        GetTerminalDetail: "getterminal",
        GetrmTargets: "getrmtargets",
        SaveRmTarget: "savermtarget",
        getuploadedterminals: "getuploadedterminals",
        GetTopupHistory: "gettopuphistory",
        GetCustomer: "getcustomer",
        getsaletransactions: "getsaletransactions",
        saveterminal: "saveterminal",
        UpdateCashier: "updatecashier",
        savestore: "savestore",
        updatestore: "updatestore",
        SaveCashier: "savecashier",
        GetCashier: "getcashier",
        getcashierposhistory: "getcashierposhistory",
        GetSubAcount: "getsubaccount",
        getconfigurationhistory: "getconfigurationhistory",
        saveconfiguration: "saveconfiguration",
        rewardpercentage: "rewardpercentage",
        getterminals: "getterminals",
        GetAppUserVisits: "getappuservisits",
        GetAppUsers: "getappusers",
        GetAppUsersLite: "getappuserslite",
        GetMerchantAppUsers: "getmerchantappusers",
        GetMerchant: "getmerchant",
        GetMerchants: "getmerchants",
        GetDealMerchants: 'getdealmerchants',
        GetTerminal: "getterminal",
        GetStore: "getstore",
        GetRelationshipManagers: "getrelationshipmanagers",
        GetAcquirerMerchants: "getacquirermerchants",
        GetBillPayments: "getbillpayments",
        GetAcquirerProgramMerchants: "getacquirerprogrammerchants",

        GetStores: "getstores",
        GetStorelocations: "getstorelocations",
        GetStoresLite: "getstoreslite",
        GetStoreBankCollection: "getstorebankcollection",
        GetCashiers: "getcashiers",

        GetSubAccounts: "getsubaccounts",
        GetSubAccount: "getsubaccount",
        GetPgAccounts: "getpgaccounts",
        GetPosAccounts: "getposaccounts",
        GetPosTerminals: "posterminals",
        GetTerminals: "getterminals",

        getrewardprductlist: "getrewardprductlist",
        GetSaleTransactions: "getsaletransactions",
        GetTransactions: "gettransactions",
        GetReedemHistory: "getredeemhistory",
        GiftCardsHistory: "getgiftcardshistory",
        GetTUCRewardTransctions: "gettucrewardtransactions",
        GetRewardTransctions: "getrewardtransactions",
        GetRewardClaimTransctions: "getrewardclaimtransactions",
        GetCampaignTransactions: "getcampaigntransactions",
        GetAcquirerTransaction: "getacquirertransaction",
        GetAcquirerRedeemTransactions: "getacquirerredeemtransactions",
        GetTUCPlusRewardTransctions: "gettucplusrewardtransactions",
        GetTUCPlusRewardTransferTransctions:
          "gettucplusrewardtransfertransactions",
        GetRedeemTransactions: "getredeemtransactions",
        GetGiftTransactions: "getgifttransactions",
        GetChangeTransactions: "getchangetransactions",
        GetPaymentTransactions: "getpaymenttransactions",
        GetComissionTransactions: "getcommissiontransactions",
        GetSalesTransactions: "getsalestransactions",

        GetInvoices: "getsettlementinvoices",
        SaveCampaign: "savecampaign",
        UpdateCampaign: "updatecampaign",
        DeleteCampaign: "deletecampaign",
        GetCampaign: "getcampaign",
        GetCampaigns: "getcampaigns",
        getonboardedmerchants: "getonboardedmerchants",

        SaveSubAccount: "savesubaccount",
        UpdateSubAccount: "updatesubaccount",
        GetSubAaccount: "getsubaccount",
        FetSubAccounts: "getsubaccounts",

        GetOrders: "getorders",
        GetOrderHistory: "getshipments",
        GetOrderHistoryOverview: "getordersovrview",
        GetOrderOverview: "getoverview",
        getbankcodes: "getbankcodes",
      },
      Core: {
        managesettelment: "managesettelment",
        getpromocode: "getpromocode",
        SendPinToUser: "sendpintouser",
        getpromocodeconditions: "getpromocodeconditions",
        getpromocodeaccounts: "getpromocodeaccounts",
        updatepromocode: "updatepromocode",
        savepromocode: "savepromocode",
        OnBoardCustomers: "onboardcustomers",
        getcustomer: "getcustomer",
        // gettransaction:"gettransaction",
        GetPurchaseDetails: 'getpurchasedetails',
        CreditSuperCash: "creditsupercash",
        GetSuperCashcreditHistory: "getsupercashcredithistory",
        NotifyCustomers: "notifycustomers",
        UpdateCustomer: "updatecustomer",
        GetDownloads: "getdownloads",
        getrefunds: "getrefunds",
        SaveStorage: "savestorage",
        GetStorage: "getstorage",
        DeleteStorage: "deletestorage",
        getrootcategories: "getrootcategories",
        getcashoutsoverview: "getcashoutsoverview",
        getwallethistoryoverview: 'getwallethistoryoverview',
        GetCashOuts: "getcashouts",
        GetCashOut: "getcashout",
        GetPointPurchaseHistory: "getpointpurchasehistory",
        getaccountpointpurchasehistory: "getaccountpointpurchasehistory",
        getaccountsubscriptionsoverview: "getaccountsubscriptionsoverview",
        getsubscriptions: "getsubscriptions",
        GetAccountSubscriptions: "getaccountsubscriptions",
        UpdateVasProduct: "updatevasproduct",
        getvasproductpurchasehistoryoverview: "getvasproductpurchasehistoryoverview",
        GetVasCategories: "getvascategories",
        getvasproductitems: "getvasproductitems",
        GetVasProducts: "getvasproducts",
        GetVasProductPurchaseHistory: "getvasproductpurchasehistory",
        GetvVasProductPurchaseDetails: "getvasproductpurchasedetails",
        verifyvaspayment: "verifyvaspayment",
        GetRequestHistory: "getrequesthistory",
        GetRequestHistoryDetails: "getrequesthistorydetails",
        GetApp: "getapp",
        GetApps: "getapps",
        DeleteApp: "deleteapp",
        ResetAppKey: "resetappkey",
        SaveApp: "saveapp",
        SaveState: "savestate",
        NewApp: "newapp",
        UpdateApp: "updateapp",
        SaveCategory: "savecategory",
        UpdateCategory: "updatecategory",
        GetSliderImages: "getsliderimages",
        DeleteSliderimage: "deletesliderimage",
        SaveSliderimage: "savesliderimage",
        UpdateAccount: "updateaccount",
        GetOtpRequest: "getotprequest",
        GetOtpRequests: "getotprequests",
        GetResponsecodes: "getresponsecodes",
        UpdateResponsecode: "updateresponsecode",
        GetRolesFeatures: "getrolefeatures",
        GetSystemLogs: "getsystemlogs",
        GetSystemLog: "getsystemlog",
        GetConfigurationHistory: "getconfigurationhistory",
        GetFeatures: "getfeatures",
        GetFeatureList: "getfeaturelist",
        GetRoleDetails: "getroledetails",
        GetDepartments: "getdepartments",
        GetRoles: "getroles",
        SaveRole: "saverole",
        Updaterole: "updaterole",
        SaveRoleFeature: "saverolefeature",
        DeleteRoleFeature: "deleterolefeature",
        GetAccountSessions: "getaccountsessions",
        SaveAccount: "saveaccount",
        GetAccounts: "getaccounts",
        GetAccount: "getaccount",
        GetProfile: "getprofile",
        UpdatePassword: "updatepassword",
        UpdatePin: "updatepin",
        UpdateUserName: "updateusername",
        GetPartner: "getpartner",
        GetMerchant: "getmerchant",
        GetAcquirer: "getacquirer",
        GetPstp: "getptsp",
        GetPssp: "getpssp",
        UploadTerminals: "uploadterminals",
        SaveTerminal: "saveterminal",
        SaveTerminals: "saveterminals",
        onboardmerchant: "onboardmerchant",
        getsubcategory: "getsubcategory",
        getcategories: "getcategories",
        getallcategories: "getallcategories",
        getmerchantcategories: "getmerchantcategories",
        gettimezone: "gettimezone",
        getcategory: "getcategory",
        getdealcategories: "getdealcategories",
        getsubcategories: "getsubcategories",
        savesubcategory: "savesubcategory",
        savesubcategories: "savesubcategories",
        updatesubcategory: "updatesubcategory",
        updatecategory: "updatecategory",
        savemerchantcategory: "savemerchantcategory",
        savedealcategory: "savedealcategory",
        updatemerchantcategory: "updatemerchantcategory",
        updatemerchantdetails: "updatemerchantdetails",
        onboardmerchants: "onboardmerchants",
        getcitybyregionarea: "getcitybyregionarea",
        getcityareabycity: "getcityareabycity",
        getregionsbycountry: "getregionsbycountry",
        getsubadmins: "getsubadmins",
        getsubadmindetails: "getsubadmindetails",
        addsubadmin: "addsubadmin",
        updatesubadmin: "updatesubadmin",
        deletesubadmin: "deletesubadmin",
        Register: "register",
        SaveCoreCommon: "savecorecommon",
        SaveCoreHelper: "savecorehelper",
        SaveCoreParameter: "savecoreparameter",
        SaveUserAccount: "saveuseraccount",
        SaveUserParameter: "saveuserparameter",
        SavePartner: "savepartner",
        SavePtsp: "saveptsp",
        SaveAcquirer: "saveacquirer",
        SaveMerchant: "savemerchant",
        SavePssp: "savepssp",
        UpdateCoreCommon: "updatecorecommon",
        updatedealcategory: "updatedealcategory",
        UpdateMerchantCategory: "updatemerchantcategory",
        UpdateCoreHelper: "updatecorehelper",
        UpdateCoreParameter: "updatecoreparameter",
        UpdateUserAccount: "updateuseraccount",
        UpdateUserAccountPassword: "updateuserpassword",
        UpdateUserAccountAccessPin: "updateuseraccesspin",
        UpdateUserParameter: "updateuserparameter",

        DeleteCoreCommon: "deletecorecommon",
        DeleteCoreHelper: "deletecorehelper",
        DeleteCoreParameter: "deletecoreparameter",
        DeleteUserAccount: "deleteuseraccount",
        DeleteUserParameter: "deleteuserparameter",

        UpdateUserSession: "updateusersession",
        UpdateUserDevice: "updateuserdevice",

        GetCoreHelper: "getcorehelper",
        GetCoreHelpers: "getcorehelpers",
        GetCoreHelpersLite: "getcorehelperslite",
        GetCoreCommon: "getcorecommon",
        GetCoreCommons: "getcorecommons",
        GetCoreCommonsLite: "getcorecommonslite",
        GetCoreParameter: "getcoreparameter",
        GetCoreParameters: "getcoreparameters",
        GetRoleListForUser: "getrolelistforuser",
        GetCoreLog: "getcorelog",
        GetCoreLogs: "getcorelogs",
        GetCoreUsage: "getcoreusage",
        GetCoreUsages: "getcoreusages",
        GetStorages: "getstorages",
        GetCoreVerification: "getcoreverification",
        GetCoreVerifications: "getcoreverifications",
        GetUserAccount: "getuseraccount",
        GetUserAccounts: "getuseraccounts",
        GetRelationshipManagers: "getrelationshipmanagers",
        GetUserAccountsLite: "getuseraccountslite",
        GetUserAccountOwner: "getuseraccountowner",
        GetUserAccountOwners: "getuseraccountowners",
        GetTransaction: "gettransaction",
        GetTransactions: "gettransactions",
        GetUserActivity: "getuseractivity",
        GetUserActivities: "getuseractivities",
        GetUserDevice: "getuserdevice",
        GetUserDevices: "getuserdevices",
        GetUserSession: "getusersession",
        GetUserSessions: "getusersessions",
        GetUserParameter: "getuserparameter",
        GetUserParameters: "getuserparameters",
        GetUserLocation: "getuserlocation",
        GetUserLocations: "getuserlocations",
        GetUserInvoice: "getuserinvoice",
        GetUserInvoices: "getuserinvoices",
        GetRMStores: "getrmstores",
        UpdateStore: "updatestore",
        GetRMTerminals: "getrmterminals",
        GetManagers: "getmanagers",
        SaveManager: "savemanager",
        GetManger: "getmanager",
        UpdateManager: "updatemanager",
        SaveBranch: "savebranch",
        GetBranch: "getbranch",
        UpdateBranch: "updatebranch",
        GetBranches: "getbranches",
        rewardpercentageSaveTerminal: "saveterminal",
        GetSubAccounts: "getsubaccounts",
        SaveSubAccount: "savesubaccount",
        GetSubAccount: "getsubaccount",
        UpdateSubAccount: "updatesubaccount",
        SaveStore: "savestore",
        ResetUserAccountPin: "resetuseraccesspin",
        UpdateState: "updatestate",
        getcountries: "getcountries",
        getstates: "getstates",
        getstate: "getstate",
        savecity: "savecity",
        getcity: "getcity",
        getcities: "getcities",
        getcityareas: "getcityareas",
        savecityarea: "savecityarea",
        updatecity: "updatecity",
        deletecity: "deletecity",
        updatestate: "updatestate",
        deletestate: "deletestate",
        updatecityarea: "updatecityarea",
        deletecityarea: "deletecityarea",
        getcityarea: "getcityarea",
        updatemerchant: "updatemerchant",
        savemerchant: "savemerchant",
        updatesettelementcriteria: "updatesettelementcriteria",
        getmerchant: "getmerchant",
        getapplogo:"getapplogo",
        setapplogo:"setapplogo",
        editlogo:"editlogo"

      },
      WorkHorse: {
        saveworkhorse: "saveworkhorse",
        updateworkhorse: "updateworkhorse",
        getworkhorse: "getworkhorse",
        getworkhorseitems: "getworkhorseitems",
        getworkhorsesubitems: "getworkhorsesubitems",
        getworkhorseactivities: "getworkhorseactivities",
      },
      Product: {
        updateproductrewardpercentage: "updateproductrewardpercentage",
        getproductbalance: "getproductbalance",
        creditproductaccount: "creditproductaccount",
        saveproduct: "saveproduct",
        createquickgiftcard: "createquickgiftcard",
        getproductcodes: "getproductcodes",
        getproducts: "getproducts",
        creategiftcard: "creategiftcard",
        creategiftcardcode: "creategiftcardcode",
        updateorderstatus: "updateorderstatus",
        confirmOrder: "confirmorder",
        getcategories: "getcategories",
        getsubcategories: "getsubcategories",
        saveninjaregistration: "saveninjaregistration",
      },
    },
    Pages: {
      ThankUCash: {
        LoginConfirm: "/account/loginconfirm",
        LoginRequest: "/account/login",
        Dashboard: {
          PTSP: "/provider/dashboard",
          Merchant: "/console/dashboard",
          NewApi: "/console/newapi",
          Merchants: "/console/merchants",
          MerchantSales: "/console/salesdashboard",
          MerchantRewards: "/console/rewardsdashboard",
          Acquirer: "/acquirer/dashboard",
          Rm: "/rm/dashboard",
          Store: "/store/dashboard",
          Console: "/console/dashboard",
        },
        PanelSMS: {
          CustomerGroup: "/console/merchant/customergroup",
          AllCustomers: "/console/merchant/customergroup/allcustomers",
          AllSmsCampaign: "/console/smscampaigns",
          AllSmsOverview: "/console/smsoverview",
          AddCampaign: "/console/addcampaigns",
          SmsOverview: "/console/smsoverview",
          CampaignDetail: "/console/campaigndetail",
          Merchant: "/console/merchantsms",
          SmsCampaign: "/console/smscampaign/analytics",
          CustomersCampaign: "/console/smscampaign/customerscampaign",

          MerchantCredits: "/console/smsparentmerchantdetail/smsmerchantdetail/merchantcredits",
          MerchantGroups: "/console/smsparentmerchantdetail/smsmerchantdetail/merchantgroups",
          MerchantOverview: "/console/smsparentmerchantdetail/smsmerchantdetail/msmsoverview",
          MerchantCampaigns: "/console/smsparentmerchantdetail/smsmerchantdetail/msmscampaigns",
          CreditH: "/console/credithistory",
        },
        PTSP: {
          Terminals: "/provider/terminals",
          Merchants: "/provider/merchants",
          RewardHistory: "/provider/rewardhistory",
          RedeemHistory: "/provider/redeemhistory",
          CommissionHistory: "/provider/commissionhistory",
          RequestHistory: "/provider/requesthistory",
          Profile: "/provider/profile",
        },
        OnBoardMerchant: {
          merchantOnboardingAccount: "/console/merchantOnboardingAccount",
          merchantOnboardingBusiness: "/console/merchantOnboardingBusiness"
        },
        // PanelConsole: {
        //   Profile: "/system/profile",
        //   LiveTerminals: "/system/liveterminals",
        //   Merchants: "/system/accounts/merchants",
        //   Stores: "/system/accounts/stores",

        //   Terminals: "/system/accounts/terminals",
        //   Acquirers: "/system/accounts/acquirers",
        //   Customers: "/system/accounts/customers",
        //   SuspendedCustomers: "/system/accounts/suspendedcustomers",
        //   BlockedCustomers: "/system/accounts/blockedcustomers",
        //   PgAccounts: "/system/accounts/gatewayproviders",
        //   PosAccounts: "/system/accounts/terminalprovider",
        //   Cashier: "/system/account/cashier",
        //   Transactions: "/system/transactions/all",
        //   Reward: "/system/transactions/rewardhistory",
        //   RewardClaim: "/system/transactions/rewardclaimhistory",
        //   Redeem: "/system/transactions/redeemhistory",
        //   Sale: "/system/sales/salehistory",
        //   Downloads: "/system/downloads",
        //   AppUser: {
        //     Dashboard: "/system/account/customer/dashboard",
        //     Reward: "/system/account/customer/transactions/rewardhistory",
        //     RewardClaim:
        //       "/system/account/customer/transactions/rewardclaimhistory",
        //     Redeem: "/system/account/customer/transactions/redeemhistory",
        //     Sale: "/system/account/customer/sales/salehistory",
        //     ManageAccount: "/system/account/customer/manage",
        //   },
        //   Merchant: {
        //     Dashboard: "/system/account/merchant/dashboard",
        //     Dashboards: {
        //       TodaysOverview: "/system/account/merchant/todaysoverview",
        //       Sales: "/system/account/merchant/salesdashboard",
        //       Rewards: "/system/account/merchant/rewardsdashboard",
        //     },
        //     // ManageAccount: "/system/account/merchant/manage",
        //     ManageAccount: {
        //       Overview: "/system/account/merchantmanager/overview",
        //       BusinessProfile:
        //         "/system/account/merchantmanager/businessprofile",
        //       Account: "/system/account/merchantmanager/account",
        //       Terminals: "/system/account/merchantmanager/terminals",
        //       Stores: "/system/account/merchantmanager/stores",
        //       SubAccounts: "/system/account/merchantmanager/managers",
        //       Cashiers: "/system/account/merchantmanager/cashiers",
        //       Configurations: "/system/account/merchantmanager/configurations",
        //       Products: "/system/account/merchantmanager/products",
        //     },
        //     SubAccounts: {
        //       Customers: "/system/account/merchant/customers",
        //       Stores: "/system/account/merchant/stores",
        //       Terminals: "/system/account/merchant/terminals",
        //       Managers: "/system/account/merchant/managers",
        //       Cashiers: "/system/account/merchant/cashiers",
        //     },
        //     GiftPoints: "/system/account/merchant/transactions/giftpoints",
        //     Product: {
        //       GiftCards: {
        //         QuickGiftCards:
        //           "/system/account/merchant/product/guickgiftcards",
        //         AddBulkGiftCards:
        //           "/system/account/merchant/product/creategiftcard",
        //         Wallet: "/system/account/merchant/product/giftcards/wallet",
        //         Manage: "/system/account/merchant/product/giftcards/manage",
        //         Codes:
        //           "/system/account/merchant/product/giftcards/saleshistory",
        //         GiftCards: "/system/account/merchant/product/giftcards",
        //       },
        //     },
        //     Reward: "/system/account/merchant/transactions/rewardhistory",
        //     RewardClaim:
        //       "/system/account/merchant/transactions/rewardclaimhistory",
        //     Redeem: "/system/account/merchant/transactions/redeemhistory",
        //     Sale: "/system/account/merchant/sales/salehistory",
        //   },
        //   Store: {
        //     Dashboard: "/system/account/store/dashboard",
        //     ManageAccount: "/system/account/store/manage",
        //     Reward: "/system/account/store/transactions/rewardhistory",
        //     RewardClaim:
        //       "/system/account/store/transactions/rewardclaimhistory",
        //     Redeem: "/system/account/store/transactions/redeemhistory",
        //     Sale: "/system/account/store/sales/salehistory",
        //     SubAccounts: {
        //       Customers: "/system/account/store/customers",
        //       Terminals: "/system/account/store/terminals",
        //     },
        //   },
        //   Acquirer: {
        //     Dashboard: "/system/account/acquirer/dashboard",
        //     ManageAccount: "/system/account/acquirer/manage",
        //     Reward: "/system/account/acquirer/transactions/rewardhistory",
        //     RewardClaim:
        //       "/system/account/acquirer/transactions/rewardclaimhistory",
        //     Redeem: "/system/account/acquirer/transactions/redeemhistory",
        //     Sale: "/system/account/acquirer/sales/salehistory",
        //     SubAccounts: {
        //       Terminals: "/system/account/acquirer/terminals",
        //     },
        //   },
        //   PgAccount: {
        //     Dashboard: "/system/account/gatewayprovider/dashboard",
        //     ManageAccount: "/system/account/gatewayprovider/manage",
        //     Reward:
        //       "/system/account/gatewayprovider/transactions/rewardhistory",
        //     RewardClaim:
        //       "/system/account/gatewayprovider/transactions/rewardclaimhistory",
        //     Redeem:
        //       "/system/account/gatewayprovider/transactions/redeemhistory",
        //     Sale: "/system/account/gatewayprovider/sales/salehistory",
        //   },
        //   PosAccount: {
        //     Dashboard: "/system/account/terminalprovider/dashboard",
        //     ManageAccount: "/system/account/terminalprovider/manage",
        //     Reward:
        //       "/system/account/terminalprovider/transactions/rewardhistory",
        //     RewardClaim:
        //       "/system/account/terminalprovider/transactions/rewardclaimhistory",
        //     Redeem:
        //       "/system/account/terminalprovider/transactions/redeemhistory",
        //     Sale: "/system/account/terminalprovider/sales/salehistory",

        //     SubAccounts: {
        //       Terminals: "/system/account/terminalprovider/terminals",
        //     },
        //   },
        //   Terminal: {
        //     Dashboard: "/system/account/terminal/dashboard",
        //     ManageAccount: "/system/account/terminal/manage",
        //     Reward: "/system/account/terminal/transactions/rewardhistory",
        //     RewardClaim:
        //       "/system/account/terminal/transactions/rewardclaimhistory",
        //     Redeem: "/system/account/terminal/transactions/redeemhistory",
        //     Sale: "/system/account/terminal/sales/salehistory",
        //     SubAccounts: {
        //       Customers: "/system/account/terminal/customers",
        //     },
        //   },
        //   UserOnboarding: {
        //     Merchant: "/console/onboarding/merchant",
        //     PendingMerchant: "/system/onboarding/pendingmerchantonboarding",
        //     PendingMerchantManager: "/system/onboarding/pendingmerchantmanager",
        //     Customer: "/system/onboarding/customer",
        //     Acquirer: "/system/onboarding/acquirer",
        //     PgAccount: "/system/onboarding/gatewayprovider",
        //     PosAccount: "/system/onboarding/terminalprovider",
        //   },

        //   Invoices: {
        //     All: "/system/invoices",
        //     Details: "/system/invoice",
        //   },
        //   Analytics: {
        //     Home: "/system/analytics/home",
        //     TodaysOverview: "/system/analytics/todaysoverview",
        //     SystemOverview: "/system/analytics/systemoverview",
        //     GrowthMap: "/system/analytics/growthmap",
        //     CustomerAnalytics: "/system/analytics/customeranalytics",
        //     Rewards: {
        //       DailyRewards: {
        //         Merchants: "/system/analytics/dailyrewards/merchants",
        //         Stores: "/system/analytics/dailyrewards/stores",
        //         Terminals: "/system/analytics/dailyrewards/terminals",
        //       },
        //       RewardsSummary: {
        //         Merchants: "/system/analytics/rewardssummary/merchants",
        //         Stores: "/system/analytics/rewardssummary/stores",
        //         Terminals: "/system/analytics/rewardssummary/terminals",
        //       },
        //     },
        //     Sales: {
        //       // DailySales: "/system/analytics/dailysales",

        //       DailySales: {
        //         Merchants: "/system/analytics/dailysales/merchants",
        //         Stores: "/system/analytics/dailysales/stores",
        //         Terminals: "/system/analytics/dailysales/terminals",
        //       },
        //       SalesSummary: {
        //         Merchants: "/system/analytics/salessummary/merchants",
        //         Stores: "/system/analytics/salessummary/stores",
        //         Terminals: "/system/analytics/salessummary/terminals",
        //       },
        //     },
        //   },
        //   Administration: {
        //     AppSlider: "/system/control/appslider",
        //     AdministrationHome: "/system/control/administration",
        //     ApiRequestHistory: "/system/control/apirequesthistory",
        //     Categories: "/system/control/categories",
        //     Roles: "/system/control/roles",
        //     AdminFeatures: "/system/control/features",
        //     ResponseCodes: "/system/control/responsecodes",
        //     SystemLog: "/system/control/systemlog",
        //     Apps: "/system/control/apps",
        //     Configurations: "/system/control/configurations",
        //     ConfigurationManager: "/system/control/configurationmanager",
        //     Verifications: "/system/control/verifications",
        //     UserSessions: "/system/control/usersessions",
        //     AdminUsers: "/system/control/adminusers",
        //     AddAdminUsers: "/system/control/addadminuser",
        //     AdminUser: {
        //       Dashboard: "/system/control/adminuser/dashboard",
        //       Sessions: "/system/control/adminuser/loginhistory",
        //     },
        //     WorkHorse: {
        //       SystemUpdates: "/system/control/workhorse/systemupdates",
        //       UpdateFeatureRequests:
        //         "/system/control/workhorse/updatefeaturerequests",
        //     },
        //   },

        // },
        PanelAcquirer: {
          SalesHistory: "/acquirer/saleshistory",
          Activity: "/acquirer/activity",
          SalesReport: "/acquirer/salesreport",
          RMReport: "/acquirer/rmreports",
          SubAccounts: "/acquirer/subaccounts",
          Terminals: "/acquirer/terminals",
          Campaigns: {
            Dashboard: "/acquirer/campaign/dashboard",
            Campaign: "/acquirer/campaign",
            Campaigns: "/acquirer/campaigns",
            AddCampaign: "/acquirer/addcampaign",
            Sales: "/acquirer/campaign/sales/saleshistory",
          },

          Accounts: {
            Branch: "/acquirer/branch",
            Rms: "/acquirer/rms",
            Subadmins: "/acquirer/subadmins",
          },
          AddAccount: {
            AddRm: "/acquirer/addrm",
            AddBranch: "/acquirer/addbranch",
          },
          AccountDetails: {
            BranchDetails: "/acquirer/branchdetails",
            Rm: "/acquirer/rm",
            SubAdminDetails: "/acquirer/subadmindetails",
            Branch: "/acquirer/branchs",
          },
          Branches: {
            BranchUpdate: "/console/acquirer/branchdetails/branchupdate",
            Manager: "/acquirer/branchdetails/manager",
            EditBranch: "/acquirer/branchdetails/editbranch",
            BranchTransactions: "/acquirer/branchdetails/sales",
            BranchTerminals: "/acquirer/branchdetails/terminals"
          },

          Analytics: {
            Sales: "/acquirer/salesanalytics",
          },
          Live: {
            Terminals: "/acquirer/live/terminals",
            Merchants: "/acquirer/live/merchants",
          },
          Manager: {
            Dashboard: "/acquirer/manager/dashboard",
            // RewardHistory: "/acquirer/terminal/rewardhistory",
            // RewardClaimHistory: "/acquirer/terminal/rewardclaimhistory",
            // RedeemHistory: "/acquirer/terminal/redeemhistory",
            Terminals: "/acquirer/manager/terminals",
            Stores: "/acquirer/branchdetails/stores",
            SalesHistory: "/acquirer/branchdetails/sales",
          },
          ManagerDetail: {
            Overview: "/acquirer/manager/dashboard",
            Terminals: "/acquirer/manager/terminals",
            Stores: "/acquirer/manager/stores",
            Teams: "/acquirer/manager/teams",
            Targets: "/acquirer/manager/targets",
          },
          Terminal: {
            Dashboard: "/acquirer/terminal/dashboard",
            // RewardHistory: "/acquirer/terminal/rewardhistory",
            // RewardClaimHistory: "/acquirer/terminal/rewardclaimhistory",
            // RedeemHistory: "/acquirer/terminal/redeemhistory",
            SalesHistory: "/acquirer/terminal/sales/saleshistory",
          },
          // LiveTerminals: "/acquirer/liveterminals",
          // Live: "/acquirer/live",
          Customers: "/acquirer/customers",
          Customer: "/acquirer/customer/dashboard",
          ReferredMerchants: "/acquirer/referredmerchants",
          AddTerminal: "/acquirer/addterminal",
          TerminalsSale: "/acquirer/terminalssale",
          Store: {
            Dashboard: "/acquirer/store/dashboard",
            SalesHistory: "/acquirer/store/saleshistory",
            Terminals: "/acquirer/store/terminals",
          },
          Merchant: {
            Dashboard: "/acquirer/merchant/dashboard",
            // SalesHistory: "/acquirer/merchant/saleshistory",
            Stores: "/acquirer/merchant/stores",
            Terminals: "/acquirer/merchant/terminals",
            SalesHistory: "/acquirer/merchant/sales/saleshistory",
          },

          Merchants: "/acquirer/merchants",
          CustomerUpload: "/acquirer/customerupload",
          CustomerOnboarding: "/acquirer/customeronboarding",
          MerchantOnboarding: "/acquirer/merchantonboarding",
          MerchantUpload: "/acquirer/merchantupload",
          MerchantManager: "/acquirer/merchantmanager",
          CustomerRewardHistory: "/acquirer/customer/rewardhistory",
          CustomerRedeemHistory: "/acquirer/customer/redeemhistory",
          Stores: "/acquirer/stores",
          Temp: "/acquirer/temp",
          AppUser: {
            Dashboard: "/acquirer/customer/dashboard",
            UpdateStore: "/acquirer/customer/updatestore",
            RewardHistory: "/acquirer/customer/rewardhistory",
            TUCPlusRewardHistory: "/acquirer/customer/tucplusrewardhistory",
            TUCPlusRewardClaimHistory:
              "/acquirer/customer/tucplusrewardclaimhistory",
            RedeemHistory: "/acquirer/customer/redeemhistory",
          },

          TerminalRewardHistory: "/acquirer/terminal/rewardhistory",
          TerminalRedeemHistory: "/acquirer/terminal/redeemhistory",
          Pssp: "/acquirer/gateways",

          // PaymentsHistory: "/acquirer/paymentshistory",

          TUCPlusRewardHistory: "/acquirer/tucplusrewardhistory",
          TUCPlusRewardClaimHistory: "/acquirer/tucplusrewardclaimhistory",
          RewardHistory: "/acquirer/rewardhistory",
          RedeemHistory: "/acquirer/redeemhistory",

          CommissionHistory: "/acquirer/commissionhistory",
          DailyCommissionHistory: "/acquirer/dailycommissionhistory",
          StoreSale: "/acquirer/storesale",
          Profile: "/acquirer/profile",

          RewardInvoices: "/acquirer/rewardinvoices",
          RedeemInvoices: "/acquirer/redeeminvoices",
        },

        Partner: {
          BulkMerchantUpload: "/acquirer/merchantupload",
          BulkMerchants: "/acquirer/bulkmerchants",
          BulkCustomers: "/partner/customers",
          Customers: "/partner/allcustomers",
          BulkCustomerUpload: "/partner/bulkcustomerupload",
        },
        PanelConsole: {
          UserProfile: "/console/userprofile",
          NotFound: "/console/notfound",
          Loans: {
            OverdueLoans: "/console/loan/loanoverdue",
            PaidLoan: "/console/loan/loanpaid",
            BaseLoans: "/console/loan",
            AllLoans: "/console/allloans",
            AllLoanActive: "/console/loanactive",
            CreditLoan: "/console/loanactivity/creditloan",
            Defaults: "/console/loanactivity/defaults",
            Rejected: "/console/loanactivity/rejected",
            Failed: "/console/loanactivity/failed",
            Processing: "/console/loanactivity/processing",
            LoanActivity: "/console/loanactivity",
            LoanDetail: "/console/loandetail",
            LoanRequests: "/console/loanrequests",
            LoanActive: "/console/loanactivity/loanactive",
            LoanAll: "/console/loanactivity/loanall"
          },
          Delivery: {

            OngoingOrders: {
              NewOrders: "/console/ongoingorders",
              Processing: "/console/ongoingorders/processing",
              Ready: "/console/ongoingorders/ready",
              Dispatch: "/console/ongoingorders/dispatch",
              Failed: "/console/ongoingorders/failed",
              Cancelled: "/console/ongoingorders/cancelled",
              ReturnRequested: "/console/ongoingorders/returnrequested"
            },
          },
          MerchantDeals: {
            MDeals: "/console/merchant/mdeals",
            MDealOverview: "/console/merchant/mdealoverview",
            MDealAdd: "/console/merchant/mdealadd",
            MDeal: "/console/merchant/mdeal",


          },

          UserProfileDetail: {
            EditProfile: "/console/userprofile/editprofile",
            Loginhistory: "/console/userprofile/loginhistory",
          },
          Payments: {
            Categories: "/console/vascategories",
            Vas: "/console/vas",
            Airtime: "/console/vas/airtime",
            Electricity: "/console/vas/electricity",
            TollGate: "/console/vas/tollgate",
            TvRecharge: "/console/vas/tvrecharge",
            VasProduct: "/console/vasproduct",
            SaleHistory: "/console/vasproduct/saleshistory",
            Packages: "/console/vasproduct/packages",
            VasAnalytics: "/console/vasanalytics",

          },
          Cashouts: {
            SaleHistory: "/console/cashouts",
            MerchantCashout: "/console/merchantcashouts",

          },
          PassWordSecurity: "/console/settings/adminuser/passwordsecurity",
          Loginhistory: "/console/settings/adminuser/loginhistory",
          FAQs: "/console/faqs",
          DealRequesthistory: "/console/dealrequest",
          AdministrationHome: "/console/administrationhome",
          DownloadList: "/console/downloads",
          GeneralHome: "/console/generalsettinghome",
          RewardHistory: "/console/rewardhistory",
          PendingRewardHistory: "/console/pendingrewardhistory",

          RedeemHistory: "/console/redeemhistory",
          SaleDetails: "/console/saledetail",
          ClaimHistory: "/console/rewardclaim",
          SalesHistory: "/console/saleshistory",
          PendingSalesHistory: "/console/conpendingtransaction",

          Activity: "/acquirer/activity",
          SalesReport: "/acquirer/salesreport",
          RMReport: "/acquirer/rmreports",
          SubAccounts: "/acquirer/subaccounts",
          Terminals: "/acquirer/terminals",
          AdminRoles: "/console/settings/adminroles",
          AddRoles: "/console/settings/addroles",
          EditRoles: "/console/settings/editroles",
          AdminUsers: "/console/settings/adminusers",
          //Loginhistory: "/console/settings/adminuser/loginhistory",
          // EditProfile: "/console/settings/adminuser/editprofile",

          Credentials: "/console/userprofile/credentials",

          Analytics: {
            TodaysOverview: "/console/todayoverviews",
            GrowthRevenue: "/console/growthreveneue",
            CustomerAnalytics: "/console/customeranalysis",
            MerchantAnalytics: "/console/merchantanalysis",
            Overview: "/console/analyticoverview",

            Sales: "/acquirer/salesanalytics",

          },

          GiftCard: {
            GiftCardHome: "/console/giftcards",
            GiftCardTransaction: "/console/gcrewardhistory",
            GiftCardRedeemHistory: "/console/gcredeemhistory",
          },


          Deals: {


            AddDeals: "/console/adddeals",
            AddDeal: "/console/adddeal",
            Deals: "/console/deals",
            Deal: "/console/deal",
            DealSold: "/console/deal/soldhistory",
            Analytics: "/console/deal/analytics",


            CodeUsage: "/console/codeusage",


            DealRedeem: "/console/deal/dealredeemhistory",
            FlashDeals: "/console/flashdeals",
            DealPromotion: "/console/dealpromotion",
            EditDeal: "/console/editdeal",
            DuplicateDeal: "/console/duplicatedeal",
            DealsOverview: "/console/dealsoverview",
            SoldHistory: "/console/soldhistory",
            ExternalUrl: "/console/externalurl",
            AppPromotion: "/console/apppromotion",
            DealCategory: "/console/dealcategory",
            DealsMerchant: "/console/dealsmerchant",
            DealsCustomer: "/console/dealscustomer",
            RedeemHistory: "/console/dealredeemhistory",
            NotificationHistory: "/console/deal/notificationhistory",
            EditDuplicateDeals: "/console/editduplicatedeal",

            AllPickups: {
              PendingPickup: "/console/allpickup/pendingpickup",
              CancelledPickup: "/console/allpickup/cancelled",
              ReturnedRequest: "/console/allpickup/returnedrequest",
            },
            RedeemedOrders: "/console/redeemedorders",
            ReturnedOrders: "/console/returnedorders",
            CancelledOrders: "/console/cancelledorders",
          },

          Administration: {
            // 
            LogFeature: "/console/logfeature",
            // 
            RequestHistory: "/console/requesthistory",
            VerificationRequest: "/console/verificationrequest",
            UserSession: "/console/usersession",
            Features: "/console/adminfeatures",
            SmsNotification: "/console/smsnotification",
            pushnotification: "/console/pushnotification",
            Configurations: "/console/configurations",
            Detailscity: "/console/detailscity",
            Getcity: "/console/getcity",
            GetcityArea: "/console/getcityarea",
            Citys: "/console/city",
            Vconfig: "/console/newapis",
            Apps: "/console/apps",
            App: "/console/apps/app",
            AppRequesthistory: "/console/app/requesthistory",
            MerchantCategories: "/console/merchantcategories",
            DealCategories: "/console/dealcategories",
            FAQCategories: "/console/faqcategories",
            SystemLog: "/console/systemlog",
            ResponseCode: "/console/responsecode",
            AppSlider: "/console/appslider",
            ImageGallery: "/console/imagegallery",
            RootCategories: "/console/rootcategories",
            SubCategories: "/console/subcategories",
            DealSubCategories: "/console/dealsubcategories",
            MerchantSubCategories: "/console/merchantsubcategories",
            Logo:"/console/logo"
          },
          CustomerPanel: {
            AllCustomers: "/console/customers/allcustomers",
            MerchantCustomers: "/console/customers/merchantcustomers",
            TucCustomers: "/console/customers/tuccustomers",
            AppDownloads: "/console/customers/tucappdownloadcustomers",
            Accounts: {
              WalletInfo: "/console/customer/moredetail/walletinfo",
              RewardNRedeem: "/console/customer/moredetail/rewardnredeem",
              CustomerOverview: "/console/customer/moredetail/overview",
              CustomerReward_Redeems: "/console/customer/moredetail/cstomer-rewards",
              Cashout: "/console/customer/moredetail/cashout",
            },
            DealHistory: {
              PurchaseHistory: '/console/customer/dealhistory/purchasehistory',
              RedeemHistory: '/console/customer/dealhistory/redeemhistory',
            },
            PurchaseHistory: '/console/customer/purchasehistory',
            VasHistory: '/console/customer/vashistory',
            RewardHistory: '/console/customer/rewardhistory',
            AcitivityTimeline: '/console/customer/activitytimeline',
            RedeemHistory: '/console/customer/redeemhistory',
            Referal: '/console/customer/referalhistory',
            ClaimHistory: '/console/customer/rewardclaimhistory',
            BnplConfigurations: '/console/customer/bnplconfiguration',
            PaymentsHistory: '/console/customer/paymentshistory',

            GiftPointHistory: '/console/customer/giftpointshistory',
            GiftCardHistory: '/console/customer/giftcardshistory',
            BuyPointHistory: '/console/customer/buypointshistory',
            LoanHistory: '/console/customer/loanhistory'
          },

          AcquirerPanel: {
            AcquirerDetails: "acquirers/acquirerdetails",
            SubAdminDetails: "/console/acquirer/subadmindetails",
            Subadmins: "/console/acquirer/subadmins",
            Configurations: "/console/acquirer/configuration",

            Dashboard: "/console/acquirer/dashboard",
            AcquirerAccount: "/console/acquirer/acquireraccount",

            ReferalAccount: "/console/refer/referalaccount",
            AnalticsReport: "/console/refer/analyticsreferal",
            CodeUsage: "/console/refer/codeusage",
            BankLoyalty: "/console/acquirer/bankloyalty",
            BankLoyaltyOverview: "/console/acquirer/bankloyalty/dashboard",
            BankLoyaltyMerchant: "/console/acquirer/bankloyalty/merchant",
            BankLoyaltyTransaction: "/console/acquirer/bankloyalty/transactions",
            BankLoyaltyRewardTransaction: "/console/acquirer/bankloyalty/rewardtransactions",
            BankLoyaltyRedeemTransaction: "/console/acquirer/bankloyalty/redeemtransactions",
            Merchants: "/console/acquirer/merchants",
            Terminals: "/console/acquirer/terminals",
            Stores: "/console/acquirer/stores",
            SalesHistory: "/console/acquirer/saleshistory",
            MerchantOnboarding: "/console//acquirer/merchantonboarding",

            Activity: "/console/acquirer/activity",
            Accounts: {
              Branch: "/console/acquirer/branchs",
              Rms: "/console/acquirer/rms",
              Subadmins: "/console/acquirer/subadmins",
              Managers: "/console/acquirer/managers",
            },
            Campaigns: {
              Dashboard: "/console/acquirer/campaign/dashboard",
              Campaign: "/console/acquirer/campaign",
              Campaigns: "/console/acquirer/campaigns",
              AddCampaign: "/console/acquirer/addcampaigns",
              Sales: "/console/acquirer/campaign/sales/saleshistory",
            },
            AddAccount: {
              AddRm: "/console/acquirer/addrm",
              AddBranch: "/console/acquirer/addbranch",
            },
            AccountDetails: {
              BranchDetails: "/console/acquirer/branchdetails",
              Rm: "/console/acquirer/rm",
              SubAdminDetails: "/console/acquirer/subadmindetails",
              Branch: "/console/acquirer/branchs",
            },
            Branches: {
              BranchUpdate: "/console/acquirer/branchdetails/branchupdate",
              Manager: "/console/acquirer/branchdetails/manager",
              EditBranch: "/console/acquirer/branchdetails/editbranch",
              BranchTransactions: "/console/acquirer/branchdetails/sales",
              BranchTerminals: "/console/acquirer/branchdetails/terminals",
              Stores: "/console/acquirer/branchdetails/stores",
              AddBranch: "/console/acquirer/addbranch",


            },
            Store: {
              Dashboard: "/console/acquirer/store/dashboard",
              SalesHistory: "/console/acquirer/store/saleshistory",
              Terminals: "/console/acquirer/store/terminals",
            },
            Merchant: {
              Dashboard: "/console/acquirer/merchant/dashboard",
              Downloads: "/console/acquirer/merchant/downloads",
              // SalesHistory: "/acquirer/merchant/saleshistory",
              Stores: "/console/acquirer/merchant/stores",
              Terminals: "/console/acquirer/merchant/terminals",
              SalesHistory: "/console/acquirer/merchant/sales/saleshistory",
            },
            Terminal: {
              Dashboard: "/console/acquirer/terminal/dashboard",
              // RewardHistory: "/acquirer/terminal/rewardhistory",
              // RewardClaimHistory: "/acquirer/terminal/rewardclaimhistory",
              // RedeemHistory: "/acquirer/terminal/redeemhistory",
              SalesHistory: "/console/acquirer/terminal/sales/saleshistory",
            },
            Manager: {
              Dashboard: "/console/acquirer/manager/dashboard",
              // RewardHistory: "/acquirer/terminal/rewardhistory",
              // RewardClaimHistory: "/acquirer/terminal/rewardclaimhistory",
              // RedeemHistory: "/acquirer/terminal/redeemhistory",
              Terminals: "/console/acquirer/manager/terminals",
              Stores: "/console/acquirer/branchdetails/stores",
              SalesHistory: "/console/acquirer/branchdetails/sales",
            },
            ManagerDetail: {
              Overview: "/console/acquirer/manager/dashboard",
              Terminals: "/console/acquirer/manager/terminals",
              Stores: "/console/acquirer/manager/stores",
              Teams: "/console/acquirer/manager/teams",
              Targets: "/console/acquirer/manager/targets",
            },

          },
          PTSPPanel: {
            PtspDetails: "acquirers/acquirerdetails",
            Dashboard: "/console/ptsp/dashboard",
            PtspAccount: "/console/ptsp/ptspaccount",
            Merchants: "/console/ptsp/merchants",
            Terminals: "/console/ptsp/terminals",
            Stores: "/console/ptsp/stores",
            SalesHistory: "/console/ptsp/saleshistory",
            CommissionHistory: "/console/ptsp/commissionhistory",
            MerchantOnboarding: "/console/ptsp/merchantonboarding",
            Activity: "/console/ptsp/activity",

            Terminal: {
              Dashboard: "/console/ptsp/terminal/dashboard",
              SalesHistory: "/console/ptsp/terminal/sales/saleshistory",
            },
            Merchant: {
              Dashboard: "/console/ptsp/merchant/dashboard",
              Transactions: "/console/ptsp/merchant/transactions",
              Terminals: "/console/ptsp/merchant/terminals",
              Products: "/console/ptsp/merchant/products",
              SalesHistory: "/console/ptsp/merchant/sales/saleshistory",
            },
            Store: {
              Dashboard: "/console/ptsp/store/dashboard",
              SalesHistory: "/console/ptsp/store/transactions",
              Terminals: "/console/ptsp/store/terminals"
            },
          },
          PartnerPanel: {
            PtspDetails: "acquirers/acquirerdetails",
            Dashboard: "/console/partner/dashboard",
            PartnerAccount: "/console/partner/partneraccount",
            Merchants: "/console/partner/merchants",
            Terminals: "/console/partner/terminals",
            SubAccounts: "/console/partner/subaccounts",
            Stores: "/console/partner/stores",
            SalesHistory: "/console/partner/saleshistory",
            MerchantOnboarding: "/console/partner/merchantonboarding",
            Activity: "/console/partner/activity",
            BulkMerchantUpload: "/console/partner/bulkmerchant",
            BulkMerchants: "/console/partner/bulkmerchants",
            BulkCustomers: "/console/partner/bulkcustomers",
            Customers: "/console/partner/allcustomers",
            BulkCustomerUpload: "/console/partner/bulkcustomerupload",
            Merchant: {
              Dashboard: "/console/partner/merchant/dashboard",
              // SalesHistory: "/acquirer/merchant/saleshistory",
              Stores: "/console/partner/merchant/stores",
              TopUp: "/console/partner/merchant/topup",

              Terminals: "/console/partner/merchant/terminals",
              SalesHistory: "/console/partner/merchant/sales/saleshistory",
            },
            Store: {
              Dashboard: "/console/partner/store/dashboard",
              SalesHistory: "/console/partner/store/saleshistory",
              Terminals: "/console/partner/store/terminals",
            },
            Terminal: {
              Dashboard: "/console/partner/terminal/dashboard",
              // RewardHistory: "/acquirer/terminal/rewardhistory",
              // RewardClaimHistory: "/acquirer/terminal/rewardclaimhistory",
              // RedeemHistory: "/acquirer/terminal/redeemhistory",
              SalesHistory: "/console/partner/terminal/sales/saleshistory",
            },
          },
          PSSPPanel: {
            Dashboard: "/console/gatewayprovider/dashboard",
            PsspAccount: "/console/gatewayprovider/psspaccount",
            SalesHistory: "/console/gatewayprovider/saleshistory",
            Reward: "/console/gatewayprovider/rewardshistory",
            Redeemed: "/console/gatewayprovider/redeemedhistory",
            Claim: "/console/gatewayprovider/claimhistory",
          },
          Campaigns: {
            Dashboard: "/acquirer/campaign/dashboard",
            Campaign: "/acquirer/campaign",
            Campaigns: "/acquirer/campaigns",
            AddCampaign: "/acquirer/addcampaign",
            Sales: "/acquirer/campaign/sales/saleshistory",
          },
          PanelMerchant: {
            SalesHistory: "/acquirer/saleshistory",
            Activity: "/acquirer/activity",
            SalesReport: "/acquirer/salesreport",
            RMReport: "/acquirer/rmreports",
            SubAccounts: "/acquirer/subaccounts",
            Terminals: "/acquirer/terminals"

          },
          Accounts: {
            Branch: "/acquirer/branch",
            Rms: "/acquirer/rms",
            Subadmins: "/acquirer/subadmins",

            TopUp: "/console/merchant/moredetail/topup",
            Wallate: "/console/merchant/moredetail/wallate",
            Cashout: "/console/merchant/moredetail/cashout",
            RewardPercentage: "/console/merchant/moredetail/rewardpercentage",
            SubAccounts: "/console/merchant/moredetail/subaccounts",

            Stores: "/console/merchant/stores",
            Terminals: "/console/merchant/terminals",
            Cashiers: "/console/merchant/cashiers"
          },
          AddAccount: {
            AddRm: "/acquirer/addrm",
            AddBranch: "/acquirer/addbranch",
          },
          AccountDetails: {
            BranchDetails: "/acquirer/branchdetails",
            Rm: "/acquirer/rm",
            SubAdminDetails: "/acquirer/subadmindetails",
            Branch: "/acquirer/branchs",
          },


          Live: {
            Terminals: "/acquirer/live/terminals",
            Merchants: "/acquirer/live/merchants",
          },

          Merchants: "/acquirer/merchants",
          MerchantOnboarding: "/acquirer/merchantonboarding",
          MerchantUpload: "/acquirer/merchantupload",
          MerchantManager: "/acquirer/merchantmanager",
          AppUser: {
            Dashboard: "/acquirer/customer/dashboard",
            UpdateStore: "/acquirer/customer/updatestore",
            RewardHistory: "/acquirer/customer/rewardhistory",
            TUCPlusRewardHistory: "/acquirer/customer/tucplusrewardhistory",
            TUCPlusRewardClaimHistory:
              "/acquirer/customer/tucplusrewardclaimhistory",
            RedeemHistory: "/acquirer/customer/redeemhistory",
          },
          Merchant: {
            Dashboard: "/console/merchant/dashboard",
            Downloads: "/console/merchant/downloads",
            Administration: "/console/merchant/dailyreconciliation",
            MDSMSCampaigns: "/console/merchant/mdsmscampaigns",
            GiftCards: "/console/merchant/giftcards",
            GiftCardsHistory: "/console/merchant/gcrewardhistory",

            SalesHistory: "/console/merchant/saleshistory",
            PendingTransaction: "/console/merchant/pendingtransaction",

            Stores: "/console/merchant/stores",
            Terminals: "/console/merchant/terminals",
            MerchantDetails: "/console/merchant/details",
            Configurations: "/console/merchant/configurations",
            BnplConfigurations: "/console/merchant/bnplconfigurations",
          },
          DailyReport: {
            SalesHistory: "/console/dailyreport/saleshistory",

            ReportSale: "/console/dailyreport/tusalereports",
            RewardHistory: "/console/dailyreport/rewardhistory",
            RedeemHistory: "/console/dailyreport/redeemhistory",
            ClaimHistory: "/console/dailyreport/rewardclaim",
          },
          TerminalRewardHistory: "/acquirer/terminal/rewardhistory",
          TerminalRedeemHistory: "/acquirer/terminal/redeemhistory",
          Pssp: "/acquirer/gateways",

          // PaymentsHistory: "/acquirer/paymentshistory",

          TUCPlusRewardHistory: "/acquirer/tucplusrewardhistory",
          TUCPlusRewardClaimHistory: "/acquirer/tucplusrewardclaimhistory",


          CommissionHistory: "/acquirer/commissionhistory",
          DailyCommissionHistory: "/acquirer/dailycommissionhistory",
          StoreSale: "/acquirer/storesale",
          Profile: "/console/profile",

          RewardInvoices: "/acquirer/rewardinvoices",
          RedeemInvoices: "/acquirer/redeeminvoices",

          UserOnboarding: {
            Merchant: "/console/onboarding/merchant",
            PendingMerchant: "/system/onboarding/pendingmerchantonboarding",
            PendingMerchantManager: "/system/onboarding/pendingmerchantmanager",
            Customer: "/system/onboarding/customer",
            Acquirer: "/console/onboarding/bank",

            Referal: "/console/onboarding/referal",


            PgAccount: "/console/onboarding/gatewayprovider",
            Partner: "/console/onboarding/partner",

            PosAccount: "/console/onboarding/terminalprovider",
          },
          BulkTerminal: "/console/bulkterminals",
          Transaction: "/console/sales/saleshistory",
          // RewardHistory: "/console/sales/rewardhistory",
          // RedeemHistory: "/console/sales/redeemhistory",
          RewardClaim: "/console/sales/rewardclaim",
          Bank: "/console/merchant/bank",
          MDSMSCampaigns: "/console/merchant/mdsmscampaigns",

          System: {
            Register: "/account/register",
          },
          Product: {
            Order: "orders",
            TucOrder: "tucorders",
            OngoingOrder: "ongoingorders",
            OrderDetail: "/console/orderhistory",
            Products: "products",
            Ambassadors: "ambassadors",
            AddProducts: "/console/addproduct",
            AddAmbassador: "/console/addambassador",
            EditProducts: "/console/editproduct",
            Inventory: "/console/product/inventory",
            OverviewProduct: "/console/product/dashboard",
            Variants: "/console/product/variants",
            Ambassador: "/console/product/ambassador"
          }
          ,
          MyBuisness: {
            SalesTrends: "/console/merchant/salestrend",
            SalesSummary: "/console/merchant/salesummary",
          },

          CashierDetails: {
            SalesHistory: "/console/cashier/sales/saleshistory",
            Overview: "/console/cashier/dashboard",
            Terminals: "/console/cashier/terminals",
            Credentials: "/console/cashier/credentials",




          },
          SubAccountDetails: {
            SalesHistory: "/console/subaccount/sales/saleshistory",

          },
          Campaign: {
            Dashboard: "/console/campaign/dashboard",
            SalesHistory: "/console/campaign/sales/saleshistory",
          },
          Loyality: {
            Overview: "/console/merchant/overview",
            Visits: "/console/merchant/visits",
            Customers: "/console/merchant/customers",
            Campaign: "/console/merchant/campaigns",
            CustomerDashboard: "/console/merchant/customer",
            RewardClaimHistory: "/console/merchant/rewardclaimhistory",
            RedeemHistory: "/console/merchant/redeemhistory",
            RewardHistory: "/console/merchant/rewardhistory",
            PendingRewardHistory: "/console/merchant/pendingrewardhistory",
            PendingTransaction: "/console/merchant/pendingtransaction",

          },
          AccountSettings: {
            Referal: 'referal',


            Cashiers: 'cashiers',
            Merchants: 'merchants',
            Terminals: 'terminals',
            Stores: 'stores',
            Payments: 'payments',
            Invoices: 'invoices',
            InvoiceDetails: '/console/invoices/invoice',

            Acquirer: 'acquirers',
            PTSP: 'ptsps',
            PSSP: 'gatewayproviders',
            Partner: 'partners',
            Customers: 'customers',
            MerchantPlans: 'plans',
            MerchantPlan: '/console/plans/plan',
            Sample: "sampletable",
            CustomerWallets: "customerwallets",
            MerchantWallets: "merchantwallets",
            CustomerWalletHistory: "customerwallethistory",
            MerchantWalletHistory: "merchantwallethistory",
            CustomerTransaction: "/console/customerwallethistory/details",
            MerchantTransaction: "/console/merchantwallethistory/details",
            Refunds: "refunds",
            Refbonus: 'refbonus',
            Referralb:'referralb',
            UniversalPoints: "supercash",

          },
          CstomerWallet: {
            Transactions: 'transactions',
            Analytics: 'analytics',
          },

          OngoingOrders: {
            NewOrders: "/console/ongoingorders",
            Processing: "/console/ongoingorders/processing",
            Ready: "/console/ongoingorders/ready",
            Dispatch: "/console/ongoingorders/dispatch",
            Failed: "/console/ongoingorders/failed",
            Cancelled: "/console/ongoingorders/cancelled",
            ReturnRequested: "/console/ongoingorders/returnrequested"
          },
          OrderHistory: "/console/allorderhistory",
          CancelledOrders: "/console/cancelledorderhistory",
          DeliveryOverview: "/console/deliveryoverview",

          //MerchantUpload: "/console/merchantupload",
          Settings: "settings/stores",
          Invoices: "/console/invoices",
          Invoice: "/console/invoice",
          Cashiers: "/console/cashiers",
          Cashier: "/console/cashier",
          Managers: "/console/managers",
          Stores: "/console/stores",
          //Terminals: "/console/terminals",
          Customers: "/console/customers",
          GiftPoints: "/console/giftpoints",
          GiftCards: "/console/giftcards",
          Products: "/console/products",
          Ambassadors: "/console/ambassadors",
          Downloads: "/console/downloads",

          Transactions: {
            Sales: "/console/transactions/saleshistory",
            Rewards: "/console/transactions/rewards",
            RewardClaims: "/console/transactions/rewardclaims",
            Redeem: "/console/transactions/redeem",
          },
          Store: {
            Dashboard: "/console/store/dashboard",
            Terminals: "/console/store/terminals",
            Cashiers: "/console/store/cashiers",
            RewardHistory: "/console/store/transactions/rewardhistory",
            RewardClaimHistory: "/console/store/transactions/rewardclaimhistory",
            RedeemHistory: "/console/store/transactions/redeemhistory",
            SalesHistory: "/console/store/sales/saleshistory",

          },
          Terminal: {
            Dashboard: "/console/terminal/dashboard",
            RewardHistory: "/console/terminal/transactions/rewardhistory",
            RewardClaimHistory: "/console/terminal/transactions/rewardclaimhistory",
            RedeemHistory: "/console/terminal/transactions/redeemhistory",
            SalesHistory: "/console/terminal/sales/saleshistory",
          },
          Customer: {
            Dashboard: "/console/customer/dashboard",
            RewardHistory: "/console/customer/sales/rewardhistory",
            RewardClaimHistory: "/console/customer/sales/rewardclaimhistory",
            RedeemHistory: "/console/customer/sales/redeemhistory",
            SalesHistory: "/console/customer/sales/salehistory",
          },

          RewardClaimHistory: "/merchant/rewardclaimhistory",

          BNPL: {
            EligibleCustomers: "/console/bnpl/eligiblecustomers",
            Merchants: "/console/bnpl/merchants",
            MerchantSettlement: "/console/bnpl/merchantsettlements",
            InvoicesAndReports: "/console/bnpl/reports",
            Invoice: "/console/bnpl/invoice",
            Overview: "/console/bnpl/overview",
            Transaction: "/console/bnpl/transaction"
          },

        },
        PanelRm: {
          Live: {
            Terminals: "/rm/live/terminals",
          },
          Accounts: {
            Terminals: "/rm/terminals",
            Stores: "/rm/stores",
          },
        },
        PanelMerchant:
        {
          GiftCard: {
            Dashboard: "/console/merchant/giftcard",
            Reward: "/console/merchant/gcrewardhistory",
            History: "/console/merchant/gcbalancehistory",
            Redeem: "/console/merchant/gcredeemhistory",
          },
          Product: {
            Order: "orders",
            OngoingOrder: "ongoingorders",
            OrderDetail: "/console/orderhistory",
            InvoiceDetail: "/console/invoicehistory",
            Products: "products",
            AddProducts: "/console/addproduct",
            EditProducts: "/console/editproduct",
            Inventory: "/console/product/inventory",
            OverviewProduct: "/console/product/dashboard",
            Variants: "/console/product/variants"
          }
        },

        PanelGiftCard: {
          Overview: "/console/giftcards/overview",

          Transactions: {
            Transactions: "/console/giftcards/transactions",
            Reward: "/console/giftcards/transactions/rewardhistory",
            History: "/console/giftcards/transactions/balancehistory",
            Redeem: "/console/giftcards/transactions/redeemhistory",
            GiftCards: "/console/giftcards/transactions/issuedhistory",
            SenderHistory: "/console/giftcards/transactions/history",
          },
        },

        PanelMerchantRewards: {
          Invoices: "/mr/invoices",
          Invoice: "/mr/invoice",
          Cashiers: "/mr/cashiers",
          Cashier: "/mr/cashier",
          Managers: "/mr/managers",
          GiftCard: {
            Dashboard: "/merchant/giftcard",
            Reward: "/merchant/giftcardsales/rewardhistory",
            History: "/merchant/giftcardsales/balancehistory",
            Redeem: "/merchant/giftcardsales/redeemhistory",
          },
          Transactions: {
            Sales: "/mr/transactions/saleshistory",
          },
          Stores: "/mr/stores",
          Store: {
            Dashboard: "/merchant/store/dashboard",
            Terminals: "/merchant/store/terminals",
            RewardHistory: "/merchant/store/rewardhistory",
            RewardClaimHistory: "/merchant/store/rewardclaimhistory",
            RedeemHistory: "/merchant/store/redeemhistory",
            SalesHistory: "/merchant/store/saleshistory",

          },

          Terminals: "/console/terminals",
          Terminal: {
            Dashboard: "/merchant/terminal/dashboard",
            RewardHistory: "/merchant/terminal/rewardhistory",
            RewardClaimHistory: "/merchant/terminal/rewardclaimhistory",
            RedeemHistory: "/merchant/terminal/redeemhistory",
            SalesHistory: "/merchant/terminal/saleshistory",
          },

          Live: "/merchant/live",
          TerminalLive: "/merchant/terminallive",
          Customers: "/merchant/customers",
          Customer: "/merchant/customer/dashboard",
          SubAccounts: "/merchant/subaccounts",

          RewardHistory: "/merchant/rewardhistory",
          RewardClaimHistory: "/merchant/rewardclaimhistory",
          RedeemHistory: "/merchant/redeemhistory",

          SalesHistory: "/merchant/saleshistory",
          StoreBankCollection: "/merchant/storebankcollection",

          AppUser: {
            Dashboard: "/merchant/customer/dashboard",
            RewardHistory: "/merchant/customer/rewardhistory",
            RewardClaimHistory: "/merchant/customer/rewardclaimhistory",
            RedeemHistory: "/merchant/customer/redeemhistory",
            SalesHistory: "/merchant/customer/saleshistory",

          },

          Pssp: "/merchant/gateways",

          CommissionHistory: "/merchant/commissionhistory",
          StoreSale: "/merchant/storesale",
          Profile: "/merchant/profile",

          RewardInvoices: "/merchant/rewardinvoices",
          RedeemInvoices: "/merchant/redeeminvoices",
        },
        Customers: "/system/customers",
        AppUsers: "/system/appusers",
        AppUser: {
          Dashboard: "/system/appuser/dashboard",
        },
        AddMerchant: "/system/addmerchant",
        Merchants: "/system/merchants",
        Merchant: {
          Dashboard: "/system/merchant/dashboard",
        },
        Stores: "/system/stores",
        AddStore: "/system/addstore",
        Store: {
          Dashboard: "/system/store/dashboard",
          UpdateStore: "/system/store/updatestore",
        },
        AddCashier: "/system/addcashier",
        Cashiers: "/system/cashiers",
        Cashier: {
          Dashboard: "/system/cashier/dashboard",
        },
        AddAcquirer: "/system/addacquirer",
        Acquirers: "/system/acquirers",
        Acquirer: {
          Dashboard: "/system/acquirer/dashboard",
        },
        AddPgAccount: "/system/addpgaccount",
        PgAccounts: "/system/pgaccounts",
        PgAccount: {
          Dashboard: "/system/pgaccount/dashboard",
        },
        AddPosAccount: "/system/addposaccount",
        PosAccounts: "/system/posaccounts",
        PosAccount: {
          Dashboard: "/provider/terminals",
        },
        AddPosTerminal: "/system/addposterminal",
        PosTerminals: "/system/posterminals",
        PosTerminal: {
          Dashboard: "/system/posterminal/dashboard",
        },
        Transactions: {
          Reward: "/system/transactions/reward",
          Redeem: "/system/transactions/redeem",
          Change: "/system/transactions/change",
          Gift: "/system/transactions/gift",
          Payments: "/system/transactions/payment",
        },
      },
      System: {
        NotFound: "/system/notfound",
        NotAccess: "/system/notaccess",
        Login: "/account/login",
        MerchantSetup: "/account/setup",
        MerchantSetupComplete: "/account/setupcomplete",
        MerchantRewardSetup: "/account/reward",
        ForgotPassword: "/account/forgotpassword",
        Dashboard: "/system/dashboard",
        Roles: "/system/roles",
        Features: "/system/features",
        Categories: "/system/categories",
        ResponseCodes: "/system/responsecodes",
        Log: "/system/log",
        Apps: "/system/apps",
        CoreUsage: "/system/usage",
        Configurations: "/system/configurations",
        Profile: "/system/profile",
        CoreTransactions: "/system/coretransactions",
        CoreUsers: "/system/coreusers",
        EditCoreUser: "/system/editcoreuser",
        AddCoreHelpers: "/system/addcorehelpers",
        EditCoreHelpers: "/system/editcorehelpers",
        CoreHelpers: "/system/corehelpers",
        CoreCommons: "/system/corecommons",
        CoreParameters: "/system/coreparameters",
      },
    },
    CommonResource: {
      Logout: "Logout",
      SaveFlashTitle: "Flash Deal",
      SaveFlashHelp: "Click Continue To Make Flash Deal",
      RemoveFlasTitle: "Unmark Flash Deal",
      RemovePromoteTitle: "Delete Promote Deal",
      RemoveAppPromoteTitle: "Delete AppPromote?",
      RemoveAppCategoryTitle: "Delete Category?",
      RemoveSubCategoryTitle: "Delete Subcategory",
      RemoveLogFeatureTitle: "Delete Log Feature?",
      RemoveFlashHelp: "Click Continue To Unmark Flash Deal",
      RemovePromoteHelp: "Click Continue To Delete Promote Deal",
      RemoveAppPromoteHelp: "Click Continue To Delete AppPromote",
      RemoveAppCategoryHelp: "Click Continue To Delete AppCategory",
      RemoveCategoryHelp: "Click Continue To Delete Category",
      RemoveSubCategoryHelp: "Click Continue To Delete SubCategory",
      RemoveLogFeatureHelp: "Click Continue To Delete Log Feature",
      DeleteImage: "Delete Image",
      DeleteImageHelp: "Click Continue To Delete Image",
      DuplicateCampaign: "Duplicate Campaign ?",
      ContinueUpdateHelp: "Click Continue to update details",

      SaveFilterTitle: "Save This Filter View",
      SaveFilterHelp: "Enter Filter Name",
      LogoutTitle: "Are You Sure You want to Logout?",
      DeliverTitle: "Are you sure you want to mark order as delivered?",
      CancelTitle: "Are you sure you want to update deal count?",
      ReferenceId: "Reference Id",
      ReferenceKey: " Reference Key",
      ReferenceCode: " Reference Code",
      SystemName: " Reference Code",
      Name: "Name",
      Select: "Select",
      Cancel: "Cancel",
      Continue: "Continue",
      Status: "Status",
      CreateDate: "Added On",
      CreatedBy: "Added By",
      ModifyDate: "Updated On",
      ModifyBy: "Updated By",
      AccessPin: "4 digit pin",
      FilterName: "filter name",
      UpdateTitle: "Update details ?",
      BlockMerchantTitle: "Block Merchant ?",
      BlockPsspTitle: "Block Pssp ?",
      BlockAcquirerTitle: "Block Acquirer ?",
      BlockUserTitle: "Inactive User ?",
      ActiveUserTitle: "Active User ?",
      BlockPtspTitle: "Block Ptsp ?",
      BlockPartnerTitle: "Block Partner ?",
      UnBlockMerchantTitle: "UnBlock Merchant ?",
      UnBlockPsspTitle: "UnBlock Pssp ?",
      UnBlockAcquirerTitle: "UnBlock Acquirer ?",
      UnBlockPtspTitle: "UnBlock Ptsp ?",
      UnBlockPartnerTitle: "UnBlock Partner ?",
      BlockCustomerTitle: "Block Customer ?",
      SuspendCustomerTitle: "Suspend Customer ?",

      UnBlockCustomerTitle: "Active Customer ?",
      RejectCashout: "Reject Cashout",
      ApproveCashout: "Approve Cashout",


      UpdateHelp: "Enter 4 digit pin to update details",
      DeleteTitle: "Delete View ?",
      DeleteDetails: "Delete Details ?",
      DeleteProductDetails: "Delete Product Details ?",

      RefundTitle: "Refund Customer ?",
      RefundHelp: "Delete details ?",

      SupercashTitle: "Supercash Customer ?",
      // RefundHelp: "Delete details ?",


      ResetKey: "Reset Key",
      DeleteDeal: "Delete Deal ?",
      DeleteDealHelp: "Click continue to delete deal ",
      DeleteHelp:
        "Details can not be recovered once deleted. Do you want to continue ?",
      ResetKeyHelp:
        "Press continue to reset app keys?",
      InvalidImage: "Invalid image dimension. Please select valid image",
      deleteRootCategory: "Delete Root Category"
    },
    FilterStates: {
      Default: 'default',
      NoChange: 'nochange',
      Modified: 'modified'
    }
    
    
    
  };
  public darkStyle = [];
  public CheckMode: string = 'dark';
  public _Assets = {
    Box: {
      root: "card shadow-none",
      header:
        "card-header d-flex pd-y-15  align-items-center justify-content-between",
      headerTitle: "h6  m-0 mt-1 mb-1",
      filterIcons: "tx-12",
      headerTitleRight: "d-flex",
      headerTitleRightItem: "link-03 lh-0 mg-l-10",
      bodyFilter: "card-body pd-y-10",
      bodyContent: "card-body p-0",
      ShowIcon:
        "wd-35 ht-35 align-items-center justify-content-center op-6 d-none d-sm-flex",
      TextCenter: "text-center",
    },
  };
  profileImage: any;
  logoImageUrl:any;
  constructor(
    public _Location: Location,
    public _Router: Router,
    public _TranslateService: TranslateService,
    public _Ng2FileInputService: Ng2FileInputService,
    private _Http: HttpClient
  ) {

    this.FullContainer = false;
    this.ContainerHeight = window.innerHeight - 56;
    // var UserTimeZone = SystemHelper.GetUserTimeZone();
    // if (UserTimeZone != undefined && UserTimeZone != null) {
    //   this.AppConfig.TimeZone = UserTimeZone;
    // } else {
    //   this.AppConfig.TimeZone = "Africa/Lagos";
    // }
    this._TranslateService
      .get("Common.ReferenceId")
      .subscribe((_Message: string) => {
        this.AppConfig.CommonResource.ReferenceId = _Message;
      });
    this._TranslateService
      .get("Common.InvalidImage")
      .subscribe((_Message: string) => {
        this.AppConfig.CommonResource.ReferenceId = _Message;
      });

    this._TranslateService
      .get("Common.ReferenceCode")
      .subscribe((_Message: string) => {
        this.AppConfig.CommonResource.ReferenceCode = _Message;
      });

    this._TranslateService
      .get("Common.ReferenceKey")
      .subscribe((_Message: string) => {
        this.AppConfig.CommonResource.ReferenceKey = _Message;
      });

    this._TranslateService.get("Common.Name").subscribe((_Message: string) => {
      this.AppConfig.CommonResource.Name = _Message;
    });

    this._TranslateService
      .get("Common.Select")
      .subscribe((_Message: string) => {
        this.AppConfig.CommonResource.Select = _Message;
      });

    this._TranslateService
      .get("Common.Cancel")
      .subscribe((_Message: string) => {
        this.AppConfig.CommonResource.Cancel = _Message;
      });
    this._TranslateService
      .get("Common.Continue")
      .subscribe((_Message: string) => {
        this.AppConfig.CommonResource.Continue = _Message;
      });

    this._TranslateService
      .get("Common.Status")
      .subscribe((_Message: string) => {
        this.AppConfig.CommonResource.Status = _Message;
      });

    this._TranslateService
      .get("Common.CreateDate")
      .subscribe((_Message: string) => {
        this.AppConfig.CommonResource.CreateDate = _Message;
      });

    this._TranslateService
      .get("Common.CreatedBy")
      .subscribe((_Message: string) => {
        this.AppConfig.CommonResource.CreatedBy = _Message;
      });
    this._TranslateService
      .get("Common.ModifyDate")
      .subscribe((_Message: string) => {
        this.AppConfig.CommonResource.ModifyDate = _Message;
      });
    this._TranslateService
      .get("Common.ModifyBy")
      .subscribe((_Message: string) => {
        this.AppConfig.CommonResource.ModifyBy = _Message;
      });
    this._TranslateService
      .get("Common.DeleteTitle")
      .subscribe((_Message: string) => {
        this.AppConfig.CommonResource.DeleteTitle = _Message;
      });
    this._TranslateService
      .get("Common.DeleteHelp")
      .subscribe((_Message: string) => {
        this.AppConfig.CommonResource.DeleteHelp = _Message;
      });
    this.RefreshHelper();
  }
  // AccountKeyHelper:any;
  RefreshHelper() {
    this.AppConfig.Client = this.GetStorage(this.AppConfig.Storage.OReqH);
    this.AppConfig.Host = window.location.host;
    if (this.AppConfig.Client != null) {
      this.AppConfig.ClientHeader = {
        "Content-Type": "application/json; charset=utf-8",
        hcak: this.AppConfig.Client.hcak,
        hcavk: this.AppConfig.Client.hcavk,
        hctk: null,
        hcudlt: this.AppConfig.Client.hcudlt,
        hcudln: this.AppConfig.Client.hcudln,
        hcuak: this.AppConfig.Client.hcuak,
        hcupk: this.AppConfig.Client.hcupk,
        hcudk: this.AppConfig.Client.hcudk,
        hcuata: "",
        hcuatp: "",
      };
    }
    if (this.AppConfig.Host == "console.thankucash.com") {
      this.AppConfig.HostConnect = "https://dealsiconnect.thankucash.com/";
      this.AppConfig.DealsWebSiteUrl = "https://deals.thankucash.com/";
      this.AppConfig.MediaManagerConnect = "https://mediamanagerconnect.thankucash.com/api/v1"
      this.AppConfig.PayStackKey = "pk_live_4acd36db0e852af843e16e83e59e7dc0f89efe12";
    }
    else if (this.AppConfig.Host == "console.thankucash.co") {
      this.AppConfig.HostConnect = "https://webconnect.thankucash.co/";
      this.AppConfig.MediaManagerConnect = "https://mediamanagerconnect.thankucash.co/api/v1"
      this.AppConfig.PayStackKey = "pk_test_4cca266d686312285a54570e20b46a808ae0d0f6";
    }
    else if (this.AppConfig.Host == "console.thankucash.tech") {
      this.AppConfig.HostConnect = "https://dealsiconnect.thankucash.tech/";
      this.AppConfig.DealsWebSiteUrl = "https://deals.thankucash.tech/";
      this.AppConfig.MediaManagerConnect = "https://mediamanagerconnect.thankucash.tech/api/v1"
      this.AppConfig.PayStackKey = "pk_test_4cca266d686312285a54570e20b46a808ae0d0f6";
    }
    else if (this.AppConfig.Host == "console.thankucash.dev") {
      // this.AppConfig.HostConnect = "https://webconnect.thankucash.dev/";
      this.AppConfig.HostConnect = "https://dealsiconnect.thankucash.dev/";
      this.AppConfig.DealsWebSiteUrl = "https://deals.thankucash.dev/";
      this.AppConfig.MediaManagerConnect = "https://mediamanagerconnect.thankucash.dev/api/v1"
      this.AppConfig.PayStackKey = "pk_test_4cca266d686312285a54570e20b46a808ae0d0f6";
    }
    else if (this.AppConfig.Host == "console.thankucash.us") {
      this.AppConfig.HostConnect = "https://webconnect.thankucash.us/";
      this.AppConfig.MediaManagerConnect = "https://mediamanagerconnect.thankucash.dev/api/v1"
      this.AppConfig.PayStackKey = "pk_test_4cca266d686312285a54570e20b46a808ae0d0f6";
    }
    else if (this.AppConfig.Host == "beta-console.thankucash.com") {
      this.AppConfig.HostConnect = "https://beta-webconnect.thankucash.com/";
      this.AppConfig.MediaManagerConnect = "https://mediamanagerconnect.thankucash.dev/api/v1"
      this.AppConfig.PayStackKey = "pk_live_4acd36db0e852af843e16e83e59e7dc0f89efe12";
    }



    else if (this.AppConfig.Host == "playconsole.thankucash.com") {
      this.AppConfig.HostConnect = "https://playwebconnect.thankucash.com/";
      this.AppConfig.PayStackKey = "pk_test_4cca266d686312285a54570e20b46a808ae0d0f6";
    } else if (this.AppConfig.Host == "testconsole.thankucash.com") {
      this.AppConfig.HostConnect = "https://testwebconnect.thankucash.com/";
      this.AppConfig.MediaManagerConnect = "https://testcdnconnect.thankucash.com/api/v1"
      this.AppConfig.PayStackKey = "pk_test_4cca266d686312285a54570e20b46a808ae0d0f6";
      // } else if (this.AppConfig.Host == "localhost:4200") {
    }

    else if (this.AppConfig.Host == "localhost:4444") {
      // this.AppConfig.HostConnect = "https://localhost:5001/";
      // this.AppConfig.HostConnect = "https://webconnect.thankucash.com/";
      // this.AppConfig.HostConnect = "https://testwebconnect.thankucash.com/";
      this.AppConfig.HostConnect = "https://webconnect.thankucash.tech/";
      this.AppConfig.DealsWebSiteUrl = "https://deals.thankucash.tech/";
      this.AppConfig.MediaManagerConnect = "https://testcdnconnect.thankucash.com/api/v1"
      this.AppConfig.PayStackKey = "pk_test_4cca266d686312285a54570e20b46a808ae0d0f6";
    }

    else if (this.AppConfig.Host == "localhost:4440") {
      // this.AppConfig.HostConnect = "https://localhost:5001/";
      // this.AppConfig.HostConnect = "https://webconnect.thankucash.com/";
      // this.AppConfig.HostConnect = "https://webconnect.thankucash.dev/";
      this.AppConfig.HostConnect = "https://dealsiconnect.thankucash.dev/";
      this.AppConfig.DealsWebSiteUrl = "https://deals.thankucash.dev/";
      // this.AppConfig.HostConnect = "https://webconnect.thankucash.tech/";
      this.AppConfig.PayStackKey = "pk_test_4cca266d686312285a54570e20b46a808ae0d0f6";
    }
    else if (this.AppConfig.Host == "localhost:4201") {
      // this.AppConfig.HostConnect = "https://localhost:5001/";
      // this.AppConfig.HostConnect = "https://webconnect.thankucash.com/";
      this.AppConfig.HostConnect = "https://webconnect.thankucash.dev/";
      // this.AppConfig.HostConnect = "https://webconnect.thankucash.tech/";
      this.AppConfig.PayStackKey = "pk_test_4cca266d686312285a54570e20b46a808ae0d0f6";
    }
    else if (this.AppConfig.Host == "localhost:4203") {
      // this.AppConfig.HostConnect = "https://testwebconnect.thankucash.com/";
      this.AppConfig.HostConnect = "https://webconnect.thankucash.com/";
      // this.AppConfig.MediaManagerConnect = "https://mediamanagerconnect.thankucash.com/api/v1"
      // this.AppConfig.HostConnect = "https://localhost:5001/";
      this.AppConfig.MediaManagerConnect = "https://testcdnconnect.thankucash.com/api/v1"
      // this.AppConfig.HostConnect = "https://testappconnect.thankucash.com/";
      this.AppConfig.PayStackKey = "pk_test_4cca266d686312285a54570e20b46a808ae0d0f6";
    }
    else if (this.AppConfig.Host == "console.thankucash.dev") {
      // this.AppConfig.HostConnect = "https://webconnect.thankucash.dev/";
      this.AppConfig.HostConnect = "https://dealsiconnect.thankucash.dev/";
    }
    else if (this.AppConfig.Host == "management.thankucash.com") {
      this.AppConfig.HostConnect = "https://rdxconnect.thankucash.com/";
    }
    else {
      this.AppConfig.HostConnect = "";
      this.AppConfig.PayStackKey = "pk_test_4cca266d686312285a54570e20b46a808ae0d0f6";
    }
    this.Select2Options_Multiple = {
      multiple: true,
      placeholder: "Business Categories",
    };
    this.Select2Options_Multiple_Location = {
      multiple: true,
      placeholder: "Select location",
    };
    this.Select2Options_state = {
      multiple: false,
      placeholder: "Select State",
    };

    this.Select2Options_app = {
      multiple: false,
      placeholder: "Select Page",
    };
    this.Select2Options_city = {
      multiple: false,
      placeholder: "Select City",
    };

    this.S2_Sort_Option = {
      multiple: false,
      placeholder: "Sort Results",
    };

    this.Select2Options_Single = {
      multiple: false,
      placeholder: "Sort Results",
    };
    this.Select2Options_SearchColumn = {
      multiple: true,
      placeholder: "Search On Fields",
    };
    // isInsideArray(){
    //     return false;
    // }
    var UserAccountInformation = this.GetStorage(
      this.AppConfig.Storage.Account
    );
    // this.AccountKeyHelper=UserAccountInformation.UserAccount.AccountKey;
    if (UserAccountInformation != null) {
      this.AccessKey = UserAccountInformation.AccessKey;
      this.PublicKey = UserAccountInformation.PublicKey;
      this.LoginTime = UserAccountInformation.LoginTime;
      this.User = UserAccountInformation.User;
      this.UserAccount = UserAccountInformation.UserAccount;
      this.UserOwner = UserAccountInformation.UserOwner;
      this.UserCountry = UserAccountInformation.UserCountry;
      this.UserRoles = UserAccountInformation.UserRoles;
      if (UserAccountInformation.UserCountry) {
        // if (UserAccountInformation.UserCountry.CountryId == 1) {
        //   this.AppConfig.TimeZone = 'Africa/Lagos';
        // }
        // else if (UserAccountInformation.UserCountry.CountryId == 87) {
        //   this.AppConfig.TimeZone = 'Africa/Accra';
        // }
        // else if (UserAccountInformation.UserCountry.CountryId == 118) {
        //   this.AppConfig.TimeZone = 'Africa/Lagos';
        // }
        // else {
        //   this.AppConfig.TimeZone = 'Africa/Lagos';
        // }
        if (UserAccountInformation.UserCountry.CountryIso == 'ng') {
          this.AppConfig.CurrencySymbol = this.UserCountry.CurrencyCode;
          this.AppConfig.CurrencySymbolCustom = this.UserCountry.CurrencyCode;
          this.CountryCode = '+' + UserAccountInformation.UserCountry.CountryIsd;

          this.UserCountrycode = UserAccountInformation.UserCountry.CountryName;
          this.UserCountrykey = UserAccountInformation.UserCountry.CountryKey
          this.UserCountryId = UserAccountInformation.UserCountry.CountryId
          this.AppConfig.TimeZone = 'Africa/Lagos';

        }
        else if (UserAccountInformation.UserCountry.CountryIso == 'GH') {
          this.AppConfig.CurrencySymbolCustom = "&#8373;";
          this.AppConfig.CurrencySymbol = "₵"
          this.CountryCode = '+' + UserAccountInformation.UserCountry.CountryIsd;

          this.UserCountrycode = UserAccountInformation.UserCountry.CountryName;
          this.UserCountrykey = UserAccountInformation.UserCountry.CountryKey
          this.UserCountryId = UserAccountInformation.UserCountry.CountryId;
          this.AppConfig.TimeZone = 'Africa/Accra';

        }
        else {
          this.AppConfig.CurrencySymbolCustom = "KSh";
          this.AppConfig.CurrencySymbol = "K"
          this.CountryCode = '+' + UserAccountInformation.UserCountry.CountryIsd;

          this.UserCountrycode = UserAccountInformation.UserCountry.CountryName;
          this.UserCountrykey = UserAccountInformation.UserCountry.CountryKey
          this.UserCountryId = UserAccountInformation.UserCountry.CountryId;
          this.AppConfig.TimeZone = 'Africa/Nairobi';
        }
      }
      else {
        this.NotifyError('Contact Support');
        this._Router.navigate([
          this.AppConfig.Pages.System.Login,

        ]);
      }




      if (UserAccountInformation.Features) {
        this.UserAccount.Features = UserAccountInformation.Features;
      } else {
        this.UserAccount.Features = [];
      }
      if (UserAccountInformation.Features) {
        UserAccountInformation.Features.forEach(element => {
          this.SystemName.push(element.SystemName);
          // this.UserAccount.SystemName = SystemName

        });
      }
      else {
        this.NotifyError('Contact Support');

      }





      if (
        this.UserAccount.AccountTypeCode == this.AppConfig.AccountType.Store
      ) {
        this.AppConfig.ActiveOwnerKey = this.UserAccount.AccountKey;
        this.AppConfig.ActiveOwnerId = this.UserAccount.AccountId;
        this.AppConfig.ActiveOwnerDisplayName = this.UserAccount.DisplayName;
        this.AppConfig.ActiveOwnerAccountCode = this.UserAccount.AccountCode;
        this.AppConfig.ActiveOwnerAccountTypeCode = this.UserAccount.AccountTypeCode;
        this.AppConfig.ActiveOwnerIsTucPlusEnabled = this.UserOwner.IsTucPlusEnabled;
      } else if (
        this.UserAccount.AccountTypeCode ==
        this.AppConfig.AccountType.MerchantSubAccount
      ) {
        this.AppConfig.ActiveOwnerKey = this.UserOwner.AccountKey;
        this.AppConfig.ActiveOwnerId = this.UserOwner.AccountId;
        this.AppConfig.ActiveOwnerDisplayName = this.UserOwner.DisplayName;
        this.AppConfig.ActiveOwnerAccountCode = this.UserOwner.AccountCode;
        this.AppConfig.ActiveOwnerAccountTypeCode = this.UserOwner.AccountTypeCode;
        this.AppConfig.ActiveOwnerIsTucPlusEnabled = this.UserOwner.IsTucPlusEnabled;
      } else if (
        this.UserAccount.AccountTypeCode ==
        this.AppConfig.AccountType.AcquirerSubAccount
      ) {
        this.AppConfig.ActiveOwnerKey = this.UserOwner.AccountKey;
        this.AppConfig.ActiveOwnerId = this.UserOwner.AccountId;
        this.AppConfig.ActiveOwnerDisplayName = this.UserOwner.DisplayName;
        this.AppConfig.ActiveOwnerAccountCode = this.UserOwner.AccountCode;
        this.AppConfig.ActiveOwnerAccountTypeCode = this.UserOwner.AccountTypeCode;
        this.AppConfig.ActiveOwnerIsTucPlusEnabled = this.UserOwner.IsTucPlusEnabled;
      } else {
        this.AppConfig.ActiveOwnerKey = this.UserAccount.AccountKey;
        this.AppConfig.ActiveOwnerId = this.UserAccount.AccountId;
        this.AppConfig.ActiveOwnerDisplayName = this.UserAccount.DisplayName;
        this.AppConfig.ActiveOwnerAccountCode = this.UserAccount.AccountCode;
        this.AppConfig.ActiveOwnerAccountTypeCode = this.UserAccount.AccountTypeCode;
        this.AppConfig.ActiveOwnerIsTucPlusEnabled = this.UserAccount.IsTucPlusEnabled;
        this.AppConfig.ActiveOwnerRoleKey = this.UserAccount.RoleKey;

      }

      if (this.UserRoles != undefined) {
        this.UserRoles.forEach((_UserRole) => {
          if (_UserRole.RolePermissions.length > 0) {
            _UserRole.RolePermissions.forEach((_Permission) => {
              this.UserRolePermissions.push(_Permission);
            });
          }
        });
      }
    }
    var UserLocationInformation = this.GetStorage(
      this.AppConfig.Storage.Location
    );
    if (UserLocationInformation != null) {
      this.UserLocation = UserLocationInformation;
    }
    var UserDeviceInformation = this.GetStorage(this.AppConfig.Storage.Device);
    if (UserDeviceInformation != null) {
      this.UserDevice = UserDeviceInformation;
    }

    this.RefreshIdsNKeys();
  }

  GetFixedDecimalNumber(Value) {
    if (Value != undefined && Value != null) {
      return Math.round((Value + Number.EPSILON) * 100) / 100;
    }
    else {
      return Value;
    }
  }
  GetPercentageFromNumber(SmallNumber, BigNumber) {
    return (SmallNumber / BigNumber) * 100;
  }
  GetAmountFromPercentage(Amount, Percentage) {
    return this.GetFixedDecimalNumber((Amount * Percentage) / 100);
  }
  ValidateData() {
    SystemHelper.ValidatePermission(this.SystemName);
  }

  public IsArrayElementAvailable: boolean = false;
  isInsideArray(SystemName) {

    // return true; 
    for (let index = 0; index < this.SystemName.length; index++) {
      const element = this.SystemName[index];
      if (element == SystemName) {
        return false;
        // this.IsArrayElementAvailable = true;
      }
      else {

        return true;
        // this.IsArrayElementAvailable = false;

      }
    }
  }



  RefreshIdsNKeys(): void {
    var StorageDetails = this.GetStorage(this.AppConfig.Storage.ActiveMerchant);
    if (StorageDetails != null) {
      this.AppConfig.ActiveMerchantReferenceKey = StorageDetails.ReferenceKey;
      this.AppConfig.ActiveMerchantReferenceId = StorageDetails.ReferenceId;
    }

    var StorageDetails = this.GetStorage(this.AppConfig.Storage.ActiveStore);
    if (StorageDetails != null) {
      this.AppConfig.ActiveStoreReferenceKey = StorageDetails.ReferenceKey;
      this.AppConfig.ActiveStoreReferenceId = StorageDetails.ReferenceId;
    }

    var StorageDetails = this.GetStorage(this.AppConfig.Storage.ActiveCashier);
    if (StorageDetails != null) {
      this.AppConfig.ActiveCashierReferenceKey = StorageDetails.ReferenceKey;
      this.AppConfig.ActiveCashierReferenceId = StorageDetails.ReferenceId;
    }

    var StorageDetails = this.GetStorage(this.AppConfig.Storage.ActiveTerminal);
    if (StorageDetails != null) {
      this.AppConfig.ActiveTerminalReferenceKey = StorageDetails.ReferenceKey;
      this.AppConfig.ActiveTerminalReferenceId = StorageDetails.ReferenceId;
    }

  }
  ProcessLogout() {
    swal({
      position: "center",
      title: this.AppConfig.CommonResource.LogoutTitle,
      text: "Click on Logout button to confirm",
      animation: false,
      customClass: this.AppConfig.Alert_Animation,
      showCancelButton: true,
      confirmButtonColor: this.AppConfig.Color_Red,
      cancelButtonColor: this.AppConfig.Color_Grey,
      confirmButtonText: this.AppConfig.CommonResource.Logout,
      cancelButtonText: this.AppConfig.CommonResource.Cancel

    }).then((result) => {
      if (result.value) {
        var pData = {
          Task: this.AppConfig.Api.Logout
        };
        let _OResponse: Observable<OResponse>;
        _OResponse = this.PostData(this.AppConfig.NetworkLocation.V1.System, pData);
        _OResponse.subscribe(
          _Response => {
            if (_Response.Status == this.StatusSuccess) {
              this.NotifySuccess(_Response.Message);
              this.DeleteStorage('temploc');

            }
            else {
              this.NotifyError(_Response.Message);
            }
          },
          _Error => {
          });
        this.DeleteStorage(this.AppConfig.Storage.Account);
        this.DeleteStorage(this.AppConfig.Storage.Location);
        this.DeleteStorage(this.AppConfig.Storage.OReqH);
        this.DeleteStorage(this.AppConfig.Storage.Permissions);
        this._Router.navigate([this.AppConfig.Pages.System.Login]);
      }
      else { }
    });
  }

  NavigateBack() {
    //  this.canActivate();
    if (window.location.pathname == '/console/dashboard') {
      this.ProcessLogout()
    }
    else {
      this._Location.back();
    }
  }

  ToggleAsidePanel() {
    if (this.ShowAsidePanel == false) {
      this.ShowAsidePanel = true;
    } else {
      this.ShowAsidePanel = false;
    }
  }
  public _FileSelect_Poster: string = this.AppConfig.Storage.ImageStoragePoster;
  _FileSelect_Poster_Data: OStorageContent = {
    Name: null,
    Content: null,
    Extension: null,
    TypeCode: null,
    Height: 400,
    Width: 800,
  };
  _FileSelect_Poster_Processing = false;

  public _FileSelect_Poster_Reset(): void {
    this._FileSelect_Poster_Data.Name = null;
    this._FileSelect_Poster_Data.Content = null;
    this._FileSelect_Poster_Data.Extension = null;

    if (this._FileSelect_Poster_Data.Content != null) {
      this._Ng2FileInputService.reset(this._FileSelect_Poster);
    }
  }

  _Icon_Cropper_ChangedEvent: any = '';
  _Icon_Cropper_BaseImage: string = '';
  _Icon_Cropper_Image: any = '';
  _Icon_Cropper_Data: OStorageContent = {
    Name: null,
    Content: null,
    Extension: null,
    TypeCode: null,
    Height: 400,
    Width: 800
  };


  Icon_Change(event: any): void {
    this._Icon_Cropper_ChangedEvent = event;
  }
  Icon_Cropped(event: ImageCroppedEvent) {
    this._Icon_Cropper_Image = event.base64;
    var ImageDetails = this.GetImageDetails(this._Icon_Cropper_Image);
    this._Icon_Cropper_Data.Content = ImageDetails.Content;
    this._Icon_Cropper_Data.Extension = ImageDetails.Extension;
  }
  Icon_B64Cropped(base64: string) {
    this._Icon_Cropper_Image = base64;
    var ImageDetails = this.GetImageDetails(this._Icon_Cropper_Image);
    this._Icon_Cropper_Data.Content = ImageDetails.Content;
    this._Icon_Cropper_Data.Extension = ImageDetails.Extension;

    //  this._InputFileComponent.files[this._InputFileComponent.files.length - 1].preview = base64;
    // this.GetFileFromB64URL(base64, this._Icon_Cropper_Data.Name, this._Icon_Cropper_Data.TypeCode)
    //   .then((file) => {
    //     this._InputFileComponent.files[this._InputFileComponent.files.length - 1].file = file;
    //   });
  }
  Icon_Crop_Failedone(datacrop) {
    if (datacrop != 'none') {
      this.NotifyWarning('Unable to load image. Please make sure valid image selected or refresh page and try again');
    }
  }
  Icon_Crop_Failed() {
    this.NotifyWarning('Unable to load image. Please make sure valid image selected or refresh page and try again');
  }
  Icon_Crop_Loaded() {
    this.OpenModal('_Icon_Cropper_Modal');
  }
  Icon_Crop_Clear() {
    this._Icon_Cropper_Data = {
      Name: null,
      Content: null,
      Extension: null,
      TypeCode: null,
      Height: 400,
      Width: 800
    };
    this._Icon_Cropper_ChangedEvent = '';
    this._Icon_Cropper_Image = '';
    this._Icon_Cropper_BaseImage = '';
    this._Icon_Cropper_Data.Name = null;
    this._Icon_Cropper_Data.Content = null;
    this._Icon_Cropper_Data.Extension = null;
    this._Icon_Cropper_Data.TypeCode = null;
    this.CloseModal('_Icon_Cropper_Modal');
  }

  _Poster_Cropper_ChangedEvent: any = "";
  _Poster_Cropper_Image: any = "";
  _Poster_Cropper_Data: OStorageContent = {
    Name: null,
    Content: null,
    Extension: null,
    TypeCode: null,
    Height: 450,
    Width: 800,
  };
  Poster_Change(event: any): void {
    this._Poster_Cropper_ChangedEvent = event;
  }
  Poster_Cropped(event: ImageCroppedEvent) {
    this._Poster_Cropper_Image = event.base64;
    var ImageDetails = this.GetImageDetails(this._Poster_Cropper_Image);
    this._Poster_Cropper_Data.Content = ImageDetails.Content;
    this._Poster_Cropper_Data.Extension = ImageDetails.Extension;
  }
  // Poster_Crop_Failed() {
  //   this.NotifyWarning(
  //     "Unable to load image. Please make sure valid image selected or refresh page and try again"
  //   );
  // }
  Poster_Crop_Loaded() {
    this.OpenModal("_Poster_Cropper_Modal");
  }
  Poster_Crop_Clear() {
    this._Icon_Cropper_ChangedEvent;
    this._Poster_Cropper_ChangedEvent = "";
    this._Poster_Cropper_Image = "";
    this._Poster_Cropper_Data.Name = null;
    this._Poster_Cropper_Data.Content = null;
    this._Poster_Cropper_Data.Extension = null;
    this._Poster_Cropper_Data.TypeCode = null;
    this.CloseModal("_Poster_Cropper_Modal");
  }

  //#region DateRange
  RangeAltered: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public DateFilterRange: string;

  public DateRangeStartO: any;
  public DateRangeEndO: any;

  public DateRangeStart: any;
  public DateRangeEnd: any;

  public ShowDateRange: boolean;

  public ToogleRange(val: string): void {
    this.DateFilterRange = val;

    var isStartNotExist: boolean =
      this.DateRangeStart == undefined && this.DateRangeStart == null;
    var isEndNotExist: boolean =
      this.DateRangeEnd == undefined && this.DateRangeEnd == null;

    if (isStartNotExist || isEndNotExist) {
      this.DateRangeStart = moment().startOf("day");
      this.DateRangeEnd = moment().endOf("day");

      this.DateRangeStartO = moment().startOf("day");
      this.DateRangeEndO = moment().endOf("day");
    }
    switch (this.DateFilterRange) {
      case "A":
        {
          this.DateRangeStart = moment(this.DateRangeStartO)
            .startOf("month")
            .startOf("day");
          this.DateRangeEnd = moment(this.DateRangeEndO)
            .endOf("month")
            .endOf("day");
        }

        break;
      case "B":
        {
          this.DateRangeStart = moment(this.DateRangeStartO)
            .startOf("week")
            .startOf("day");
          this.DateRangeEnd = moment(this.DateRangeEndO)
            .endOf("week")
            .endOf("day");
        }

        break;
      case "C":
        {
          this.DateRangeStart = moment(this.DateRangeStartO);
          this.DateRangeEnd = moment(this.DateRangeEndO);
        }

        break;

      default:
        {
          this.DateRangeStart = moment(this.DateRangeStartO);
          this.DateRangeEnd = moment(this.DateRangeEndO);
        }
        break;
    }



    this.RangeAltered.next(true);
  }

  public ResetDateRange(): void {
    this.DateFilterRange = "C";
    this.DateRangeStart = moment().startOf("day");
    this.DateRangeEnd = moment().endOf("day");

    this.DateRangeStartO = moment().startOf("day");
    this.DateRangeEndO = moment().endOf("day");
  }

  public SetDateRange(event: any): void {
    var StartTime = moment(event.start).startOf("day");
    var EndTime = moment(event.end).endOf("day");

    switch (this.DateFilterRange) {
      case "A":
        {
          StartTime = StartTime.startOf("month");
          EndTime = EndTime.endOf("month");
        }

        break;
      case "B":
        {
          StartTime = StartTime.startOf("week");
          EndTime = EndTime.endOf("week");
        }

        break;
      case "C":
        {
          StartTime = StartTime;
          EndTime = EndTime;
        }

        break;

      default:
        {
          StartTime = StartTime;
          EndTime = EndTime;
        }
        break;
    }

    this.DateRangeStart = StartTime;
    this.DateRangeEnd = EndTime;

    this.DateRangeStartO = moment(event.start).startOf("day");
    this.DateRangeEndO = moment(event.end).endOf("day");

    this.RangeAltered.next(true);

  }

  //#endregion

  //#region PermanentFilters 

  FilterState: string;

  Active_FilterOptions: any[];
  FilterSnap: any;
  FilterSnapTemprary: any;
  FilterSnapPrestine: any;
  FilterSnapPrev: any = {};
  CleanConfig: any;
  CurrentType: string;
  Active_FilterInit(filterType: string, InitializeConfig: any): void {
    this.CurrentType = filterType;
    this.CleanConfig = cloneDeep(InitializeConfig);
    this.CleanConfig.OtherFilters = [];
    this.CleanConfig.SalesRange = {
      SalesMin: 0,
      SalesMax: 10000000,
      RewardMin: 0,
      RewardMax: 10000000,
      RedeemMin: 0,
      RedeemMax: 10000000,
      ClaimRewardMin: 0,
      ClaimRewardMax: 10000000,
      DealMin: 0,
      DealMax: 10000000,
      DealPurchaseMin: 0,
      DealPurchaseMax: 10000000,
      ActiveLoanMin: 0,
      ActiveLoanMax: 10000,
      ClosedLoanMin: 0,
      ClosedLoanMax: 10000,
      TUCFeesMin: 0,
      TUCFeesMax: 10000000,
      CreditLimitMin: 0,
      CreditLimitMax: 10000000,
      loanAmountMin: 0,
      loanAmountMax: 10000000,
      paidAmountMin: 0,
      paidAmountMax: 10000000, SettlmentRangeMin: 0,
      SettlmentRangeMax: 10000000,

      TotalAmountMin: 0,
      TotalAmountMax: 10000000,
    };
    if (this.CleanConfig.Sort) {
      this.CleanConfig.Sort.SortColumn = null;
    }

    this.Active_FilterOptions = [];

    //#region Create Default Option 

    var temp: any = cloneDeep(InitializeConfig);
    temp.id = -1;
    temp.text = "Default View";
    temp.Sort.SortDefaultName = temp.Sort.SortDefaultName + " desc";
    temp.OtherFilters = [];
    temp.SalesRange = {
      SalesMin: 0,
      SalesMax: 10000000,
      RewardMin: 0,
      RewardMax: 10000000,
      RedeemMin: 0,
      RedeemMax: 10000000,
      ClaimRewardMin: 0,
      ClaimRewardMax: 10000000,
      DealMin: 0,
      DealMax: 10000000,
      DealPurchaseMin: 0,
      DealPurchaseMax: 10000000,
      ActiveLoanMin: 0,
      ActiveLoanMax: 10000,
      ClosedLoanMax: 10000,

      TotalAmountMin: 0,
      TotalAmountMax: 10000000,
    };
    this.Active_FilterOptions.push(temp);
    //#endregion

    //#region Initiate FilterSnap With Default 

    this.FilterSnap = cloneDeep(temp);
    this.FilterSnap.OtherFilters = [];
    this.FilterSnap.SalesRange = {
      SalesMin: 0,
      SalesMax: 10000000,
      RewardMin: 0,
      RewardMax: 10000000,
      RedeemMin: 0,
      RedeemMax: 10000000,
      ClaimRewardMin: 0,
      ClaimRewardMax: 10000000,
      DealMin: 0,
      DealMax: 10000000,
      DealPurchaseMin: 0,
      DealPurchaseMax: 10000000,
      ActiveLoanMin: 0,
      ActiveLoanMax: 10000,
      ClosedLoanMin: 0,
      ClosedLoanMax: 10000,
      TUCFeesMin: 0,
      TUCFeesMax: 10000000,
      CreditLimitMin: 0,
      CreditLimitMax: 10000000,
      loanAmountMin: 0,
      loanAmountMax: 10000000,
      paidAmountMin: 0,
      paidAmountMax: 10000000,
      SettlmentRangeMin: 0,
      SettlmentRangeMax: 10000000,
      TotalAmountMin: 0,
      TotalAmountMax: 10000000,
    };
    this.FilterSnap.Badges = [];
    this.FilterSnapTemprary = cloneDeep(this.FilterSnap);
    this.FilterSnapPrestine = cloneDeep(this.FilterSnap);

    //#endregion

    //#region Get Stored Values 

    //var StorageValue = this.GetStorage(filterType);

    var filterData = {
      Task: "getliststates",
      "ReferenceId": this.UserAccount.AccountId,
      "ReferenceKey": this.UserAccount.AccountKey,
      "SubReferenceKey": filterType,
      "Offset": 0,
      "Limit": 100
    }

    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V3.SystemMange, filterData);
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          var _ResponseData = _Response.Result as any;

          var FilterArray = null;
          if (_ResponseData.Data.length > 0) {
            FilterArray = JSON.parse(_ResponseData.Data[0].Data);
          }

          if (FilterArray != null) {
            this.Active_FilterOptions = this.Active_FilterOptions.concat(
              FilterArray
            );
          }
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );



    //#endregion
    this.ComputeFilterState();
  }

  Save_NewFilter(filterType: string, AccessType?: number): void {

    //#region check max limit 

    if (this.Active_FilterOptions.length >= 10) {
      this.NotifyError("You can store maximum 10 filters.");
      return;
    }

    //#endregion

    //#region checkforduplicate

    var IsNameDuplicate: boolean = this.IsNameDuplicate();
    if (IsNameDuplicate) {
      this.NotifyError("Duplicate Name. Please Enter New Name");
      return;
    }

    var IsStructureDuplicate: boolean = this.IsStructureDuplicate();
    if (IsStructureDuplicate) {
      return;
    }

    //#endregion

    this.FilterSnap.id = this.Active_FilterOptions.length - 1;
    this.Active_FilterOptions.push(cloneDeep(this.FilterSnap));
    // this.SaveStorage(
    //   filterType,
    //   this.Active_FilterOptions.slice(1, this.Active_FilterOptions.length)
    // );

    var filterData = {
      Task: "saveliststate",
      "AccountId": this.UserAccount.AccountId,
      "AccountKey": this.UserAccount.AccountKey,
      "SystemName": filterType,
      "AccessTypeId": ((AccessType != undefined) ? AccessType : 0),
      "Data": JSON.stringify(this.Active_FilterOptions.slice(1, this.Active_FilterOptions.length))
    }

    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V3.SystemMange, filterData);
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._AccountOverview = _Response.Result as OAccountOverview;
          this.NotifySuccess(_Response.Message);
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );

    //#region ReStorePreviousState 
    this.FilterSnap.id = this.FilterSnapPrev.id;
    this.FilterSnap.text = this.FilterSnapPrev.text;
    //#endregion

    this.FilterSnapTemprary = cloneDeep(this.FilterSnap);
    this.FilterSnapPrestine = cloneDeep(this.FilterSnap);

    this.ComputeFilterState();
  }

  Delete_Filter(filterType: string): void {
    var deleteIndex = 0;
    for (let index = 0; index < this.Active_FilterOptions.length; index++) {
      const element = this.Active_FilterOptions[index];
      if (element.text == this.FilterSnap.text) {
        deleteIndex = index;
        break;
      }
    }

    //#region delete existing
    if (deleteIndex == 0) {
      this.NotifyError("default Filter cannot be deleted");
      return;
    } else {
      //#region set current snap to default
      this.FilterSnap = cloneDeep(this.Active_FilterOptions[0]);
      this.FilterSnap.element = undefined;

      this.FilterSnapTemprary = cloneDeep(this.FilterSnap);
      this.FilterSnapPrestine = cloneDeep(this.FilterSnap);
      //#endregion
    }

    this.Active_FilterOptions.splice(deleteIndex, 1);

    // this.SaveStorage(
    //   filterType,
    //   this.Active_FilterOptions.slice(1, this.Active_FilterOptions.length)
    // );


    var filterData = {
      Task: "saveliststate",
      "AccountId": this.UserAccount.AccountId,
      "AccountKey": this.UserAccount.AccountKey,
      "SystemName": filterType,
      "Data": JSON.stringify(this.Active_FilterOptions.slice(1, this.Active_FilterOptions.length))
    }

    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V3.SystemMange, filterData);
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._AccountOverview = _Response.Result as OAccountOverview;
          this.NotifySuccess("View Deleted Successfully");
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );

    //#endregion

    this.ComputeFilterState();
  }

  IsNameDuplicate(): boolean {
    for (let index = 0; index < this.Active_FilterOptions.length; index++) {
      const element = this.Active_FilterOptions[index];
      if (this.FilterSnap.text == element.text) {
        return true;
      }
    }
    return false;
  }

  IsStructureDuplicate(): boolean {
    //#region clone FilterSnap 
    var FilterSnapClone: any = cloneDeep(this.FilterSnap);
    FilterSnapClone.text = null;
    FilterSnapClone.id = null;
    FilterSnapClone.Badges = null;
    FilterSnapClone.disabled = null;
    FilterSnapClone.element = null;
    FilterSnapClone.selected = null;
    //#endregion

    for (let index = 0; index < this.Active_FilterOptions.length; index++) {
      const element = this.Active_FilterOptions[index];

      var elementName: string = element.text;
      //#region clone ArrayElement 

      var ElementClone: any = cloneDeep(element);
      ElementClone.text = null;
      ElementClone.id = null;
      ElementClone.Badges = null;
      ElementClone.disabled = null;
      ElementClone.element = null;
      ElementClone.selected = null;

      //#endregion

      var areTwoEqual: boolean = this.AreTwoFilterSnapEqual(FilterSnapClone, ElementClone, 'check for duplicate');

      if (areTwoEqual) {
        this.NotifyError("Duplicate Filter." + "  " + "  Similar filter is" + " ' " + elementName + " ' ");
        return true;
      }

    }
    return false;
  }

  Active_FilterValueChanged(event: any): void {
    try {
      if (event.data.length > 0) {
        this.FilterSnap = cloneDeep(event.data[0]);
        this.FilterSnap.element = undefined;
        this.FilterSnapTemprary = cloneDeep(this.FilterSnap);
        this.FilterSnapPrestine = cloneDeep(this.FilterSnap);
      }
    } catch (error) {

    }

    this.ComputeFilterState();
  }

  _RefreshUI: boolean = true;
  Update_CurrentFilterSnap(event: any, Type: any, List_Config?: any, SubType?: any) {
    var ResetOffset = false;
    this.FilterSnapTemprary.RefreshData = false;
    this.FilterSnapTemprary.RefreshCount = false;

    if (
      !(
        isNull(List_Config.SearchBaseConditions) ||
        isUndefined(List_Config.SearchBaseConditions)
      )
    ) {
      this.FilterSnapTemprary.SearchBaseConditions = List_Config.SearchBaseConditions;
    }

    if (Type == this.AppConfig.ListToggleOption.Limit) {
      this.FilterSnapTemprary.PageRecordLimit = event;
      ResetOffset = true;
      this.FilterSnapTemprary.RefreshData = true;
      this.FilterSnapTemprary.RefreshCount = true;
    } else if (Type == this.AppConfig.ListToggleOption.Search) {
      if (event != null) {
        this.FilterSnapTemprary.SearchParameter = event.target.value;
      }
      ResetOffset = true;
      this.FilterSnapTemprary.RefreshData = true;
      this.FilterSnapTemprary.RefreshCount = true;
    } else if (Type == this.AppConfig.ListToggleOption.Date) {
      if (
        this.FilterSnapTemprary.StartDate == undefined &&
        this.FilterSnapTemprary.EndDate == undefined
      ) {
        this.FilterSnapTemprary.StartTime = event.start;
        this.FilterSnapTemprary.EndTime = event.end;
        if (event.label) {
          this.FilterSnapTemprary.dateRangeLable = event.label;
        }

      }
      ResetOffset = true;
      this.FilterSnapTemprary.RefreshData = false;
      this.FilterSnapTemprary.RefreshCount = false;
    } else if (Type == this.AppConfig.ListToggleOption.DateExpiry) {
      if (
        this.FilterSnapTemprary.ExpiryStartDate == undefined &&
        this.FilterSnapTemprary.ExpiryEndDate == undefined
      ) {
        this.FilterSnapTemprary.ExpiryStartDate = event.start;
        this.FilterSnapTemprary.ExpiryEndDate = event.end;
      }
      ResetOffset = true;
      this.FilterSnapTemprary.RefreshData = false;
      this.FilterSnapTemprary.RefreshCount = false;
    }
    else if (Type == this.AppConfig.ListToggleOption.Status) {
      this.FilterSnapTemprary.StatusName = event.data[0].text;
      const optTypes = [this.AppConfig.FilterTypeOption.MerchantSales, this.AppConfig.FilterTypeOption.GiftCardRedeems,
      this.AppConfig.FilterTypeOption.GiftCardTransactions, this.AppConfig.FilterTypeOption.IssuedGiftCard];
      if (optTypes.includes(this.CurrentType)) {
        this.FilterSnapTemprary.Status = event.data[0].id;
      } else {
        this.FilterSnapTemprary.Status = event.data[0].code;
      }

      this.FilterSnapTemprary.RefreshData = false;
      this.FilterSnapTemprary.RefreshCount = false;
      ResetOffset = false;
    } else if (Type == this.AppConfig.ListToggleOption.Sort) {
      if (isUndefined(this.FilterSnapTemprary.Sort)) {
        this.FilterSnapTemprary.Sort = {};
      }
      this.FilterSnapTemprary.Sort.SortColumn = event.SystemName;
      this.FilterSnapTemprary.RefreshData = true;
      this.FilterSnapTemprary.RefreshCount = true;
      ResetOffset = true;
    } else if (Type == this.AppConfig.ListToggleOption.SortOrder) {
      if (isUndefined(this.FilterSnapTemprary.Sort)) {
        this.FilterSnapTemprary.Sort = {};
      }
      if (this.FilterSnapTemprary.Sort.SortOrder == "asc") {
        this.FilterSnapTemprary.Sort.SortOrder = "desc";
      } else {
        this.FilterSnapTemprary.Sort.SortOrder = "asc";
      }
      this.FilterSnapTemprary.RefreshData = true;
      this.FilterSnapTemprary.RefreshCount = true;
      ResetOffset = true;
    } else if (Type == this.AppConfig.ListToggleOption.SortApply) {
      this.FilterSnapTemprary.RefreshData = true;
      this.FilterSnapTemprary.RefreshCount = true;
      ResetOffset = true;
    } else if (Type == this.AppConfig.ListToggleOption.SortReset) {
      if (isUndefined(this.FilterSnapTemprary.Sort)) {
        this.FilterSnapTemprary.Sort = {};
      }

      this.FilterSnapTemprary.Sort.SortOrder = "desc";
      this.FilterSnapTemprary.Sort.SortColumn = null;
      this.FilterSnapTemprary.RefreshData = true;
      this.FilterSnapTemprary.RefreshCount = true;
      ResetOffset = true;
    } else if (Type == this.AppConfig.ListToggleOption.Page) {
      this.FilterSnapTemprary.ActivePage = event;
      this.FilterSnapTemprary.RefreshData = true;
      this.FilterSnapTemprary.RefreshCount = false;
    } else if (Type == this.AppConfig.ListToggleOption.MultiSelect) {
      this.FilterSnapTemprary.ActivePage = event;
      this.FilterSnapTemprary.RefreshData = true;
      this.FilterSnapTemprary.RefreshCount = true;
    } else if (Type == this.AppConfig.ListToggleOption.Refresh) {
      this.FilterSnapTemprary.RefreshData = true;
      this.FilterSnapTemprary.RefreshCount = true;
    } else if (Type == this.AppConfig.ListToggleOption.Refresh) {
      this.FilterSnapTemprary.RefreshData = true;
      this.FilterSnapTemprary.RefreshCount = true;
    } else if (Type == this.AppConfig.ListToggleOption.ResetOffset) {
      this.FilterSnapTemprary.RefreshData = true;
      this.FilterSnapTemprary.RefreshCount = true;
      ResetOffset = true;
    } else if (Type == this.AppConfig.ListToggleOption.ApplyFilter) {
      ResetOffset = true;
      this.FilterSnapTemprary.RefreshData = true;
      this.FilterSnapTemprary.RefreshCount = true;
    } else if (Type == this.AppConfig.ListToggleOption.CancelFilter) {
    } else if (Type == this.AppConfig.ListToggleOption.SalesRange) {
      this.FilterSnapTemprary.SalesRange = event.data;

    } else if (Type == this.AppConfig.ListToggleOption.Other) {
      var data: any = cloneDeep(event.data[0]);
      data.element = undefined;
      data.OtherType = SubType;

      var CurrentIndex = this.FilterSnapTemprary.OtherFilters.findIndex((filter) => (filter.data[0].OtherType == SubType));
      if (CurrentIndex == -1) {
        this.FilterSnapTemprary.OtherFilters.push({ data: [data], value: event.value });
      } else {
        this.FilterSnapTemprary.OtherFilters[CurrentIndex] = { data: [data], value: event.value };
      }

      CurrentIndex = data;

    }
    if (ResetOffset == true) {
      this.FilterSnapTemprary.ActivePage = 1;
      this.FilterSnapTemprary.TotalRecords = 0;
      this.FilterSnapTemprary.ShowingStart = 0;
      this.FilterSnapTemprary.ShowingEnd = 0;
    }

    this.ComputeFilterState();

  }
  public FiltersArray = []
  public FiltersArrayFinal = []

  MakeFilterSnapPermanent(): void {
    this.FilterSnap = cloneDeep(this.FilterSnapTemprary);
    this.ComputeFilterState();
    this.FiltersArray.push({
      Name: "Status",
      Value: this.FilterSnap.StatusName,
    },
      {
        Name: "Date Range",
        Value: this.GetDateS(this.FilterSnap.StartTime) + "-" + this.GetDateS(this.FilterSnap.EndTime),
      },
      {
        Name: "Invoice Range",
        Value: this.FilterSnap.SalesRange.SalesMin + "-" + this.FilterSnap.SalesRange.SalesMax,
      },
      {
        Name: "Reward Range",
        Value: this.FilterSnap.SalesRange.RewardMin + "-" + this.FilterSnap.SalesRange.RewardMax,
      },
      {
        Name: "Redeem Range",
        Value: this.FilterSnap.SalesRange.RedeemMin + "-" + this.FilterSnap.SalesRange.RedeemMax,
      },
      {
        Name: "Active Loan Range",
        Value: this.FilterSnap.SalesRange.ActiveLoanMin + "-" + this.FilterSnap.SalesRange.ActiveLoanMax,
      },
      {
        Name: "Closed Loan Range",
        Value: this.FilterSnap.SalesRange.ClosedLoanMin + "-" + this.FilterSnap.SalesRange.ClosedLoanMax
      },
      {
        Name: "Fees Loan Range",
        Value: this.FilterSnap.SalesRange.TUCFeesLoanMin + "-" + this.FilterSnap.SalesRange.TUCFeesLoanMax
      },
      {
        Name: 'Credit Limit Range',
        Value: this.FilterSnap.SalesRange.CreditLimitMin + "-" + this.FilterSnap.SalesRange.CreditLimitMax
      },
      {
        Name: 'Loan Amount Range',
        Value: this.FilterSnap.SalesRange.loanAmountMin + "-" + this.FilterSnap.SalesRange.loanAmountMax
      },
      {
        Name: 'Paid Amount Range',
        Value: this.FilterSnap.SalesRange.paidAmountMin + "-" + this.FilterSnap.SalesRange.paidAmountMax
      },
      {
        Name: 'Settlement  Amount Range',
        Value: this.FilterSnap.SalesRange.SettlmentRangeMin + "-" + this.FilterSnap.SalesRange.SettlmentRangeMax
      }
    )

    for (let index = 0; index < this.FiltersArray.length; index++) {
      const element = this.FiltersArray[index];
      if (element.Value != undefined && element.Value != null) {
        this.FiltersArrayFinal.push(element)
      }

    }

    this.FiltersArrayFinal.splice(0, this.FiltersArrayFinal.length - 4);
  }


  ResetFilterSnap(): void {
    var index: number = (this.FilterSnap.id < 1) ? 0 : (this.FilterSnap.id - 1);

    this.FilterSnapTemprary = cloneDeep(this.Active_FilterOptions[index]);
    this.FilterSnap = cloneDeep(this.Active_FilterOptions[index]);

  }

  ComputeFilterState(): void {

    //#region clone FilterSnap 
    var FilterSnapClone: any = cloneDeep(this.FilterSnap);
    FilterSnapClone.text = null;
    FilterSnapClone.id = null;
    FilterSnapClone.Badges = null;
    FilterSnapClone.disabled = null;
    FilterSnapClone.element = null;
    FilterSnapClone.selected = null;
    //#endregion

    //#region clone CleanConfig 
    var CleanConfigClone: any = cloneDeep(this.CleanConfig);
    CleanConfigClone.text = null;
    CleanConfigClone.id = null;
    CleanConfigClone.Badges = null;
    CleanConfigClone.disabled = null;
    CleanConfigClone.element = null;
    CleanConfigClone.selected = null;
    CleanConfigClone.selected = null;
    //#endregion

    if (this.AreTwoFilterSnapEqual(FilterSnapClone, CleanConfigClone, 'check for Default')) {
      this.FilterState = this.AppConfig.FilterStates.Default;
    } else if (this.AreTwoFilterSnapEqual(this.FilterSnap, this.FilterSnapPrestine, 'check for nochange')) {
      this.FilterState = this.AppConfig.FilterStates.NoChange;
    } else {
      this.FilterState = this.AppConfig.FilterStates.Modified;
    }

    // alert(this.FilterState);
  }

  //#endregion

  _FileSelect_Icon_Data: OStorageContent = {
    Name: null,
    Content: null,
    Extension: null,
    TypeCode: null,
    Height: 128,
    Width: 128,
  };

  public _FileSelect_Icon: string = this.AppConfig.Storage.ImageStorage;
  _FileSelect_Icon_Processing = false;

  public _FileSelect_Icon_Reset(): void {
    this._FileSelect_Icon_Data.Name = null;
    this._FileSelect_Icon_Data.Content = null;
    this._FileSelect_Icon_Data.Extension = null;
    // this._FileSelect_Icon_Data.Height = 0;
    // this._FileSelect_Icon_Data.Width = 0;
    if (
      this._Ng2FileInputService.getCurrentFiles(this._FileSelect_Icon).length > 0
    ) {
      this._Ng2FileInputService.reset(this._FileSelect_Icon);
    }

  }

  public Get_UserAccountDetails(ShowHeader: boolean) {
    this.AppConfig.ShowHeader = ShowHeader;

    this.IsFormProcessing = true;
    var pData = {
      Task: this.AppConfig.Api.Core.GetUserAccount,
      Reference: this.GetSearchConditionStrict(
        "",
        "ReferenceKey",
        this.AppConfig.DataType.Text,
        this.AppConfig.ActiveReferenceKey,
        "="
      ),
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V2.System, pData);
    _OResponse.subscribe(
      (_Response) => {
        if (_Response.Status == this.StatusSuccess) {
          this._UserAccount = _Response.Result as OUserDetails;

          this._UserAccount.DateOfBirth = this.GetDateS(
            this._UserAccount.DateOfBirth
          );
          this._UserAccount.LastLoginDateS = this.GetDateTimeS(
            this._UserAccount.LastLoginDate
          );
          this._UserAccount.CreateDateS = this.GetDateTimeS(
            this._UserAccount.CreateDate
          );
          this._UserAccount.ModifyDateS = this.GetDateTimeS(
            this._UserAccount.ModifyDate
          );
          this._UserAccount.StatusI = this.GetStatusIcon(
            this._UserAccount.StatusCode
          );
          this._UserAccount.StatusB = this.GetStatusBadge(
            this._UserAccount.StatusCode
          );
          this._UserAccount.StatusC = this.GetStatusColor(
            this._UserAccount.StatusCode
          );

          if (this._UserAccount.CreatedByDisplayName != undefined) {
            if (this._UserAccount.CreatedByDisplayName.length > 8) {
              this._UserAccount.CreatedByDisplayNameShort =
                this._UserAccount.CreatedByDisplayName.substring(0, 8) + "..";
            } else {
              this._UserAccount.CreatedByDisplayNameShort = this._UserAccount.CreatedByDisplayName;
            }
          }
          if (this._UserAccount.ModifyByDisplayName != undefined) {
            if (this._UserAccount.ModifyByDisplayName.length > 8) {
              this._UserAccount.ModifyByDisplayNameShort =
                this._UserAccount.ModifyByDisplayName.substring(0, 8) + "..";
            } else {
              this._UserAccount.ModifyByDisplayNameShort = this._UserAccount.ModifyByDisplayName;
            }
          }
          this.IsFormProcessing = false;
        } else {
          this.IsFormProcessing = false;
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.HandleException(_Error);
      }
    );
  }

  NotifySwalSuccess(Title, Message) {
    swal({
      type: "success",
      title: Title,
      text: Message,
      position: this.AppConfig.Alert_Position,
      animation: this.AppConfig.Alert_AllowAnimation,
      customClass: this.AppConfig.Alert_Animation,
      allowOutsideClick: this.AppConfig.Alert_AllowOutsideClick,
      allowEscapeKey: this.AppConfig.Alert_AllowEscapeKey,
    }).then((result) => { });
  }

  FlashSwalSuccess(Title, Message) {
    swal({
      type: "success",
      title: Title,
      text: Message,
      position: this.AppConfig.Alert_Position,
      animation: true,
      customClass: this.AppConfig.Alert_Animation,
      allowOutsideClick: this.AppConfig.Alert_AllowOutsideClick,
      allowEscapeKey: this.AppConfig.Alert_AllowEscapeKey,
      timer: 1600
    }).then((result) => { });
  }

  NotifySwalError(Title, Message) {
    swal({
      type: "error",
      title: Title,
      text: Message,
      position: this.AppConfig.Alert_Position,
      animation: this.AppConfig.Alert_AllowAnimation,
      customClass: this.AppConfig.Alert_Animation,
      allowOutsideClick: this.AppConfig.Alert_AllowOutsideClick,
      allowEscapeKey: this.AppConfig.Alert_AllowEscapeKey,
    }).then((result) => { });
  }

  NotifySuccess(Message) {
    notie.alert({
      type: "1",
      text: Message,
    });
  }
  NotifyWarning(Message) {
    notie.alert({
      type: "2",
      text: Message,
    });
  }
  NotifyError(Message) {
    notie.alert({
      type: "3",
      text: Message,
    });
  }
  NotifyInfo(Message) {
    notie.alert({
      type: "4",
      text: Message,
    });
  }
  HandleException(Exception: HttpErrorResponse) {
    this.IsFormProcessing = false;
    if (
      Exception.status != undefined &&
      Exception.error != null &&
      Exception.status == 401
    ) {
      var ResponseData = JSON.parse(atob(Exception.error.zx)) as OResponse;
      this.NotifyError(ResponseData.Message);
      setTimeout(() => {
        window.location.href = "/account/login";
        // this._Router.navigate([this.AppConfig.Pages.System.NotFound])
      }, 1500);
    } else {
      if (Exception.error instanceof Error) {
        this.NotifyError(
          "Sorry, error occured while connecting server:" +
          Exception.error.message
        );
      } else {
        this.NotifyError(
          "Sorry, error occured while connecting server : " +
          Exception.message +
          " Response Code -" +
          Exception.status
        );
      }
    }
  }
  GetRandomNumber() {
    this.RandomNumber = Math.floor(1000 + Math.random() * 9000);
    return this.RandomNumber;
  }
  GeneratePassoword() {
    var plength = 14;
    var keylistalpha = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var keylistint = "123456789";
    var keylistspec = "!@#_";
    var temp = "";
    var len = plength / 2;
    var len = len - 1;
    var lenspec = plength - len - len;

    for (let index = 0; index < len; index++)
      temp += keylistalpha.charAt(
        Math.floor(Math.random() * keylistalpha.length)
      );

    for (let index = 0; index < lenspec; index++)
      temp += keylistspec.charAt(
        Math.floor(Math.random() * keylistspec.length)
      );

    for (let index = 0; index < len; index++)
      temp += keylistint.charAt(Math.floor(Math.random() * keylistint.length));

    temp = temp
      .split("")
      .sort(function () {
        return 0.5 - Math.random();
      })
      .join("");

    this.RandomPassword = temp;
    return temp;
  }
  RemoveItemFromArray(ItemToRemove, DataSet: any[]) {
    var Index = DataSet.indexOf(ItemToRemove, 0);
    if (Index > -1) {
      DataSet.splice(Index, 1);
    }
    return DataSet;
  }

  RemoveItemFromArrayByField(ItemNameToRemove, DataSet: any[]) {

    for (let index = 0; index < DataSet.length; index++) {
      const element = DataSet[index];
      if (element.includes(ItemNameToRemove)) {
        DataSet.splice(index, 1);
      }
    }

    return DataSet;
  }

  OpenModal(ModalId) {
    setTimeout(() => {
      $("#" + ModalId).modal("show");
    }, 100);
  }
  CloseModal(ModalId) {
    $("#" + ModalId).modal("hide");
  }
  CloseAllModal() {
    $('.modal').modal('hide');
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (state.url != undefined) {
      this.SaveStorage("temploc", {
        StorageRoute: state.url
      });
    }
    this.Poster_Crop_Clear();
    this.Icon_Crop_Clear();
    this.AppConfig.ShowHeader = true;
    this.FullContainer = false;
    if (this.GetStorage(this.AppConfig.Storage.Account) != null) {
      return true;
    } else {
      this._Router.navigate([this.AppConfig.Pages.System.Login]);
      return false;
    }
    return true;
  }
  public StorageRoute?: any;
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    // this.AppConfig.ActiveOwnerAccountTypeCode = null;
    // this.AppConfig.ActiveOwnerAccountTypeId = null;
    // this.StorageRoute = window.location.href;

    this.SaveStorage("temploc", {
      StorageRoute: state.url
    });
    this.AppConfig.ShowHeader = true;
    this.FullContainer = false;
    var AccountTypeCode = childRoute.data.accounttypecode;
    if (AccountTypeCode != undefined) {
      this.AppConfig.ActiveReferenceAccountTypeCode = AccountTypeCode;
    } else {
      this.AppConfig.ActiveReferenceAccountTypeCode = null;
    }
    var ActiveReferenceListType = childRoute.data.listtype;
    if (ActiveReferenceListType != undefined) {
      this.AppConfig.ActiveReferenceListType = ActiveReferenceListType;
    } else {
      this.AppConfig.ActiveReferenceListType = null;
    }

    if (this.UserAccount != null) {
      var PageName = childRoute.data.PageName;


      if (PageName != undefined) {
        this._TranslateService.get(PageName).subscribe((_PageName: string) => {
          this.AppConfig.ActivePageName = _PageName;
        });
      }
      return true;
    } else {
      this._Router.navigate([this.AppConfig.Pages.System.Login]);
      return false;
    }
    return true;

    return true;
  }
  GetDateTime(Date) {
    return SystemHelper.GetDateTime(Date, this.AppConfig.TimeZone);
  }
  GetDateTimeGMT(Date) {
    return SystemHelper.GetDateTimeGMT(Date, this.AppConfig.TimeZone);
  }


  GetTimeS(Date) {
    return SystemHelper.GetTimeS(
      Date,
      this.AppConfig.TimeZone,
      this.AppConfig.TimeFormat
    );
  }

  GetFilterDate(Date) {
    if (Date != undefined && Date != null) {
      return moment(Date).format("DD-MM-YYYY")
    } else {
      return Date
    }
  }

  GetDateS(Date) {
    return SystemHelper.GetDateS(
      Date,
      this.AppConfig.TimeZone,
      this.AppConfig.DateFormat
    );
  }

  HCXConvertDate(date) {
    return moment(date).format('YYYY-MM-DD HH:mm');
  }
  HCXGetDateComponent(obj: object) {
    var keys = Object.keys(obj);
    keys.forEach(key => {
      const objectvaluetype = obj[key];
      if (typeof objectvaluetype == 'string') {
        const dateFormat = 'DD-MM-YYYY';
        const toDateFormat = moment(new Date(obj[key])).format(dateFormat);
        var isValid = moment(toDateFormat, dateFormat, true).isValid();
        if (isValid) {
          obj[key + 'Part'] =
          {
            Date: this.GetDateS(objectvaluetype),
            Time: this.GetTimeS(objectvaluetype),
            DateTime: this.GetDateTimeS(objectvaluetype),
            Object: this.GetDateTime(objectvaluetype),
          };
        }
      }
    });
    return obj;
  }
  HCXGetDateComponentList(list: object[]) {
    list.forEach(item => {
      var keys = Object.keys(item);
      keys.forEach(key => {
        const objectvaluetype = item[key];
        if (typeof objectvaluetype == 'string') {
          const dateFormat = 'DD-MM-YYYY';
          const toDateFormat = moment(new Date(item[key])).format(dateFormat);
          var isValid = moment(toDateFormat, dateFormat, true).isValid();
          if (isValid) {
            item[key + 'Part'] =
            {
              Date: this.GetDateS(objectvaluetype),
              Time: this.GetTimeS(objectvaluetype),
              DateTime: this.GetDateTimeS(objectvaluetype),
              Object: this.GetDateTime(objectvaluetype),
            };
          }
        }
      });
    });

    return list;
  }
  FormatDateForRMTarget(Date, format) {
    return SystemHelper.GetDateTimeS(
      moment(Date, 'YYYY-MM-DD'),
      this.AppConfig.TimeZone,
      format
    );
  }

  DateInUTC(dt: any): any {

    try {
      var m = dt;
      var timezoneoffset = -(moment().zone());
      var m = moment(dt).utc().add(timezoneoffset, 'minutes');
      return m;
    } catch (error) {
      return dt;
    }

  }

  GetDateSByFormat(Date, Format) {
    return SystemHelper.GetDateS(
      Date,
      this.AppConfig.TimeZone,
      Format
    );
  }

  GetDayS(Date) {
    return SystemHelper.GetDateS(
      Date,
      this.AppConfig.TimeZone,
      this.AppConfig.DateDayFormat
    );
  }
  GetDateMonthS(Date) {
    return SystemHelper.GetDateS(
      Date,
      this.AppConfig.TimeZone,
      this.AppConfig.DateMonthFormat
    );
  }
  GetDateYearS(Date) {
    return SystemHelper.GetDateS(
      Date,
      this.AppConfig.TimeZone,
      this.AppConfig.DateYearFormat
    );
  }

  TriggerClick(Item) {
    return SystemHelper.TriggerClick(Item);
  }
  CheckDateIsAfter(Date, CompareTo) {
    return SystemHelper.CheckDateIsAfter(Date, CompareTo);
  }
  CheckDateIsBefore(Date, CompareTo) {
    return SystemHelper.CheckDateIsBefore(Date, CompareTo);
  }
  GetTimeDifference(Date, CompareTo) {
    return SystemHelper.GetTimeDifference(Date, CompareTo);
  }
  GetTimeDifferenceS(Date, CompareTo) {
    return SystemHelper.GetTimeDifferenceS(Date, CompareTo);
  }
  GetDateTimeS(Date) {
    return SystemHelper.GetDateTimeS(
      Date,
      this.AppConfig.TimeZone,
      this.AppConfig.DateTimeFormat
    );
  }

  GetDateTimeSPromote(Date) {
    return SystemHelper.GetDateTimeS(
      Date,
      this.AppConfig.TimeZone,
      this.AppConfig.DateTimeFormatPromote
    );
  }

  GetDateTimeSCategory(Date) {
    return SystemHelper.GetDateTimeS(
      Date,
      this.AppConfig.TimeZone,
      this.AppConfig.DateTimeFormatPromote
    );
  }

  GetDateTimeSForChart(Date) {
    return SystemHelper.GetDateTimeS(
      Date,
      this.AppConfig.TimeZone,
      this.AppConfig.DateTimeFormatForChart
    );
  }
  GetDateTimeSChart(Date) {
    return SystemHelper.GetDateTimeS(
      Date,
      this.AppConfig.TimeZone,
      this.AppConfig.DateTimeFormatForChartX
    );
  }
  GetTimeInterval(Date, CompareTo) {
    return SystemHelper.GetTimeInterval(
      Date,
      CompareTo,
      this.AppConfig.TimeZone
    );
  }
  newGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
      c
    ) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  dec2hex(dec) {
    return ("0" + dec.toString(16)).substr(-2);
  }

  GenerateGuid() {
    var arr = new Uint8Array(40 / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, this.dec2hex).join("");
  }

  GenerateId(): number {
    return Math.floor(Math.random() * 90000) + 10000;
  }

  DivideTwoNumbers(num: number, din: number): number {
    var isDinUndefined = isUndefined(din);
    var isDinNull = isNull(din);

    if (!isDinUndefined && !isDinNull && din == 0) {
      return 0;
    } else {
      return num / din;
    }
  }

  getAbsolute(num: number): any {
    return Math.abs(num);
  }


  GetStatusBadge(StatusCode) {
    var ColorCyan = "badge cyan";
    var ColorSuccess = "badge badge-success";
    var ColorWarning = "badge badge-warning";
    var ColorDanger = "badge badge-danger";
    var ColorPrimary = "badge badge-primary";
    var ColorDark = "badge badge-dark";
    var Colorteal = "badge badge-teal"
    var StatusIcon = "";
    if (isNaN(StatusCode) == false) {
      if (
        StatusCode == 293 ||
        StatusCode == 294 ||
        StatusCode == 1 ||
        StatusCode == 450 ||
        StatusCode == 452 ||
        StatusCode == 4 ||
        StatusCode == 299 ||
        StatusCode == 298 ||
        StatusCode == 29 ||
        StatusCode == 27 ||
        StatusCode == 461 ||
        StatusCode == 38 ||
        // order status - ready to ship
        StatusCode == 490 ||
        // product status - inactive
        StatusCode == 547 ||
        StatusCode == 557 ||
        StatusCode == 631 ||
        StatusCode == 717 ||
        StatusCode == 679

      ) {
        StatusIcon = ColorWarning;
      }
      if (
        StatusCode == 2 ||
        StatusCode == 28 ||
        StatusCode == 451 ||
        StatusCode == 295 ||
        StatusCode == 297 ||
        StatusCode == 463 ||
        StatusCode == 39 ||
        //order status - delivered
        StatusCode == 512 ||
        // product status - Success
        StatusCode == 548 ||
        StatusCode == 559 ||

        StatusCode == 680 ||
        StatusCode == 722 || StatusCode == 683

      ) {
        StatusIcon = ColorSuccess;
      }
      if (
        StatusCode == 3 ||
        StatusCode == 32 ||
        StatusCode == 30 ||
        StatusCode == 453 ||
        StatusCode == 454 ||
        StatusCode == 455 ||
        StatusCode == 31 ||
        StatusCode == 296 ||
        StatusCode == 300 ||
        StatusCode == 462 ||
        StatusCode == 464 ||
        StatusCode == 301 ||
        StatusCode == 40 ||
        StatusCode == 681 ||
        StatusCode == 721 ||
        StatusCode == 720 ||
        //orderstatus - cancelled
        StatusCode == 494 || StatusCode == 495 || StatusCode == 511 ||
        // product status - OutOfStock
        StatusCode == 549 || StatusCode == 493 || StatusCode == 560 || StatusCode == 682
      ) {
        StatusIcon = ColorDanger;
      }
      if (
        //orderstatus - active and out for delivery
        StatusCode == 486 || StatusCode == 487 || StatusCode == 488 || StatusCode == 489 || StatusCode == 491 || StatusCode == 492
        || StatusCode == 558 ||
        StatusCode == 718 || StatusCode == 678
      ) {
        StatusIcon = ColorPrimary;
      }
      if (
        //orderstatus - delivery failed
        StatusCode == 550 ||
        StatusCode == 712 ||

        StatusCode == 695
      ) {
        StatusIcon = ColorDark;
      }
      return StatusIcon;
    } else {

      if (
        StatusCode == "campaign.paused" ||
        StatusCode == "campaign.creating" ||
        StatusCode == "campaign.underreview" ||
        StatusCode == "campaign.lowbalance" ||
        StatusCode == "default.inactive" ||
        StatusCode == "default.suspended" ||
        StatusCode == "transaction.pending" ||
        StatusCode == "invoice.pending" ||
        StatusCode == "transaction.processing" ||
        StatusCode == "transaction.initialized" ||
        StatusCode == "paymentstatus.processing" ||
        StatusCode == "published" ||
        // order status
        StatusCode == "orderstatus.ready" || StatusCode == "orderstatus.readytopickup" || StatusCode == "orderstatus.outfordelivery" ||
        //product status
        StatusCode == "product.inactive" ||
        //Deal status
        StatusCode == "deal.paused" ||

        StatusCode == "dealcode.used" ||
        StatusCode == "paymentstatus.initialized" ||
        StatusCode == "paymentstatus.processing" ||
        StatusCode == "cashoutstatus.processing"


      ) {
        StatusIcon = ColorWarning;
      }
      if (
        StatusCode == "campaign.approved" ||
        StatusCode == "campaign.published" ||
        StatusCode == "default.active" ||
        StatusCode == "default.padi" ||
        StatusCode == "transaction.success" ||
        StatusCode == "orderstatus.delivered" ||
        StatusCode == "product.active" ||
        //Deal status
        StatusCode == "deal.published" ||
        StatusCode == "cashoutstatus.success" ||

        StatusCode == "dealcode.unused" ||
        StatusCode == "completed" ||
        StatusCode == "paymentstatus.success" ||
        StatusCode == "sent"

      ) {
        StatusIcon = ColorSuccess;
      }
      if (
        StatusCode == "campaign.expired" ||
        StatusCode == "campaign.archived" ||
        StatusCode == "campaign.rejected" ||
        StatusCode == "default.disabled" ||
        StatusCode == "default.blocked" ||
        StatusCode == "transaction.error" ||
        StatusCode == "transaction.failed" ||
        StatusCode == "transaction.cancelled" ||
        StatusCode == "invoice.cancelled" ||
        StatusCode == "product.outofstock" ||
        StatusCode == "cancelled" ||
        StatusCode == "rejected" ||
        StatusCode == "expired" ||
        StatusCode == "orderstatus.deliveryfailed" || StatusCode == "orderstatus.cancelledbyuser" || StatusCode == "orderstatus.cancelledbyseller" || StatusCode == "orderstatus.cancelledbysystem"
        ||
        StatusCode == "cashoutstatus.failed" ||
        //Deal status
        StatusCode == "deal.paused"
        ||
        //Deal status
        StatusCode == "deal.expired" ||
        StatusCode == "dealcode.blocked" ||
        StatusCode == "paymentstatus.failed" ||
        StatusCode == "cashoutstatus.rejected" ||
        StatusCode == "dealcode.expired" || StatusCode == "deal.rejected"


      ) {
        StatusIcon = ColorDanger;
      }

      if (

        StatusCode == "deal.approvalpending"
      ) {
        StatusIcon = ColorWarning;
      }


      if (StatusCode == "orderstatus.new" || StatusCode == "orderstatus.pendingconfirmation" || StatusCode == "orderstatus.confirmed" || StatusCode == "orderstatus.preparing"
        ||
        //Deal status
        StatusCode == "deal.draft" ||
        StatusCode == "running" ||
        StatusCode == "cashoutstatus.initialized"

      ) {
        StatusIcon = ColorPrimary;
      }
      if (StatusCode == "product.notavailable" ||
        StatusCode == "draft" ||
        StatusCode == "deal.approved") {
        StatusIcon = ColorDark;
      }

      return StatusIcon;
    }
  }
  GetStatusColor(StatusCode) {
    var ColorSuccess = "#20ab68";
    var ColorWarning = "#fda61c";
    var ColorDanger = "#e9223f";
    var ColorPrimary = "#3735ff";
    var ColorDark = "#1e2435";
    var Colorteal = "#00cccc"

    var StatusIcon = "";
    if (isNaN(StatusCode) == false) {
      if (
        StatusCode == 1 ||
        StatusCode == 4 ||
        StatusCode == 450 ||
        StatusCode == 452 ||
        StatusCode == 29 ||
        StatusCode == 461 ||
        StatusCode == 27 ||
        // order status - ready to ship
        StatusCode == 490 ||
        StatusCode == 547 ||
        StatusCode == 38

      ) {
        StatusIcon = ColorWarning;
      }
      if (
        StatusCode == 2 ||
        StatusCode == 451 || StatusCode == 463 ||
        StatusCode == 28 ||
        //order status - delivered
        StatusCode == 512 ||
        StatusCode == 548 ||
        StatusCode == 39


      ) {
        StatusIcon = ColorSuccess;
      }
      if (
        StatusCode == 3 ||
        StatusCode == 32 ||
        StatusCode == 453 ||
        StatusCode == 454 ||
        StatusCode == 455 ||
        StatusCode == 462 ||
        StatusCode == 464 ||
        StatusCode == 30 ||
        StatusCode == 31 ||
        //orderstatus - cancelled
        StatusCode == 494 || StatusCode == 495 || StatusCode == 511 ||
        StatusCode == 549 ||
        StatusCode == 40


      ) {
        StatusIcon = ColorDanger;
      }
      if (
        //orderstatus - active and out for delivery
        StatusCode == 486 || StatusCode == 487 || StatusCode == 488 || StatusCode == 489 || StatusCode == 491 || StatusCode == 492
      ) {
        StatusIcon = ColorPrimary;
      }
      if (
        //orderstatus - delivery failed
        StatusCode == 493 ||
        StatusCode == 550

      ) {
        StatusIcon = ColorDark;
      }
      return StatusIcon;
    } else {
      if (
        StatusCode == "default.inactive" ||
        StatusCode == "default.suspended" ||
        StatusCode == "transaction.pending" ||
        StatusCode == "transaction.processing" ||
        StatusCode == "product.inactive" ||
        StatusCode == "invoice.pending" ||
        StatusCode == 'deal.paused' ||

        // order status
        StatusCode == "orderstatus.ready" || StatusCode == "orderstatus.readytopickup" || StatusCode == "orderstatus.outfordelivery"
      ) {
        StatusIcon = ColorWarning;
      }
      if (
        StatusCode == "default.active" ||
        StatusCode == "transaction.success" ||
        StatusCode == "product.active" ||
        StatusCode == "orderstatus.delivered" ||
        StatusCode == "invoice.paid" ||
        StatusCode == 'deal.published' ||
        StatusCode == 'deal.approved' ||
        StatusCode == "sent"
      ) {
        StatusIcon = ColorSuccess;
      }
      if (
        StatusCode == "default.disabled" ||
        StatusCode == "default.blocked" ||
        StatusCode == "transaction.error" ||
        StatusCode == "transaction.failed" ||
        StatusCode == "transaction.cancelled" ||
        StatusCode == "product.outofstock" ||
        StatusCode == "invoice.cancelled" ||
        StatusCode == "invoice.expired" ||
        StatusCode == 'deal.approvalpending' ||
        StatusCode == "orderstatus.cancelledbyuser" || StatusCode == "orderstatus.cancelledbyseller" || StatusCode == "orderstatus.cancelledbysystem"
      ) {
        StatusIcon = ColorDanger;
      } if (StatusCode == "orderstatus.new" || StatusCode == "orderstatus.pendingconfirmation" || StatusCode == "orderstatus.confirmed" || StatusCode == "orderstatus.preparing"
      ) {
        StatusIcon = ColorPrimary;
      }
      if (
        StatusCode == "orderstatus.deliveryfailed" ||
        StatusCode == "product.notavailable" ||
        StatusCode == "invoice.draft" ||
        StatusCode == "deal.draft"



      ) {
        StatusIcon = ColorDark;
      }
      if (
        StatusCode == "deal.approved"



      ) {
        StatusIcon = Colorteal;
      }
      return StatusIcon;
    }
  }
  GetStatusIcon(StatusCode) {
    var ColorPrimary = "text-primary";
    var ColorInfo = "text-info";
    var ColorSuccess = "text-success";
    var ColorWarn = "text-warn";
    var ColorWarning = "text-warning";
    var ColorDanger = "text-danger";
    var ColorDark = "text text-dark";
    var StatusIcon = "fa fa-circle text-default f-s-11 p-r-5 ";
    if (isNaN(StatusCode) == false) {
      if (
        StatusCode == 1 ||
        StatusCode == 4 ||
        StatusCode == 29 ||
        StatusCode == 450 ||
        StatusCode == 452 ||
        StatusCode == 461 ||
        StatusCode == 27 ||
        StatusCode == 38 ||

        // order status - ready to ship
        StatusCode == 490 ||
        StatusCode == 547

      ) {
        StatusIcon = "fa fa-circle text-warning f-s-11 p-r-5 ";
      }
      if (
        StatusCode == 2 ||
        StatusCode == 463 ||
        StatusCode == 451 ||
        StatusCode == 28 ||
        StatusCode == 39 ||

        //order status - delivered
        StatusCode == 512 ||
        StatusCode == 548

      ) {
        StatusIcon = "fa fa-circle text-success f-s-11 p-r-5";
      }
      if (
        StatusCode == 3 ||
        StatusCode == 32 ||
        StatusCode == 462 ||
        StatusCode == 464 ||
        StatusCode == 453 ||
        StatusCode == 454 ||
        StatusCode == 455 ||
        StatusCode == 30 ||
        StatusCode == 31 ||
        StatusCode == 40 ||

        StatusCode == 549 ||

        //orderstatus - cancelled
        StatusCode == 494 || StatusCode == 495 || StatusCode == 511
      ) {
        StatusIcon = "fa fa-circle text-danger f-s-11 p-r-5";
      } if (
        //orderstatus - active and out for delivery
        StatusCode == 486 || StatusCode == 487 || StatusCode == 488 || StatusCode == 489 || StatusCode == 491 || StatusCode == 492
      ) {
        StatusIcon = "fa fa-circle text-primary f-s-11 p-r-5";
      }
      if (
        //orderstatus - delivery failed
        StatusCode == 493 ||
        StatusCode == 550

      ) {
        StatusIcon = "fa fa-circle text-dark f-s-11 p-r-5";
      }
      return StatusIcon;
    } else {
      if (
        StatusCode == "default.suspended" ||
        StatusCode == "default.inactive" ||
        StatusCode == "transaction.pending" ||
        StatusCode == "transaction.processing" ||
        StatusCode == "product.inactive" ||
        StatusCode == "invoice.pending" ||
        StatusCode == "deal.approvalpending" || StatusCode == "deal.paused" ||
        // order status
        StatusCode == "orderstatus.ready" || StatusCode == "orderstatus.readytopickup" || StatusCode == "orderstatus.outfordelivery"
      ) {
        StatusIcon = "fa fa-circle text-warning f-s-11 p-r-5 ";
      }
      if (
        StatusCode == "default.active" ||
        StatusCode == "transaction.success" ||
        StatusCode == "orderstatus.delivered" ||
        StatusCode == "invoice.cancelled" ||
        StatusCode == "invoice.paid" ||
        StatusCode == "deal.published" || StatusCode == "deal.approved"


      ) {
        StatusIcon = "fa fa-circle text-success f-s-11 p-r-5";
      }
      if (
        StatusCode == "default.disabled" ||
        StatusCode == "default.blocked" ||
        StatusCode == "transaction.error" ||
        StatusCode == "transaction.failed" ||
        StatusCode == "transaction.cancelled" ||
        StatusCode == "product.outofstock" ||
        StatusCode == "invoice.cancelled" ||
        StatusCode == "invoice.expired"



        || StatusCode == "orderstatus.cancelledbyuser" || StatusCode == "orderstatus.cancelledbyseller" || StatusCode == "orderstatus.cancelledbysystem"
      ) {
        StatusIcon = "fa fa-circle text-danger f-s-11 p-r-5";
      } if (StatusCode == "orderstatus.new" || StatusCode == "orderstatus.pendingconfirmation" || StatusCode == "orderstatus.confirmed" || StatusCode == "orderstatus.preparing"
      ) {
        StatusIcon = "fa fa-circle text-primary f-s-11 p-r-5";
      }
      if (StatusCode == "orderstatus.deliveryfailed" ||
        StatusCode == "product.notavailable" ||
        StatusCode == "product.draft"

      ) {
        "fa fa-circle text-dark f-s-11 p-r-5"
      }
      return StatusIcon;
    }
  }
  SaveStorage(StorageName, StorageValue) {
    try {
      var StringV = btoa(JSON.stringify(StorageValue));
      localStorage.setItem(StorageName, StringV);

      return true;
    } catch (e) {
      alert(e);
      return false;
    }
  }
  SaveStorageValue(StorageName, StorageValue) {
    try {
      localStorage.setItem(StorageName, btoa(StorageValue));
      return true;
    } catch (e) {
      alert(e);
      return false;
    }
  }
  //
  EmailAddr: any;
  GetStorage(StorageName) {
    var StorageValue = localStorage.getItem(StorageName);
    
    if (StorageValue != undefined) {
      if (StorageValue != null) {
        return JSON.parse(atob(StorageValue));
      } else {
        return null;
      }
    } else {
      return null;
    }
    

  }
  
  
  GetStorageValue(StorageName) {
    var StorageValue = localStorage.getItem(StorageName);
    if (StorageValue != undefined) {
      if (StorageValue != null) {
        return atob(StorageValue);
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
  DeleteStorage(StorageName) {
    localStorage.removeItem(StorageName);
    return true;
  }
  DownloadCsv(Data, FileName, CsvOptions) {
    // this._Angular2CsvModule.();
    // new Angu(Data, FileName, CsvOptions);
    new Angular2Csv(Data, FileName, CsvOptions);
    //   new Angular2Csv(Data, FileName, CsvOptions);
  }
  PreventText(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar) && event.which < 48 || event.which > 57) {
      event.preventDefault();
    }
  }

  PreventTextButAcceptDecimal(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar) && (event.which < 48 && event.keyCode !== 46) || event.which > 57) {
      event.preventDefault();
    }
  }
  PreventSpecialCharacter(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }
  PreventAmount(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  lowerCaseConvert(str) {
    let email_lowercase = "";
    for (let chars of str) {
      //Get the ascii value of character
      let value = chars.charCodeAt();

      //If the character is in uppercase
      if (value >= 65 && value <= 90) {
        //convert it to lowercase
        email_lowercase += String.fromCharCode(value + 32);
      } else {
        //else add the original character
        email_lowercase += chars;
      }
    }
    return email_lowercase
  }

  GetSearchCondition(BaseString, ColumnName, ColumnType, ColumnValue) {
    if (BaseString == undefined || BaseString == null) {
      BaseString = "";
    }

    var SearchExpression = BaseString;
    if (
      ColumnName != undefined &&
      ColumnType != undefined &&
      ColumnValue != undefined &&
      ColumnName != null &&
      ColumnType != null &&
      ColumnValue != null &&
      ColumnName != "" &&
      ColumnType != "" &&
      ColumnValue != ""
    ) {
      if (ColumnType == "text") {
        if (
          SearchExpression != undefined &&
          SearchExpression != null &&
          SearchExpression != ""
        ) {
          SearchExpression += " OR ";
        }
        SearchExpression += ColumnName + '.Contains("' + ColumnValue + '")';
      } else if (ColumnType == "number") {
        if (isNaN(ColumnValue) == false) {
          if (
            SearchExpression != undefined &&
            SearchExpression != null &&
            SearchExpression != ""
          ) {
            SearchExpression = "( " + SearchExpression + ") AND ";
          }
          SearchExpression += ColumnName + " = " + ColumnValue + " ";
        }
      }

      if (BaseString != undefined && BaseString != null && BaseString != "") {
        BaseString += " OR ";
      }

      //  BaseString += SearchExpression;
      return SearchExpression;
    } else {
      return BaseString;
    }
  }
  GetSortCondition(SortConditions) {
    var NewSortCondition = "";
    if (
      SortConditions != undefined ||
      SortConditions != null ||
      SortConditions.length != 0
    ) {
      for (let index = 0; index < SortConditions.length; index++) {
        const element = SortConditions[index];
        if (index == SortConditions.length - 1) {
          NewSortCondition = NewSortCondition + element;
        } else {
          NewSortCondition = NewSortCondition + element + ",";
        }
      }
    }
    return NewSortCondition;
  }
  GetAccountOverviewMain(UserAccountId, UserAccountKey, StartTime, EndTime) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getaccountoverview",
      StartTime: StartTime, // new Date(2017, 0, 1, 0, 0, 0, 0),
      EndTime: EndTime, // moment().add(2, 'days'),
      AccountId: UserAccountId,
      AccountKey: UserAccountKey
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.Ptsp.V3.TUCPtspAnalytics, Data);
    _OResponse.subscribe(
      _Response => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._AccountOverview = _Response.Result as OAccountOverview;
          if (
            this._AccountOverview.SettlementCredit != undefined &&
            this._AccountOverview.SettlementDebit != undefined
          ) {
            this._AccountOverview.SettlementPending =
              this._AccountOverview.SettlementCredit -
              this._AccountOverview.SettlementDebit;
          }
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      _Error => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetSearchConditionStrict(
    BaseString,
    ColumnName,
    ColumnType,
    ColumnValue,
    Condition
  ) {
    if (BaseString == undefined || BaseString == null) {
      BaseString = "";
    }

    var SearchExpression = BaseString;
    if (
      ColumnName != undefined &&
      ColumnType != undefined &&
      // ColumnValue != undefined &&
      ColumnName != null &&
      ColumnType != null &&
      //ColumnValue != null &&
      ColumnName != "" &&
      ColumnType != "" //&&
      // ColumnValue != ''
    ) {
      if (ColumnType == "text") {
        if (
          SearchExpression != undefined &&
          SearchExpression != null &&
          SearchExpression != ""
        ) {
          SearchExpression = "( " + SearchExpression + ") AND ";
        }
        // SearchExpression += ColumnName + '.Contains("' + ColumnValue + '")';
        if (ColumnValue == "") {
          SearchExpression += ColumnName + " " + Condition + ' "" ';
        } else if (ColumnValue != null && ColumnValue != "") {
          var x = Number(ColumnValue);
          let arr = this.AppConfig.StatusList.transaction;
          let obj = arr.find(o => o.id === x);
          if (obj != undefined) {
            SearchExpression +=
              ColumnName + " " + Condition + ' "' + obj.code + '" ';
          }
          else {
            SearchExpression +=
              ColumnName + " " + Condition + ' "' + ColumnValue + '" ';
          }
        } else {
          SearchExpression +=
            "( " + ColumnName + " " + Condition + " null " + ")";
        }
      } else if (ColumnType == "number") {
        if (
          SearchExpression != undefined &&
          SearchExpression != null &&
          SearchExpression != ""
        ) {
          SearchExpression = "( " + SearchExpression + ") AND ";
        }
        if (ColumnValue != null && ColumnValue != "") {
          SearchExpression +=
            ColumnName + " " + Condition + ' "' + ColumnValue + '" ';
        } else {
          SearchExpression += "( " + ColumnName + " " + Condition + " " + ")";
        }
      }

      if (BaseString != undefined && BaseString != null && BaseString != "") {
        BaseString += " OR ";
      }

      //  BaseString += SearchExpression;
      return SearchExpression;
    } else {
      return BaseString;
    }
  }
  GetSearchConditionStrictOr(
    BaseString,
    ColumnName,
    ColumnType,
    ColumnValue,
    Condition
  ) {
    if (BaseString == undefined || BaseString == null) {
      BaseString = "";
    }

    var SearchExpression = BaseString;
    if (
      ColumnName != undefined &&
      ColumnType != undefined &&
      // ColumnValue != undefined &&
      ColumnName != null &&
      ColumnType != null &&
      //ColumnValue != null &&
      ColumnName != "" &&
      ColumnType != "" //&&
      // ColumnValue != ''
    ) {
      if (ColumnType == this.AppConfig.DataType.Text) {
        if (
          SearchExpression != undefined &&
          SearchExpression != null &&
          SearchExpression != ""
        ) {
          SearchExpression = "( " + SearchExpression + ") OR ";
        }
        // SearchExpression += ColumnName + '.Contains("' + ColumnValue + '")';
        if (ColumnValue == "") {
          SearchExpression += ColumnName + " " + Condition + ' "" ';
        } else if (ColumnValue != null && ColumnValue != "") {
          SearchExpression +=
            ColumnName + " " + Condition + ' "' + ColumnValue + '" ';
        } else {
          SearchExpression +=
            "( " + ColumnName + " " + Condition + " null " + ")";
        }
      } else if (ColumnType == this.AppConfig.DataType.Number) {
        if (
          isNaN(ColumnValue) == false &&
          ColumnValue.toString().indexOf(".") == -1
        ) {
          if (
            SearchExpression != undefined &&
            SearchExpression != null &&
            SearchExpression != ""
          ) {
            SearchExpression = "( " + SearchExpression + ") OR ";
          }
          if (ColumnValue != null && ColumnValue != "") {
            SearchExpression +=
              ColumnName + " " + Condition + ' "' + ColumnValue + '" ';
          } else {
            SearchExpression += "( " + ColumnName + " " + Condition + " " + ")";
          }
        }
      } else if (ColumnType == this.AppConfig.DataType.Decimal) {
        if (isNaN(ColumnValue) == false) {
          if (
            SearchExpression != undefined &&
            SearchExpression != null &&
            SearchExpression != ""
          ) {
            SearchExpression = "( " + SearchExpression + ") OR ";
          }
          if (ColumnValue != null && ColumnValue != "") {
            SearchExpression +=
              ColumnName + " " + Condition + ' "' + ColumnValue + '" ';
          } else {
            SearchExpression += "( " + ColumnName + " " + Condition + " " + ")";
          }
        }
      }
      if (BaseString != undefined && BaseString != null && BaseString != "") {
        BaseString += " AND ";
      }

      //  BaseString += SearchExpression;
      return SearchExpression;
    } else {
      return BaseString;
    }
  }
  GetDateCondition(BaseString, ColumnName, StartTime, EndTime) {
    if (BaseString == undefined || BaseString == null) {
      BaseString = "";
    }

    var SearchExpression = BaseString;
    if (
      ColumnName != undefined &&
      StartTime != undefined &&
      EndTime != undefined &&
      ColumnName != null &&
      StartTime != null &&
      EndTime != null &&
      ColumnName != "" &&
      StartTime != "" &&
      EndTime != ""
    ) {
      // StartTime = moment(StartTime).subtract(1, "seconds");
      // EndTime = moment(EndTime).add(1, "seconds");
      var FSd = new Date(StartTime);
      var TStartDateM = moment(FSd).format("YYYY-MM-DD HH:mm:ss");
      // var TStartDateM = moment(FSd).utc().format("YYYY-MM-DD HH:mm:ss");
      var ESd = new Date(EndTime);
      var TEndTimeM = moment(ESd).format("YYYY-MM-DD HH:mm:ss");
      // var TEndTimeM = moment(ESd).utc().format("YYYY-MM-DD HH:mm:ss");
      // var TStartTime = FSd.getFullYear() + '-' + (FSd.getMonth() + 1) + '-' + (FSd.getDate()) + ' 23:59:00';
      // var TEndTime = ESd.getFullYear() + '-' + (ESd.getMonth() + 1) + '-' + (ESd.getDate()) + ' 23:59:00';
      if (
        SearchExpression != undefined &&
        SearchExpression != null &&
        SearchExpression != ""
      ) {
        SearchExpression = "( " + SearchExpression + ") AND ";
      }
      SearchExpression +=
        "( " +
        ColumnName +
        ' >= "' +
        TStartDateM +
        '" AND ' +
        ColumnName +
        ' <= "' +
        TEndTimeM +
        '")';
      if (BaseString != undefined && BaseString != null && BaseString != "") {
        BaseString += " AND ";
      }
      //  BaseString += SearchExpression;
      return SearchExpression;
    } else {
      return BaseString;
    }
  }
  GetDateOnlyCondition(BaseString, ColumnName, StartTime) {
    if (BaseString == undefined || BaseString == null) {
      BaseString = "";
    }

    var SearchExpression = BaseString;
    if (
      ColumnName != undefined &&
      StartTime != undefined &&
      ColumnName != null &&
      StartTime != null &&
      ColumnName != "" &&
      StartTime != ""
    ) {
      StartTime = moment(StartTime).format("LL");
      var FSd = new Date(StartTime);
      var TStartDateM = moment(FSd).utc().format("YYYY-MM-DD");
      if (
        SearchExpression != undefined &&
        SearchExpression != null &&
        SearchExpression != ""
      ) {
        SearchExpression = "( " + SearchExpression + ") AND ";
      }
      SearchExpression += "( " + ColumnName + ' = "' + TStartDateM + '")';
      if (BaseString != undefined && BaseString != null && BaseString != "") {
        BaseString += " AND ";
      }
      //  BaseString += SearchExpression;
      return SearchExpression;
    } else {
      return BaseString;
    }
  }
  GetSearchConditionRangeCustom(BaseString, ColumnName, StartRange, EndRange) {
    if (BaseString == undefined || BaseString == null) {
      BaseString = "";
    }
    StartRange = StartRange;
    EndRange = EndRange + 1;
    var SearchExpression = BaseString;
    if (
      ColumnName != undefined &&
      StartRange != undefined &&
      EndRange != undefined &&
      ColumnName != null &&
      StartRange != null &&
      EndRange != null &&
      ColumnName != "" &&
      StartRange != "" &&
      EndRange != ""
    ) {
      if (
        SearchExpression != undefined &&
        SearchExpression != null &&
        SearchExpression != ""
      ) {
        SearchExpression = "( " + SearchExpression + ") AND ";
      }
      SearchExpression +=
        "( " +
        ColumnName +
        ' > "' +
        StartRange +
        '" AND ' +
        ColumnName +
        ' < "' +
        EndRange +
        '")';
      if (BaseString != undefined && BaseString != null && BaseString != "") {
        BaseString += " AND ";
      }
      //  BaseString += SearchExpression;
      return SearchExpression;
    } else {
      return BaseString;
    }
  }
  GetSearchConditionRange(BaseString, ColumnName, StartRange, EndRange) {
    if (BaseString == undefined || BaseString == null) {
      BaseString = "";
    }
    // if (StartRange != null || StartRange > 0) {
    //   StartRange = StartRange - 1;
    // }
    StartRange = StartRange;
    EndRange = EndRange + 1;
    var SearchExpression = BaseString;
    if (
      ColumnName != undefined &&
      StartRange != undefined &&
      EndRange != undefined &&
      ColumnName != null &&
      StartRange != null &&
      EndRange != null &&
      ColumnName != "" &&
      // StartRange != "" &&
      EndRange != ""
    ) {
      if (
        SearchExpression != undefined &&
        SearchExpression != null &&
        SearchExpression != ""
      ) {
        SearchExpression = "( " + SearchExpression + ") AND ";
      }
      SearchExpression +=
        "( " +
        ColumnName +
        ' >= "' +
        StartRange +
        '" AND ' +
        ColumnName +
        ' < "' +
        EndRange +
        '")';
      if (BaseString != undefined && BaseString != null && BaseString != "") {
        BaseString += " AND ";
      }
      //  BaseString += SearchExpression;
      return SearchExpression;
    } else {
      return BaseString;
    }
  }
  GetSearchConditionStrictFromArray(
    BaseString,
    ColumnName,
    ColumnType,
    ColumnValueArray,
    Condition
  ) {
    if (BaseString == undefined || BaseString == null) {
      BaseString = "";
    }
    var SearchExpression = BaseString;
    if (
      ColumnName != undefined &&
      ColumnType != undefined &&
      ColumnName != null &&
      ColumnType != null &&
      ColumnName != "" &&
      ColumnType != "" //&&
    ) {
      if (
        ColumnValueArray != undefined ||
        ColumnValueArray != null ||
        ColumnValueArray.length != 0
      ) {
        if (ColumnType == "text") {
          var TExpression = "";
          for (let index = 0; index < ColumnValueArray.length; index++) {
            const ColumnValue = ColumnValueArray[index];
            if (TExpression != "") {
              TExpression += " OR ";
            }
            if (ColumnValue != null && ColumnValue != "") {
              TExpression +=
                ColumnName + " " + Condition + ' "' + ColumnValue + '" ';
            } else {
              TExpression +=
                " " + ColumnName + " " + Condition + " null " + " ";
            }
          }
          if (TExpression != "") {
            if (SearchExpression != "") {
              SearchExpression =
                "( " + SearchExpression + ") AND (" + TExpression + ")";
            } else {
              SearchExpression = TExpression;
            }
          }
        } else if (ColumnType == "number") {
          var TColSearch = "";
          if (ColumnValueArray.length == 1) {
            const ColumnValue = ColumnValueArray[0];
            if (ColumnValue != null && ColumnValue != "") {
              TColSearch +=
                " " + ColumnName + " " + Condition + ' "' + ColumnValue + '"';
            } else {
              TColSearch += " " + ColumnName + " " + Condition + " " + "";
            }
          } else {
            for (let index = 0; index < ColumnValueArray.length; index++) {
              const ColumnValue = ColumnValueArray[index];
              if (TColSearch != "") {
                TColSearch += " OR ";
              }
              if (ColumnValue != null && ColumnValue != "") {
                TColSearch +=
                  " " +
                  ColumnName +
                  " " +
                  Condition +
                  ' "' +
                  ColumnValue +
                  '" ';
              } else {
                TColSearch += "  " + ColumnName + " " + Condition + " " + " ";
              }
            }
          }
          if (TColSearch != "") {
            if (SearchExpression != "") {
              SearchExpression =
                "( " + SearchExpression + ") AND (" + TColSearch + ")";
            } else {
              SearchExpression = TColSearch;
            }
          }
        }
      }
      return SearchExpression;
    } else {
      return BaseString;
    }
  }
  GetSearchConditionSubStrictFromArray(
    BaseString,
    ColumnName,
    ColumnType,
    ColumnValueArray,
    Condition
  ) {
    if (BaseString == undefined || BaseString == null) {
      BaseString = "";
    }
    var SearchExpression = BaseString;
    if (
      ColumnName != undefined &&
      ColumnType != undefined &&
      ColumnName != null &&
      ColumnType != null &&
      ColumnName != "" &&
      ColumnType != "" //&&
    ) {
      if (
        ColumnValueArray != undefined ||
        ColumnValueArray != null ||
        ColumnValueArray.length != 0
      ) {
        if (ColumnType == "text") {
          var TExpression = "";
          for (let index = 0; index < ColumnValueArray.length; index++) {
            const ColumnValue = ColumnValueArray[index];
            if (TExpression != "") {
              TExpression += " OR ";
            }
            if (ColumnValue != null && ColumnValue != "") {
              TExpression +=
                ColumnName + " " + Condition + ' "' + ColumnValue + '" ';
            } else {
              TExpression +=
                " " + ColumnName + " " + Condition + " null " + " ";
            }
          }
          if (TExpression != "") {
            if (SearchExpression != "") {
              SearchExpression =
                "( " + SearchExpression + ") AND (" + TExpression + ")";
            } else {
              SearchExpression = TExpression;
            }
          }
        } else if (ColumnType == "number") {
          var TColSearch = "";
          if (ColumnValueArray.length == 1) {
            const ColumnValue = ColumnValueArray[0];
            if (ColumnValue != null && ColumnValue != "") {
              TColSearch +=
                " " + ColumnName + " " + Condition + ' "' + ColumnValue + '"';
            } else {
              TColSearch += " " + ColumnName + " " + Condition + " " + "";
            }
          } else {
            for (let index = 0; index < ColumnValueArray.length; index++) {
              const ColumnValue = ColumnValueArray[index];
              if (TColSearch != "") {
                TColSearch += " OR ";
              }
              if (ColumnValue != null && ColumnValue != "") {
                TColSearch +=
                  " " +
                  ColumnName +
                  " " +
                  Condition +
                  ' "' +
                  ColumnValue +
                  '" ';
              } else {
                TColSearch += "  " + ColumnName + " " + Condition + " " + " ";
              }
            }
          }
          if (TColSearch != "") {
            if (SearchExpression != "") {
              SearchExpression =
                "( " + SearchExpression + ") AND (" + TColSearch + ")";
            } else {
              SearchExpression = TColSearch;
            }
          }
        }
      }
      return SearchExpression;
    } else {
      return BaseString;
    }
  }
  GetImageDetails(ImageBase64) {
    var _ImageContent: OStorageContent = {
      Name: null,
      Content: null,
      Extension: null,
      TypeCode: null,
      Width: 0,
      Height: 0,
    };

    if (ImageBase64 != null && ImageBase64 != "") {
      var ImageContent = ImageBase64;
      var ImageExtension = "jpg";
      var ImageBase = ImageContent.replace("data:image/png;base64,", "")
        .replace("data:image/jpg;base64,", "")
        .replace("data:image/jpeg;base64,", "")
        .replace("data:image/gif;base64,", "");
      if (ImageContent.includes("data:image/png;base64,")) {
        ImageExtension = "png";
      } else if (ImageContent.includes("data:image/jpg;base64,")) {
        ImageExtension = "jpg";
      } else if (ImageContent.includes("data:image/jpeg;base64,")) {
        ImageExtension = "jpeg";
      } else if (ImageContent.includes("data:image/gif;base64,")) {
        ImageExtension = "gif";
      } else {
        ImageExtension = "jpg";
      }
      _ImageContent.Content = ImageBase;
      _ImageContent.Extension = ImageExtension;
      return _ImageContent;
    } else {
      return _ImageContent;
    }
  }

  GetFileFromB64URL(url, filename, mimeType) {
    return (fetch(url)
      .then((res) => { return res.arrayBuffer(); })
      .then((buf) => { return new File([buf], filename, { type: mimeType }); })
    );
  }
  _TerminalStatusCountArray = [
    { data: [], label: 'Active' },
    { data: [], label: 'Idle' },
    { data: [], label: 'Dead ' },
    { data: [], label: 'Idle' },

  ];
  GetTerminalStatusCount(
    UserAccountId,
    UserAccountTypeId,
    SubUserAccountId,
    SubUserAccountTypeId
  ) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getterminalstatuscount",
      UserAccountId: UserAccountId,
      UserAccountTypeId: UserAccountTypeId,
      SubUserAccountId: SubUserAccountId,
      SubUserAccountTypeId: SubUserAccountTypeId,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(
      this.AppConfig.NetworkLocation.V2.TUCAnalytics,
      Data
    );
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._TerminalStatusCount = _Response.Result as OTerminalStatusCount;

          this._TerminalStatusCountArray = [
            { data: [this._TerminalStatusCount.Active], label: 'Active' },
            { data: [this._TerminalStatusCount.Idle], label: 'Idle' },
            { data: [this._TerminalStatusCount.Dead], label: 'Dead ' },
            { data: [this._TerminalStatusCount.Unused], label: 'InActive' },
          ];
          // this._TerminalStatusCountArray[0].data = this._TerminalStatusCount.Active;
          // this._TerminalStatusCountArray[1].data = this._TerminalStatusCount.Idle;
          // this._TerminalStatusCountArray[2].data = this._TerminalStatusCount.Dead;
          // this._TerminalStatusCountArray[3].data = this._TerminalStatusCount.Unused;


        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetUserCounts(
    UserAccountId,
    UserAccountTypeId,
    SubUserAccountId,
    SubUserAccountTypeId,
    StartTime,
    EndTime
  ) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getusercounts",
      StartTime: StartTime, // new Date(2017, 0, 1, 0, 0, 0, 0),
      EndTime: EndTime, // moment().add(2, 'days'),
      UserAccountId: UserAccountId,
      UserAccountTypeId: UserAccountTypeId,
      SubUserAccountId: SubUserAccountId,
      SubUserAccountTypeId: SubUserAccountTypeId,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(
      this.AppConfig.NetworkLocation.V2.TUCAnalytics,
      Data
    );
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._UserCounts = _Response.Result as OUserCounts;
          if (
            this._AccountOverview.SettlementCredit != undefined &&
            this._AccountOverview.SettlementDebit != undefined
          ) {
            this._AccountOverview.SettlementPending =
              this._AccountOverview.SettlementCredit -
              this._AccountOverview.SettlementDebit;
          }
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetSalesOverview(
    UserAccountId,
    UserAccountTypeId,
    SubUserAccountId,
    SubUserAccountTypeId
  ) {
    this.IsFormProcessing = true;
    this._SalesOverview.TransactionTypeLabels = [];
    this._SalesOverview.TransactionTypeData = [];
    this._SalesOverview.TransactionTypeUsersLabels = [];
    this._SalesOverview.TransactionTypeUsersData = [];
    this._SalesOverview.TransactionStatusLabels = [];
    this._SalesOverview.TransactionStatusData = [];

    this._SalesOverview.TransactionTypeSalesLabels = [];
    this._SalesOverview.TransactionTypeSalesData = [];

    var Data = {
      Task: "getsalesoverview",
      UserAccountId: UserAccountId,
      UserAccountTypeId: UserAccountTypeId,
      SubUserAccountId: SubUserAccountId,
      SubUserAccountTypeId: SubUserAccountTypeId,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(
      this.AppConfig.NetworkLocation.V2.TUCAnalytics,
      Data
    );
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._SalesOverview = _Response.Result as OSalesOverview;
          this._SalesOverview.FirstTransactionDate = this.GetDateTimeS(
            this._SalesOverview.FirstTransactionDate
          );
          this._SalesOverview.LastTransactionDateD = this.GetTimeDifferenceS(
            this._SalesOverview.LastTransactionDate,
            moment()
          );
          this._SalesOverview.LastTransactionDate = this.GetDateTimeS(
            this._SalesOverview.LastTransactionDate
          );

          this._SalesOverview.TransactionTypeUsersLabels = [];
          this._SalesOverview.TransactionTypeUsersData = [];
          this._SalesOverview.TransactionTypeUsersLabels.push("Cash Tr Users");
          this._SalesOverview.TransactionTypeUsersLabels.push("Card Tr. Users");
          this._SalesOverview.TransactionTypeUsersData.push(
            this._SalesOverview.TotalCashTransactionCustomer
          );
          this._SalesOverview.TransactionTypeUsersData.push(
            this._SalesOverview.TotalCardTransactionCustomer
          );

          this._SalesOverview.TransactionTypeSalesLabels = [];
          this._SalesOverview.TransactionTypeSalesData = [];
          this._SalesOverview.TransactionTypeSalesLabels.push("Cash Payments");
          this._SalesOverview.TransactionTypeSalesLabels.push("Card Payments");
          this._SalesOverview.TransactionTypeSalesData.push(
            this._SalesOverview.TotalCashTransactionInvoiceAmount
          );
          this._SalesOverview.TransactionTypeSalesData.push(
            this._SalesOverview.TotalCardTransactionInvoiceAmount
          );

          this._SalesOverview.TransactionTypeLabels = [];
          this._SalesOverview.TransactionTypeData = [];
          this._SalesOverview.TransactionTypeLabels.push("Cash Transactions");
          this._SalesOverview.TransactionTypeLabels.push("Card Transactions");
          this._SalesOverview.TransactionTypeData.push(
            this._SalesOverview.TotalCashTransaction
          );
          this._SalesOverview.TransactionTypeData.push(
            this._SalesOverview.TotalCardTransaction
          );

          this._SalesOverview.TransactionStatusLabels = [];
          this._SalesOverview.TransactionStatusData = [];
          this._SalesOverview.TransactionStatusLabels.push("Success");
          this._SalesOverview.TransactionStatusLabels.push("Failed");
          this._SalesOverview.TransactionStatusData.push(
            this._SalesOverview.TotalSuccessfulTransaction
          );
          this._SalesOverview.TransactionStatusData.push(
            this._SalesOverview.TotalFailedTransaction
          );
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetSalesSummary(
    UserAccountId,
    UserAccountTypeId,
    SubUserAccountId,
    SubUserAccountTypeId,
    StartTime,
    EndTime
  ) {
    this.IsFormProcessing = true;
    this._SalesSummary.TransactionTypeLabels = [];
    this._SalesSummary.TransactionTypeData = [];
    this._SalesSummary.TransactionTypeUsersLabels = [];
    this._SalesSummary.TransactionTypeUsersData = [];
    this._SalesSummary.TransactionStatusLabels = [];
    this._SalesSummary.TransactionStatusData = [];
    this._SalesSummary.TransactionTypeSalesLabels = [];
    this._SalesSummary.TransactionTypeSalesData = [];
    var Data = {
      Task: "getsalessummary",
      StartTime: StartTime,
      EndTime: EndTime,
      UserAccountId: UserAccountId,
      UserAccountTypeId: UserAccountTypeId,
      SubUserAccountId: SubUserAccountId,
      SubUserAccountTypeId: SubUserAccountTypeId,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(
      this.AppConfig.NetworkLocation.V2.TUCAnalytics,
      Data
    );
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._SalesSummary = _Response.Result as OSalesSummary;
          this._SalesSummary.FirstTransactionDate = this.GetDateTimeS(
            this._SalesSummary.FirstTransactionDate
          );
          this._SalesSummary.LastTransactionDateD = this.GetTimeDifferenceS(
            this._SalesSummary.LastTransactionDate,
            moment()
          );
          this._SalesSummary.LastTransactionDate = this.GetDateTimeS(
            this._SalesSummary.LastTransactionDate
          );

          this._SalesSummary.TransactionTypeUsersLabels = [];
          this._SalesSummary.TransactionTypeUsersData = [];
          this._SalesSummary.TransactionTypeUsersLabels.push("Cash Tr Users");
          this._SalesSummary.TransactionTypeUsersLabels.push("Card Tr. Users");
          this._SalesSummary.TransactionTypeUsersData.push(
            this._SalesSummary.TotalCashTransactionCustomer
          );
          this._SalesSummary.TransactionTypeUsersData.push(
            this._SalesSummary.TotalCardTransactionCustomer
          );

          this._SalesSummary.TransactionTypeSalesLabels = [];
          this._SalesSummary.TransactionTypeSalesData = [];
          this._SalesSummary.TransactionTypeSalesLabels.push("Cash Payments");
          this._SalesSummary.TransactionTypeSalesLabels.push("Card Payments");
          this._SalesSummary.TransactionTypeSalesData.push(
            this._SalesSummary.TotalCashTransactionInvoiceAmount
          );
          this._SalesSummary.TransactionTypeSalesData.push(
            this._SalesSummary.TotalCardTransactionInvoiceAmount
          );

          this._SalesSummary.TransactionTypeLabels = [];
          this._SalesSummary.TransactionTypeData = [];
          this._SalesSummary.TransactionTypeLabels.push("Cash Transactions");
          this._SalesSummary.TransactionTypeLabels.push("Card Transactions");
          this._SalesSummary.TransactionTypeData.push(
            this._SalesSummary.TotalCashTransaction
          );
          this._SalesSummary.TransactionTypeData.push(
            this._SalesSummary.TotalCardTransaction
          );

          this._SalesSummary.TransactionStatusLabels = [];
          this._SalesSummary.TransactionStatusData = [];
          this._SalesSummary.TransactionStatusLabels.push("Success");
          this._SalesSummary.TransactionStatusLabels.push("Failed");
          this._SalesSummary.TransactionStatusData.push(
            this._SalesSummary.TotalSuccessfulTransaction
          );
          this._SalesSummary.TransactionStatusData.push(
            this._SalesSummary.TotalFailedTransaction
          );
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetRewardsSummary(
    UserAccountId,
    UserAccountTypeId,
    SubUserAccountId,
    SubUserAccountTypeId,
    StartTime,
    EndTime
  ) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getrewardssummary",
      StartTime: StartTime,
      EndTime: EndTime,
      UserAccountId: UserAccountId,
      UserAccountTypeId: UserAccountTypeId,
      SubUserAccountId: SubUserAccountId,
      SubUserAccountTypeId: SubUserAccountTypeId,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(
      this.AppConfig.NetworkLocation.V2.TUCAnalytics,
      Data
    );
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._RewardsSummary = _Response.Result as ORewardsSummary;
          this._RewardsSummary.FirstTransactionDateD = this.GetDateTimeS(
            this._RewardsSummary.FirstTransactionDate
          );
          this._RewardsSummary.FirstTransactionDate = this.GetDateTimeS(
            this._RewardsSummary.FirstTransactionDate
          );
          this._RewardsSummary.LastTransactionDateD = this.GetTimeDifferenceS(
            this._RewardsSummary.LastTransactionDate,
            moment()
          );
          this._RewardsSummary.LastTransactionDate = this.GetDateTimeS(
            this._RewardsSummary.LastTransactionDate
          );
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  public _OSalesHistory = {
    Labels: [],
    SaleColors: [],
    SaleDataSet: [],

    SaleCustomersColors: [],
    SaleCustomersDataSet: [],

    TransactionStatusDataSetColors: [],
    TransactionStatusDataSet: [],

    TransactionTypeDataSetColors: [],
    TransactionTypeDataSet: [],

    TransactionTypeCustomersDataSetColors: [],
    TransactionTypeCustomersDataSet: [],
  };
  GetSalesHistory(
    UserAccountId,
    UserAccountTypeId,
    SubUserAccountId,
    SubUserAccountTypeId,
    StartTime,
    EndTime
  ) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getsaleshistory",
      StartTime: StartTime,
      EndTime: EndTime,
      UserAccountId: UserAccountId,
      UserAccountTypeId: UserAccountTypeId,
      SubUserAccountId: SubUserAccountId,
      SubUserAccountTypeId: SubUserAccountTypeId,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(
      this.AppConfig.NetworkLocation.V2.TUCAnalytics,
      Data
    );
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          var TStatusDataSet = [];
          var TStatusDataSetColor = [];
          this._OSalesHistory.Labels = [];
          var ResponseData = _Response.Result;
          var TSaleColor = [
            {
              backgroundColor: [],
            },
          ];
          var TSaleDataSet = [
            {
              label: "Sale",
              fill: false,
              borderColor: "#907eec",
              borderDash: [5, 5],
              backgroundColor: "#907eec",
              pointBackgroundColor: "#fdab29",
              pointBorderColor: "#fdab29",
              pointHoverBackgroundColor: "#fdab29",
              pointHoverBorderColor: "#fdab29",
              data: [],
            },
          ];
          var TSaleCustomersColor = [
            {
              backgroundColor: [],
            },
          ];
          var TSaleCustomersDataSet = [
            {
              label: "Customer Visits",
              fill: false,
              borderColor: "#907eec",
              borderDash: [5, 5],
              backgroundColor: "#907eec",
              pointBackgroundColor: "#fdab29",
              pointBorderColor: "#fdab29",
              pointHoverBackgroundColor: "#fdab29",
              pointHoverBorderColor: "#fdab29",
              data: [],
            },
          ];
          var TTypeDataSetColor = [];
          var TTypeDataSet = [];
          var TTypeCustomersDataSetColor = [];
          var TTypeCustomersDataSet = [];
          var TTypeDataSetCardItemColor = {
            backgroundColor: [],
          };
          var TTypeDataSetCashItemColor = {
            backgroundColor: [],
          };
          var TTypeCustomersDataSetCardItemColor = {
            backgroundColor: [],
          };
          var TTypeCustomersDataSetCashItemColor = {
            backgroundColor: [],
          };
          var TTypeDataSetCardItem = {
            label: "Card",
            backgroundColor: [],
            data: [],
          };
          var TTypeDataSetCashItem = {
            label: "Cash",
            backgroundColor: [],
            data: [],
          };
          var TTypeCustomersDataSetCardItem = {
            label: "Card Customers",
            backgroundColor: [],
            data: [],
          };
          var TTypeCustomersDataSetCashItem = {
            label: "Cash Customers",
            backgroundColor: [],
            data: [],
          };
          var TStatusDataSetSuccessItemColor = {
            backgroundColor: [],
          };
          var TStatusDataSetErrorItemError = {
            backgroundColor: [],
          };
          var TStatusDataSetSuccessItem = {
            label: "Success",
            backgroundColor: [],
            data: [],
          };
          var TStatusDataSetErrorItem = {
            label: "Error",
            backgroundColor: [],
            data: [],
          };
          ResponseData.forEach((element) => {
            var Data = element.Data;
            this._OSalesHistory.Labels.push(this.GetDateMonthS(element.Date));
            TSaleDataSet[0].data.push(
              Math.round(Data.TotalSuccessfulTransactionInvoiceAmount)
            );
            TSaleColor[0].backgroundColor.push("rgba(97, 192, 60, 0.7)");
            TSaleCustomersDataSet[0].data.push(
              Data.TotalSuccessfulTransactionCustomer
            );
            TSaleCustomersColor[0].backgroundColor.push(
              "rgba(153, 0, 153, 0.7)"
            );
            TTypeDataSetCardItem.data.push(
              Math.round(Data.TotalCardTransactionInvoiceAmount)
            );
            TTypeDataSetCardItemColor.backgroundColor.push("#61c03c");
            TTypeDataSetCashItem.data.push(
              Math.round(Data.TotalCashTransactionInvoiceAmount)
            );
            TTypeDataSetCashItemColor.backgroundColor.push("#ff9900");
            TTypeCustomersDataSetCardItem.data.push(
              Data.TotalCardTransactionCustomer
            );
            TTypeCustomersDataSetCardItemColor.backgroundColor.push("#990099");
            TTypeCustomersDataSetCashItem.data.push(
              Data.TotalCashTransactionCustomer
            );
            TTypeCustomersDataSetCashItemColor.backgroundColor.push("#00aff0");
            TStatusDataSetSuccessItem.data.push(
              Data.TotalSuccessfulTransaction
            );
            TStatusDataSetSuccessItemColor.backgroundColor.push("#61c03c");
            TStatusDataSetErrorItem.data.push(Data.TotalFailedTransaction);
            TStatusDataSetErrorItemError.backgroundColor.push("#dc3912");
          });
          TStatusDataSetColor.push(TStatusDataSetSuccessItemColor);
          TStatusDataSetColor.push(TStatusDataSetErrorItemError);
          TStatusDataSet.push(TStatusDataSetSuccessItem);
          TStatusDataSet.push(TStatusDataSetErrorItem);
          TTypeDataSetColor.push(TTypeDataSetCardItemColor);
          TTypeDataSetColor.push(TTypeDataSetCashItemColor);
          TTypeDataSet.push(TTypeDataSetCardItem);
          TTypeDataSet.push(TTypeDataSetCashItem);
          TTypeCustomersDataSetColor.push(TTypeCustomersDataSetCardItemColor);
          TTypeCustomersDataSetColor.push(TTypeCustomersDataSetCashItemColor);
          TTypeCustomersDataSet.push(TTypeCustomersDataSetCardItem);
          TTypeCustomersDataSet.push(TTypeCustomersDataSetCashItem);
          this._OSalesHistory.TransactionStatusDataSet = TStatusDataSet;
          this._OSalesHistory.TransactionStatusDataSetColors = TStatusDataSetColor;
          this._OSalesHistory.TransactionTypeDataSet = TTypeDataSet;
          this._OSalesHistory.TransactionTypeDataSetColors = TTypeDataSetColor;
          this._OSalesHistory.TransactionTypeCustomersDataSet = TTypeCustomersDataSet;
          this._OSalesHistory.TransactionTypeCustomersDataSetColors = TTypeCustomersDataSetColor;
          this._OSalesHistory.SaleDataSet = TSaleDataSet;
          this._OSalesHistory.SaleColors = TSaleColor;
          this._OSalesHistory.SaleCustomersDataSet = TSaleCustomersDataSet;
          this._OSalesHistory.SaleCustomersColors = TSaleCustomersColor;
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetCardTypeSalesSummary(
    UserAccountId,
    UserAccountTypeId,
    SubUserAccountId,
    SubUserAccountTypeId,
    StartTime,
    EndTime
  ) {
    this.IsFormProcessing = true;
    this._CardTypeSalesSummary.CardTypeLabels = [];
    this._CardTypeSalesSummary.CardTypeData = [];
    var Data = {
      Task: "getcardtypesalessummary",
      StartTime: StartTime,
      EndTime: EndTime,
      UserAccountId: UserAccountId,
      UserAccountTypeId: UserAccountTypeId,
      SubUserAccountId: SubUserAccountId,
      SubUserAccountTypeId: SubUserAccountTypeId,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(
      this.AppConfig.NetworkLocation.V2.TUCAnalytics,
      Data
    );
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._CardTypeSalesSummary = _Response.Result as OCardTypeSalesSummary;
          this._CardTypeSalesSummary.CardTypeLabels = [];
          this._CardTypeSalesSummary.CardTypeData = [];
          if (
            this._CardTypeSalesSummary != undefined &&
            this._CardTypeSalesSummary.CardTypes != undefined
          ) {
            this._CardTypeSalesSummary.CardTypes.forEach((element) => {
              this._CardTypeSalesSummary.CardTypeLabels.push(
                element.CardTypeName
              );
              this._CardTypeSalesSummary.CardTypeData.push(
                element.TotalTransaction
              );
              element.FirstTransactionDate = this.GetDateTimeS(
                element.FirstTransactionDate
              );
              element.LastTransactionDateD = this.GetTimeDifferenceS(
                element.LastTransactionDate,
                moment()
              );
              element.LastTransactionDate = this.GetDateTimeS(
                element.LastTransactionDate
              );
            });
          }
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetAccountOverview(UserAccountKey, SubUserAccountKey, StartTime, EndTime) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getaccountoverview",
      StartTime: StartTime, // new Date(2017, 0, 1, 0, 0, 0, 0),
      EndTime: EndTime, // moment().add(2, 'days'),
      UserAccountKey: UserAccountKey,
      SubUserAccountKey: SubUserAccountKey,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V2.ThankU, Data);
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._AccountOverview = _Response.Result as OAccountOverview;
          if (
            this._AccountOverview.SettlementCredit != undefined &&
            this._AccountOverview.SettlementDebit != undefined
          ) {
            this._AccountOverview.SettlementPending =
              this._AccountOverview.SettlementCredit -
              this._AccountOverview.SettlementDebit;
          }
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetAccountOverviewNext(
    UserAccountKey,
    SubUserAccountKey,
    StartTime,
    EndTime
  ) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getaccountoverview",
      StartTime: StartTime, // new Date(2017, 0, 1, 0, 0, 0, 0),
      EndTime: EndTime, // moment().add(2, 'days'),
      UserAccountKey: UserAccountKey,
      SubUserAccountKey: SubUserAccountKey,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V2.ThankU, Data);
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._AccountOverviewNext = _Response.Result as OAccountOverview;
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetRewardOverview(UserAccountKey, SubUserAccountKey, StartTime, EndTime) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getrewardoverview",
      StartTime: StartTime, // new Date(2017, 0, 1, 0, 0, 0, 0),
      EndTime: EndTime, // moment().add(2, 'days'),
      UserAccountKey: UserAccountKey,
      SubUserAccountKey: SubUserAccountKey,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V2.ThankU, Data);
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._RewardOverview = _Response.Result as ORewardOverview;
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetRewardOverviewNext(UserAccountKey, SubUserAccountKey, StartTime, EndTime) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getrewardoverview",
      StartTime: StartTime, // new Date(2017, 0, 1, 0, 0, 0, 0),
      EndTime: EndTime, // moment().add(2, 'days'),
      UserAccountKey: UserAccountKey,
      SubUserAccountKey: SubUserAccountKey,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V2.ThankU, Data);
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._RewardOverviewNext = _Response.Result as ORewardOverview;
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetRewardTypeOverview(UserAccountKey, SubUserAccountKey, StartTime, EndTime) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getrewardtypeoverview",
      StartTime: StartTime, // new Date(2017, 0, 1, 0, 0, 0, 0),
      EndTime: EndTime, // moment().add(2, 'days'),
      UserAccountKey: UserAccountKey,
      SubUserAccountKey: SubUserAccountKey,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V2.ThankU, Data);
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._RewardTypeOverview = _Response.Result as ORewardTypeOverview;

          if (
            this._RewardTypeOverview.Transactions &&
            this._RewardTypeOverview.Transactions > 0
          ) {
            this._RewardTypeOverview.CardRewardTransactionsPerc =
              (this._RewardTypeOverview.CashRewardTransactions * 100) /
              this._RewardTypeOverview.Transactions;
            this._RewardTypeOverview.CashRewardTransactionsPerc =
              (this._RewardTypeOverview.CardRewardTransactions * 100) /
              this._RewardTypeOverview.Transactions;
          }

          this._RewardTypeOverview.RewardTypeLabels = [];
          this._RewardTypeOverview.RewardTypeData = [];
          this._RewardTypeOverview.RewardTypeLabels.push("Cash");
          this._RewardTypeOverview.RewardTypeLabels.push("Card");
          this._RewardTypeOverview.RewardTypeData.push(
            this._RewardTypeOverview.CashRewardPurchaseAmount
          );
          this._RewardTypeOverview.RewardTypeData.push(
            this._RewardTypeOverview.CardRewardPurchaseAmount
          );

          this._RewardTypeOverview.AppUsersCardTypeLabel = [];
          this._RewardTypeOverview.AppUsersCardTypeDataUsers = [];
          this._RewardTypeOverview.AppUsersCardTypeDataTransactions = [];
          this._RewardTypeOverview.AppUsersCardTypeDataPurchase = [];
          this._RewardTypeOverview.AppUsersCardType.forEach((element) => {
            if (
              this._RewardTypeOverview.Transactions &&
              this._RewardTypeOverview.Transactions > 0
            ) {
              element.TransactionPerc =
                (element.Purchase * 100) /
                this._RewardTypeOverview.Transactions;
            }
            this._RewardTypeOverview.AppUsersCardTypeLabel.push(element.Name);
            this._RewardTypeOverview.AppUsersCardTypeDataUsers.push(
              element.Users
            );
            this._RewardTypeOverview.AppUsersCardTypeDataTransactions.push(
              element.Transactions
            );
            this._RewardTypeOverview.AppUsersCardTypeDataPurchase.push(
              Math.round(element.Purchase)
            );
            element.Purchase = Math.round(element.Purchase);
          });

          // this._RewardTypeOverview.BankCompareLabel = [];
          // this._RewardTypeOverview.BankCompareDataUsers = [];
          // this._RewardTypeOverview.BankCompareDataTransactions = [];
          // this._RewardTypeOverview.BankCompareDataPurchase = [];
          // this._RewardTypeOverview.AppUsersBank.forEach(element => {
          //   this._RewardTypeOverview.BankCompareLabel.push(element.Name);
          //   this._RewardTypeOverview.AppUsersCardTypeDataPurchase.push(
          //     Math.round(element.Purchase)
          //   );
          //   element.Purchase = Math.round(element.Purchase);
          // });

          this._RewardTypeOverview.AppUsersBankLabel = [];
          this._RewardTypeOverview.AppUsersBankDataUsers = [];
          this._RewardTypeOverview.AppUsersBankDataTransactions = [];
          this._RewardTypeOverview.AppUsersBankDataPurchase = [];
          if (this._RewardTypeOverview.AppUsersBank != undefined) {
            this._RewardTypeOverview.AppUsersBank.forEach((element) => {
              element.Purchase = Math.round(element.Purchase);
              if (element.Users > 0) {
                this._RewardTypeOverview.AppUsersBankLabel.push(element.Name);
                this._RewardTypeOverview.AppUsersBankDataUsers.push(
                  element.Users
                );
                this._RewardTypeOverview.AppUsersBankDataTransactions.push(
                  element.Transactions
                );
                this._RewardTypeOverview.AppUsersBankDataPurchase.push(
                  Math.round(element.Purchase)
                );
              }
            });
          }

          this._RewardTypeOverview.AppUsersBankLabel = [];
          this._RewardTypeOverview.AppUsersBankDataUsers = [];
          this._RewardTypeOverview.AppUsersBankDataTransactions = [];
          this._RewardTypeOverview.AppUsersBankDataPurchase = [];
          if (this._RewardTypeOverview.AppUsersBank != undefined) {
            this._RewardTypeOverview.AppUsersBank.forEach((element) => {
              element.Purchase = Math.round(element.Purchase);
              if (element.Users > 0) {
                this._RewardTypeOverview.AppUsersBankLabel.push(element.Name);
                this._RewardTypeOverview.AppUsersBankDataUsers.push(
                  element.Users
                );
                this._RewardTypeOverview.AppUsersBankDataTransactions.push(
                  element.Transactions
                );
                this._RewardTypeOverview.AppUsersBankDataPurchase.push(
                  Math.round(element.Purchase)
                );
              }
            });
          }

          this._RewardTypeOverview.AppUsersBankLabel = [];
          this._RewardTypeOverview.AppUsersBankDataUsers = [];
          this._RewardTypeOverview.AppUsersBankDataTransactions = [];
          this._RewardTypeOverview.AppUsersBankDataPurchase = [];
          if (this._RewardTypeOverview.AppUsersBank != undefined) {
            this._RewardTypeOverview.AppUsersBank.forEach((element) => {
              element.Purchase = Math.round(element.Purchase);
              if (element.Users > 0) {
                this._RewardTypeOverview.AppUsersBankLabel.push(element.Name);
                this._RewardTypeOverview.AppUsersBankDataUsers.push(
                  element.Users
                );
                this._RewardTypeOverview.AppUsersBankDataTransactions.push(
                  element.Transactions
                );
                this._RewardTypeOverview.AppUsersBankDataPurchase.push(
                  Math.round(element.Purchase)
                );
              }
            });
          }
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetRewardTypeOverviewNext(
    UserAccountKey,
    SubUserAccountKey,
    StartTime,
    EndTime
  ) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getrewardtypeoverview",
      StartTime: StartTime, // new Date(2017, 0, 1, 0, 0, 0, 0),
      EndTime: EndTime, // moment().add(2, 'days'),
      UserAccountKey: UserAccountKey,
      SubUserAccountKey: SubUserAccountKey,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V2.ThankU, Data);
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._RewardTypeOverviewNext = _Response.Result as ORewardTypeOverview;
          this._RewardTypeOverviewNext.RewardTypeLabels = [];
          this._RewardTypeOverviewNext.RewardTypeData = [];
          this._RewardTypeOverviewNext.RewardTypeLabels.push("Cash");
          this._RewardTypeOverviewNext.RewardTypeLabels.push("Card");
          this._RewardTypeOverviewNext.RewardTypeData.push(
            this._RewardTypeOverviewNext.CashRewardTransactions
          );
          this._RewardTypeOverviewNext.RewardTypeData.push(
            this._RewardTypeOverviewNext.CardRewardTransactions
          );

          this._RewardTypeOverviewNext.AppUsersCardTypeLabel = [];
          this._RewardTypeOverviewNext.AppUsersCardTypeDataUsers = [];
          this._RewardTypeOverviewNext.AppUsersCardTypeDataTransactions = [];
          this._RewardTypeOverviewNext.AppUsersCardTypeDataPurchase = [];
          this._RewardTypeOverviewNext.AppUsersCardType.forEach((element) => {
            this._RewardTypeOverviewNext.AppUsersCardTypeLabel.push(
              element.Name
            );
            this._RewardTypeOverviewNext.AppUsersCardTypeDataUsers.push(
              element.Users
            );
            this._RewardTypeOverviewNext.AppUsersCardTypeDataTransactions.push(
              element.Transactions
            );
            this._RewardTypeOverviewNext.AppUsersCardTypeDataPurchase.push(
              Math.round(element.Purchase)
            );
            element.Purchase = Math.round(element.Purchase);
          });

          this._RewardTypeOverviewNext.AppUsersBankLabel = [];
          this._RewardTypeOverviewNext.AppUsersBankDataUsers = [];
          this._RewardTypeOverviewNext.AppUsersBankDataTransactions = [];
          this._RewardTypeOverviewNext.AppUsersBankDataPurchase = [];
          this._RewardTypeOverviewNext.AppUsersBank.forEach((element) => {
            element.Purchase = Math.round(element.Purchase);
            if (element.Users > 0) {
              this._RewardTypeOverviewNext.AppUsersBankLabel.push(element.Name);
              this._RewardTypeOverviewNext.AppUsersBankDataUsers.push(
                element.Users
              );
              this._RewardTypeOverviewNext.AppUsersBankDataTransactions.push(
                element.Transactions
              );
              this._RewardTypeOverviewNext.AppUsersBankDataPurchase.push(
                Math.round(element.Purchase)
              );
            }
          });
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetAccountOverviewSub(UserAccountId, UserAccountKey, SubUserAccId, SubUserAccKey, StartTime, EndTime) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getaccountoverview",
      StartTime: StartTime, // new Date(2017, 0, 1, 0, 0, 0, 0),
      EndTime: EndTime, // moment().add(2, 'days'),
      AccountId: UserAccountId,
      AccountKey: UserAccountKey,
      SubAccountId: SubUserAccId,
      SubAccountKey: SubUserAccKey
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.Ptsp.V3.TUCPtspAnalytics, Data);
    _OResponse.subscribe(
      _Response => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._AccountOverview = _Response.Result as OAccountOverview;
          // this._AccountOverview.TerminalStatus["Unused"] = _Response.Result.TerminalStatus.Inactive;
          if (
            this._AccountOverview.SettlementCredit != undefined &&
            this._AccountOverview.SettlementDebit != undefined
          ) {
            this._AccountOverview.SettlementPending =
              this._AccountOverview.SettlementCredit -
              this._AccountOverview.SettlementDebit;
          }
        } else {
          this.NotifyError(_Response.Message);
        }

      },
      _Error => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetRedeemOverview(UserAccountKey, SubUserAccountKey, StartTime, EndTime) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getredeemoverview",
      StartTime: StartTime, // new Date(2017, 0, 1, 0, 0, 0, 0),
      EndTime: EndTime, // moment().add(2, 'days'),
      UserAccountKey: UserAccountKey,
      SubUserAccountKey: SubUserAccountKey,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V2.ThankU, Data);
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._RedeemOverview = _Response.Result as ORedeemOverview;
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetRedeemOverviewNext(UserAccountKey, SubUserAccountKey, StartTime, EndTime) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getredeemoverview",
      StartTime: StartTime, // new Date(2017, 0, 1, 0, 0, 0, 0),
      EndTime: EndTime, // moment().add(2, 'days'),
      UserAccountKey: UserAccountKey,
      SubUserAccountKey: SubUserAccountKey,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V2.ThankU, Data);
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._RedeemOverviewNext = _Response.Result as ORedeemOverview;
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetAppUsersOverview(UserAccountKey, SubUserAccountKey, StartTime, EndTime) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getappusersoverview",
      StartTime: StartTime, // new Date(2017, 0, 1, 0, 0, 0, 0),
      EndTime: EndTime, // moment().add(2, 'days'),
      UserAccountKey: UserAccountKey,
      ParentKey: SubUserAccountKey,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V2.ThankU, Data);
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._AppUsersOverview = _Response.Result as OAppUsersOverview;
          this._AppUsersOverview.AppUsersLabelsByGender = [];
          this._AppUsersOverview.AppUsersDataByGender = [];
          this._AppUsersOverview.AppUsersLabelsByAgeGroup = [];
          this._AppUsersOverview.AppUsersVisitByAgeGroup = [];
          this._AppUsersOverview.AppUsersCountByAgeGroup = [];
          this._AppUsersOverview.AppUsersPurchaseByAgeGroup = [];

          this._AppUsersOverview.AppUsersLabelsByGender.push("Male");
          this._AppUsersOverview.AppUsersLabelsByGender.push("Female");

          this._AppUsersOverview.AppUsersDataByGender.push(
            this._AppUsersOverview.AppUsersMale
          );
          this._AppUsersOverview.AppUsersDataByGender.push(
            this._AppUsersOverview.AppUsersFemale
          );
          this._AppUsersOverview.AppUsersByAgeGroup.forEach((element) => {
            if (element.Name != "Unknown") {
              this._AppUsersOverview.AppUsersLabelsByAgeGroup.push(
                element.Name
              );
              this._AppUsersOverview.AppUsersVisitByAgeGroup.push(
                element.Visits
              );
              this._AppUsersOverview.AppUsersCountByAgeGroup.push(
                element.Users
              );
              this._AppUsersOverview.AppUsersPurchaseByAgeGroup.push(
                Math.round(element.Purchase)
              );
            }
          });
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetAppUsersOverviewNext(
    UserAccountKey,
    SubUserAccountKey,
    StartTime,
    EndTime
  ) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getappusersoverview",
      StartTime: StartTime, // new Date(2017, 0, 1, 0, 0, 0, 0),
      EndTime: EndTime, // moment().add(2, 'days'),
      UserAccountKey: UserAccountKey,
      ParentKey: SubUserAccountKey,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V2.ThankU, Data);
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._AppUsersOverviewNext = _Response.Result as OAppUsersOverview;
          this._AppUsersOverviewNext.AppUsersLabelsByGender = [];
          this._AppUsersOverviewNext.AppUsersDataByGender = [];
          this._AppUsersOverviewNext.AppUsersLabelsByAgeGroup = [];
          this._AppUsersOverviewNext.AppUsersVisitByAgeGroup = [];
          this._AppUsersOverviewNext.AppUsersCountByAgeGroup = [];
          this._AppUsersOverviewNext.AppUsersPurchaseByAgeGroup = [];

          this._AppUsersOverviewNext.AppUsersLabelsByGender.push("Male");
          this._AppUsersOverviewNext.AppUsersLabelsByGender.push("Female");

          this._AppUsersOverviewNext.AppUsersDataByGender.push(
            this._AppUsersOverviewNext.AppUsersMale
          );
          this._AppUsersOverviewNext.AppUsersDataByGender.push(
            this._AppUsersOverviewNext.AppUsersFemale
          );
          this._AppUsersOverviewNext.AppUsersByAgeGroup.forEach((element) => {
            if (element.Name != "Unknown") {
              this._AppUsersOverviewNext.AppUsersLabelsByAgeGroup.push(
                element.Name
              );
              this._AppUsersOverviewNext.AppUsersVisitByAgeGroup.push(
                element.Visits
              );
              this._AppUsersOverviewNext.AppUsersCountByAgeGroup.push(
                element.Users
              );
              this._AppUsersOverviewNext.AppUsersPurchaseByAgeGroup.push(
                Math.round(element.Purchase)
              );
            }
          });
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetVisitHistory(UserAccountKey, SubUserAccountKey, StartTime, EndTime) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getvisithistory",
      StartTime: StartTime, // new Date(2017, 0, 1, 0, 0, 0, 0),
      EndTime: EndTime, // moment().add(2, 'days'),
      UserAccountKey: UserAccountKey,
      SubUserAccountKey: SubUserAccountKey,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V2.ThankU, Data);
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          var ChartResponse = _Response.Result;
          this._UserOverviewPlot.HourlyVisitLabel = [];
          this._UserOverviewPlot.HourlyVisitData = [];
          ChartResponse.DateRange.forEach((RangeItem) => {
            this._UserOverviewPlot.HourlyVisitLabel.push(
              this.GetTimeS(RangeItem.StartTime)
            );
          });
          var TPlotDataSet = [];
          if (ChartResponse.DateRange.length > 0) {
            var RangeItem = ChartResponse.DateRange[0].Data;
            RangeItem.forEach((element) => {
              var DataSetItem = {
                label: element.Name,
                data: [],
              };
              TPlotDataSet.push(DataSetItem);
            });
            ChartResponse.DateRange.forEach((RangeItem) => {
              var Data = RangeItem.Data;
              Data.forEach((element) => {
                TPlotDataSet[0].data.push(parseInt(element.Value));
                var DataItem = TPlotDataSet.find(
                  (x) => x.label == element.Name
                );
                if (DataItem != undefined) {
                  DataItem.data.push(parseInt(element.Value));
                }
              });
            });
          }
          this._UserOverviewPlot.HourlyVisitData = TPlotDataSet;
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetVisitHistoryNext(UserAccountKey, SubUserAccountKey, StartTime, EndTime) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getvisithistory",
      StartTime: StartTime, // new Date(2017, 0, 1, 0, 0, 0, 0),
      EndTime: EndTime, // moment().add(2, 'days'),
      UserAccountKey: UserAccountKey,
      SubUserAccountKey: SubUserAccountKey,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V2.ThankU, Data);
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          var ChartResponse = _Response.Result;
          this._UserOverviewPlot.HourlyVisitNextLabel = [];
          this._UserOverviewPlot.HourlyVisitNextData = [];
          ChartResponse.DateRange.forEach((RangeItem) => {
            this._UserOverviewPlot.HourlyVisitNextLabel.push(
              this.GetTimeS(RangeItem.StartTime)
            );
          });
          var TPlotDataSet = [];
          if (ChartResponse.DateRange.length > 0) {
            var RangeItem = ChartResponse.DateRange[0].Data;
            RangeItem.forEach((element) => {
              var DataSetItem = {
                label: element.Name,
                data: [],
              };
              TPlotDataSet.push(DataSetItem);
            });
            ChartResponse.DateRange.forEach((RangeItem) => {
              var Data = RangeItem.Data;
              Data.forEach((element) => {
                TPlotDataSet[0].data.push(parseInt(element.Value));
                var DataItem = TPlotDataSet.find(
                  (x) => x.label == element.Name
                );
                if (DataItem != undefined) {
                  DataItem.data.push(parseInt(element.Value));
                }
              });
            });
          }
          this._UserOverviewPlot.HourlyVisitNextData = TPlotDataSet;
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  // Sub AccountOverview Region Start
  GetSubAccountOverviewLite(TodayStartTime, TodayEndTime) {
    return;
    this.IsFormProcessing = true;
    var Data = {
      Task: "getaccountoverview",
      StartTime: TodayStartTime,
      EndTime: TodayEndTime,
      AccountKey: this.UserAccount.AccountKey,
      AccountId: this.UserAccount.AccountId,
      SubAccountId: this.AppConfig.ActiveReferenceId,
      SubAccountKey: this.AppConfig.ActiveReferenceKey,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(
      this.AppConfig.NetworkLocation.V3.Analytics,
      Data
    );
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._SubAccountOverview = _Response.Result as OAccountOverview;
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  // Sub AccountOverview Region End
  SetNetwork() {
    var DeviceSerialNumber = null;
    var _Storage_UserDevice = this.GetStorage(this.AppConfig.Storage.Device);
    if (_Storage_UserOReqH == null) {
      var DeviceInformation = {
        SerialNumber: this.newGuid(),
      };
      this.SaveStorage(this.AppConfig.Storage.Device, DeviceInformation);
    } else {
      DeviceSerialNumber = _Storage_UserDevice.SerialNumber;
    }
    var _Storage_UserOReqH = this.GetStorage(this.AppConfig.Storage.OReqH);
    if (_Storage_UserOReqH == null) {
      var ehcak = btoa(this.AppConfig.AppKey);
      var ehcavk = btoa(this.AppConfig.AppVersionKey);
      var ehctk = btoa("na");
      var ehcudv = btoa(DeviceSerialNumber);
      var ehcudlt = btoa("0");
      var ehcudln = btoa("0");
      var ekuak = "";
      var ehcupk = btoa("0");
      var ehcudk = btoa("0");
      var OReqH = {
        "Content-Type": "application/json; charset=utf-8",
        hcak: ehcak,
        hcavk: ehcavk,
        hctk: ehctk,
        hcudv: ehcudv,
        hcudlt: ehcudlt,
        hcudln: ehcudln,
        hcuak: ekuak,
        hcupk: ehcupk,
        hcudk: ehcudk,
        hcuata: "",
        hcuatp: "",
      };
      this.AppConfig.ClientHeader = OReqH;
      this.SaveStorage(this.AppConfig.Storage.OReqH, OReqH);
    }
  }
  public IconData = {
    url: "assets/img/map-store-marker.png", // gives a data://<value>
    scaledSize: {
      height: 26,
      width: 26,
    },
  };

  PostData(PostLocation, Data): Observable<OResponse> {
    this.AppConfig.ClientHeader.hcuata = "";
    this.AppConfig.ClientHeader.hcuatp = "";
    var NetworkLocation = this.AppConfig.HostConnect + PostLocation + Data.Task;
    this.AppConfig.ClientHeader.hctk = btoa(Data.Task);
    if (
      Data.AuthPin != undefined &&
      Data.AuthPin != null &&
      Data.AuthPin != ""
    ) {
      this.AppConfig.ClientHeader.hcuata = btoa(Data.AuthPin);
    }
    if (
      Data.AuthPassword != undefined &&
      Data.AuthPassword != null &&
      Data.AuthPassword != ""
    ) {
      this.AppConfig.ClientHeader.hcuatp = btoa(Data.AuthPassword);
    }
    let _Headers = new HttpHeaders(this.AppConfig.ClientHeader);
    var ORequest = {
      // zx: btoa(JSON.stringify(Data))))
      zx: btoa(unescape(encodeURIComponent(JSON.stringify(Data))))
    };
    var RequestData = JSON.stringify(ORequest);
    return this._Http
      .post<ONetworkResponse>(NetworkLocation, RequestData, {
        headers: _Headers,
      })
      .pipe(map((_Response) => JSON.parse(atob(_Response.zx)) as OResponse))
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  public SetFirstImageOrNone(files: File[]): void {
    if (files.length > 0) {
      var filesplits: string[] = files[0].name.split('.');
      var reader = new FileReader();

      this._Icon_Cropper_Data = {
        Name: null,
        Content: "",
        Extension: (filesplits.length > 0) ? (filesplits[filesplits.length - 1]) : '',
        TypeCode: null,
        Height: 128,
        Width: 128
      }

      reader.onloadend = (e) => {
        this._Icon_Cropper_Data.Content = reader.result.toString().split(';base64,')[1];
      }

      reader.readAsDataURL(files[0]);

    } else {
      this.Icon_Crop_Clear();
    }

  }

  public _InputFileComponent: InputFileComponent;

  public _SetFirstImageOrNone(files: InputFile[]): void {
    if (files.length > 0) {
      var reader = new FileReader();
      reader.onloadend = (e) => {
        this._Icon_Cropper_BaseImage = reader.result.toString();
      }

      reader.readAsDataURL(files[files.length - 1].file);

    } else {
      this.Icon_Crop_Clear();
    }

  }

  public ChartConfig = {
    SalesTypeColors: [
      {
        backgroundColor: ["#7987a1", "#00cccc", "#0168fa"],
      },

    ],
    CutomerAgeTypeColors: [
      {
        backgroundColor: ["#F10875", "#FFC20A", "#00cccc"],
      },

    ],

    GenderTypeColors: [
      {
        backgroundColor: ["#0168fa", "#F10875"],
      },

    ],
    AppUserTypeColors: [
      {
        backgroundColor: ["#B11A83", "#C0CCDA"],
      },

    ],
    CustRefralTypeColors: [
      {
        backgroundColor: ["#0168fa", "#F10875", "#FFC20A"],
      }
    ],
    CardCashUserTypeColors: [
      {
        backgroundColor: ["#FFC20A", "#0168fa"],
      },

    ],
    SpendTypeColors: [
      {
        backgroundColor: ["#F10875", "#FFC20A", "#00cccc", '#0168fa'],
      },

    ],
    SalesTypeCustomersColors: [
      {
        backgroundColor: ["#00aff0", "#990099"],
      },
    ],
    TransactionStatusDataSetColors: [
      {
        backgroundColor: ["#61c03c"],
      },
      {
        backgroundColor: ["#dc3912"],
      },
    ],
    TransactionStatusColors: [
      {
        backgroundColor: ["#00cccc", "#f10075"],
      },
    ],
    CardTypeColors: [
      {
        backgroundColor: ["#ffc107", "#00cccc", "#f10075", "#0168fa"],
      },
    ],
    Plugins: [pluginDataLabels],
    DoughnetChart: {
      Type: "doughnut",
      Options: {
        responsive: true,
        plugins: {
          datalabels: {
            backgroundColor: "#fff",
            color: "#000000bf",
            borderRadius: "2",
            borderWidth: "1",
            borderColor: "transparent",
            anchor: "center",
            align: "center",
            padding: 2,
            opacity: 0.9,
            display: true,
            font: {
              size: 10,
              weight: 500,
            },
            formatter: (value, ctx) => {
              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
              dataArr.map((data) => {
                sum += data;
              });
              let percentage = ((value * 100) / sum).toFixed(2) + "%";
              // return percentage;
              const label = ctx.chart.data.labels[ctx.dataIndex];
              if (label != undefined) {
                return label + "\n" + percentage;
                // return label;
              } else {
                return value;
              }
            },
          },
        },
        legend: {
          display: false,
          position: "right",
        },
        legendCallback: function (chart) {
          // Return the HTML string here.
        },
      },

      // options: {
      //   elements: {
      //     center: {
      //       text: 'Red is 2/3 the total numbers',
      //       color: '#FF6384', // Default is #000000
      //       fontStyle: 'Arial', // Default is Arial
      //       sidePadding: 20, // Default is 20 (as a percentage)
      //       minFontSize: 20, // Default is 20 (in px), set to false and text will not wrap.
      //       lineHeight: 25 // Default is 25 (in px), used for when text wraps
      //     }
      //   }
      // },
      HalfDoughnetOptions: {
        rotation: 1 * Math.PI,/** This is where you need to work out where 89% is */
        circumference: 1 * Math.PI,/** put in a much smaller amount  so it does not take up an entire semi circle */
        responsive: true,
        cutoutPercentage: 80,
        plugins: {
          datalabels: {
            backgroundColor: "#fff",
            color: "#000000bf",
            borderRadius: "2",
            borderWidth: "1",
            borderColor: "transparent",
            anchor: "center",
            align: "center",
            padding: 0,
            opacity: 0.9,
            display: false,
            font: {
              size: 10,
              weight: 500,
            },
            formatter: (value, ctx) => {
              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
              dataArr.map((data) => {
                sum += data;
              });
              let percentage = ((value * 100) / sum).toFixed(2) + "%";
              // return percentage;
              const label = ctx.chart.data.labels[ctx.dataIndex];
              if (label != undefined) {
                return label + "\n" + percentage;
                // return label;
              } else {
                return value;
              }
            },
          },
        },
        legend: {
          display: false,
          position: "right",
        },
        legendCallback: function (chart) {
          // Return the HTML string here.
        },
      },
    },
    LineChart: {
      layout: {
        padding: {
          top: 16,
        },
      },
      Type: "line",
      Options: {
        responsive: true,
        legend: {
          display: false,
          position: "bottom",
        },
        ticks: {
          autoSkip: false,
        },
        scales: {
          xAxes: [
            {
              // categoryPercentage: 0.98,
              // barPercentage: 0.98,
              display: true,
              maxBarThickness: 50,
              categoryPercentage: 0.4,
              barPercentage: 0.4,
              gridLines: {
                stacked: false,
                display: true,
              },
              ticks: {
                autoSkip: false,
                fontSize: 11,
              },
            },
          ],
          yAxes: [
            {

              maxBarThickness: 50,
              categoryPercentage: 0.4,
              barPercentage: 0.4,
              gridLines: {
                stacked: true,
                display: true,
              },
              ticks: {
                beginAtZero: true,
                fontSize: 11,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            display: false,
            backgroundColor: "#ffffff47",
            color: "#798086",
            borderRadius: "2",
            borderWidth: "1",
            borderColor: "transparent",
            anchor: "end",
            align: "end",
            padding: 2,
            font: {
              size: 9,
              weight: 400,
            },
            formatter: (value, ctx) => {
              const label = ctx.chart.data.labels[ctx.dataIndex];
              if (label != undefined) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              } else {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              }
            },
          },
        },
      },
      OptionsCompare: {
        responsive: true,

        legend: {
          display: false,
          position: "bottom",
        },
        ticks: {
          autoSkip: false,
        },

        scales: {
          xAxes: [
            {
              // categoryPercentage: 0.98,
              // barPercentage: 0.98,
              display: false,
              maxBarThickness: 50,
              categoryPercentage: 0.4,
              barPercentage: 0.4,
              gridLines: {
                stacked: false,
                display: false,
              },
              ticks: {
                autoSkip: false,
                fontSize: 11,
              },
            },
          ],
          yAxes: [
            {
              display: false,
              maxBarThickness: 50,
              categoryPercentage: 0.4,
              barPercentage: 0.4,
              gridLines: {
                stacked: true,
                display: true,
              },
              ticks: {
                beginAtZero: true,
                fontSize: 11,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            display: false,
            backgroundColor: "#ffffff47",
            color: "#798086",
            borderRadius: "2",
            borderWidth: "1",
            borderColor: "transparent",
            anchor: "end",
            align: "end",
            padding: 2,
            font: {
              size: 9,
              weight: 400,
            },
            formatter: (value, ctx) => {
              const label = ctx.chart.data.labels[ctx.dataIndex];
              if (label != undefined) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              } else {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              }
            },
          },
        },
      },
    },
    BarChart: {
      layout: {
        padding: {
          top: 16,

        },
      },
      Type: "bar",
      Options: {
        cornerRadius: 20,
        responsive: true,
        legend: {
          display: false,
          position: "right",
        },
        ticks: {
          autoSkip: false,
        },
        scales: {
          xAxes: [
            {
              categoryPercentage: 0.90,
              barPercentage: 0.90,
              // stacked: true,
              // categoryPercentage: 0.98,
              // barPercentage: 0.98,
              maxBarThickness: 30,
              // categoryPercentage: 0.4,
              // barPercentage: 0.4,
              gridLines: {
                stacked: true,
                display: true,
              },
              ticks: {
                autoSkip: false,
                fontSize: 11,
              },
            },
          ],
          yAxes: [
            {
              // categoryPercentage: 0.4,
              // barPercentage: 0.4,
              // stacked: true,
              gridLines: {
                stacked: true,
                display: true,
              },
              ticks: {
                beginAtZero: true,
                fontSize: 11,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            display: false,
            backgroundColor: "#ffffff47",
            color: "#798086",
            borderRadius: "2",
            borderWidth: "1",
            borderColor: "transparent",
            anchor: "end",
            align: "end",
            padding: 2,
            font: {
              size: 10,
              weight: 500,
            },
            formatter: (value, ctx) => {
              const label = ctx.chart.data.labels[ctx.dataIndex];
              if (label != undefined) {
                return value;
              } else {
                return value;
              }
            },
          },
        },
      },
    },
  };
  _UserOverviewPlot = {
    PieChartPlugins: [pluginDataLabels],
    StartTime: null,
    EndTime: null,
    GuageLight: {
      Type: "arch",
      Append: "%",
      Cap: "round",
      Thick: 6,
      Size: 55,
      Color: "#ffffff",
    },
    PlotChartHeight: 95,
    ChartTypeDoughnet: "doughnut",
    ChartTypeBar: "bar",
    ChartTypeBarHorizontal: "horizontalBar",
    MerchantChartTypeBarHOptions: {
      tooltips: {
        enabled: true,
        callbacks: {
          title: function () {
            return 'Data';
          },


        },
      },
      layout: {
        padding: {
          right: 40
        }
      },
      responsive: true,
      legend: {
        display: false,
        position: 'bottom',
        fullWidth: true,
        labels: {
          boxWidth: 10,
          padding: 50
        }
      },
      scales: {
        xAxes: [
          {
            barPercentage: .5,
            categoryPercentage: .5,

            gridLines: {
              stacked: true,
              display: false,
            },
            ticks: {
              autoSkip: false,
              fontSize: 11,
            },
          },
        ],
        yAxes: [


          {
            barPercentage: 0.75,
            // barThickness: 35,
            gridLines: {
              stacked: true,
              display: false,
            },
          },
        ],
      },
      plugins: {
        datalabels: {
          backgroundColor: "transparent",
          color: "#000000bf",
          borderRadius: "2",
          borderWidth: "1",
          borderColor: "transparent",
          anchor: "end",
          align: "end",
          padding: 2,
          font: {
            size: 10,
            weight: 500,
          },
          formatter: (value, ctx) => {
            const label = ctx.chart.data.labels[ctx.dataIndex];
            if (label != undefined) {
              return value;
            } else {
              return value;
            }
          },
        },
      },
    },
    Cust_TUCChartTypeBarHOptions: {
      tooltips: {
        enabled: true,
        callbacks: {
          title: function () {
            return 'Data';
          },


        },
      },
      responsive: true,
      legend: {
        display: false,
        position: 'bottom',
        fullWidth: true,
        labels: {
          boxWidth: 10,
          padding: 50
        }
      },
      scales: {
        xAxes: [
          {
            barPercentage: .5,
            categoryPercentage: .5,

            gridLines: {
              stacked: true,
              display: true,
            },
            ticks: {
              autoSkip: false,
              fontSize: 11,
            },
          },
        ],
        yAxes: [


          {
            barPercentage: 0.75,
            // barThickness: 35,
            gridLines: {
              stacked: true,
              display: true,
            },
          },
        ],
      },
      plugins: {
        datalabels: {
          backgroundColor: "transparent",
          color: "#000000bf",
          borderRadius: "2",
          borderWidth: "1",
          borderColor: "transparent",
          anchor: "end",
          align: "end",
          padding: 2,
          font: {
            size: 10,
            weight: 500,
          },
          formatter: (value, ctx) => {
            const label = ctx.chart.data.labels[ctx.dataIndex];
            if (label != undefined) {
              return value;
            } else {
              return value;
            }
          },
        },
      },
    },

    ChartTypeBarHOptions: {
      tooltips: {
        enabled: true,
        callbacks: {
          title: function () {
            return 'Data';
          },


        },
      },
      responsive: true,
      legend: {
        display: false,
        position: 'bottom',
        fullWidth: true,
        labels: {
          boxWidth: 10,
          padding: 50
        }
      },
      scales: {
        xAxes: [
          {
            barPercentage: .5,
            categoryPercentage: .5,

            gridLines: {
              stacked: true,
              display: false,
            },
            ticks: {
              autoSkip: false,
              fontSize: 11,
            },
          },
        ],
        yAxes: [


          {
            barPercentage: 0.75,
            // barThickness: 35,
            gridLines: {
              stacked: true,
              display: false,
            },
          },
        ],
      },
      plugins: {
        datalabels: {
          backgroundColor: "transparent",
          color: "#000000bf",
          borderRadius: "2",
          borderWidth: "1",
          borderColor: "transparent",
          anchor: "end",
          align: "end",
          padding: 2,
          font: {
            size: 10,
            weight: 500,
          },
          formatter: (value, ctx) => {
            const label = ctx.chart.data.labels[ctx.dataIndex];
            if (label != undefined) {
              return value;
            } else {
              return value;
            }
          },
        },
      },
    },

    ChartTypeBarHOptionsForRepayment: {
      tooltips: {
        enabled: true,
        callbacks: {
          title: function () {
            return 'Data';
          },


        },
      },
      responsive: true,
      legend: {
        display: true,
        fullWidth: true,
      },
      scales: {
        xAxes: [
          {
            barPercentage: .5,
            categoryPercentage: .5,

            gridLines: {
              stacked: true,
              display: true,
            },
            ticks: {
              beginAtZero: true,
              autoSkip: true,
              fontSize: 11,
            },
          },
        ],
        yAxes: [


          {
            barPercentage: 0.75,
            // barThickness: 35,
            gridLines: {
              stacked: true,
              display: true,
            },
          },
        ],
      },
    },

    CustomerHOptions: {
      responsive: true,
      tooltips: {
        enabled: true,
        callbacks: {
          title: function () {
            return 'Data';
          },


        },
      },
      legend: {
        display: false,
        position: 'bottom',
        fullWidth: true,
        labels: {
          boxWidth: 10,
          padding: 50
        }
      },
      scales: {
        xAxes: [
          {
            barPercentage: .5,
            categoryPercentage: .5,

            gridLines: {
              stacked: true,
              display: false,
            },
            ticks: {
              autoSkip: false,
              fontSize: 11,
            },
          },
        ],
        yAxes: [


          {
            barPercentage: 0.75,
            // barThickness: 35,
            gridLines: {
              stacked: true,
              display: false,
            },
          },
        ],
      },
    },

    bnplchartOptions: {
      responsive: true,
      tooltips: {
        enabled: true,
        callbacks: {
          title: function () {
            return 'Data';
          },


        },
      },
      legend: {
        display: false,
        position: 'bottom',
        fullWidth: true,
        labels: {
          boxWidth: 10,
          padding: 50
        }
      },
      scales: {
        xAxes: [
          {
            barPercentage: .5,
            categoryPercentage: .5,

            gridLines: {
              stacked: true,
              display: true,
            },
            ticks: {
              autoSkip: false,
              fontSize: 11,
            },
          },
        ],
        yAxes: [
          {
            barPercentage: 0.75,
            // barThickness: 35,
            gridLines: {
              stacked: true,
              display: true,
            },
          },
        ],
      },
    },

    CustomCustomerHOptions: {
      padding: {
        top: 12,
      },
      tooltips: {
        enabled: true
      },
      responsive: true,
      legend: {
        display: false,
        position: 'bottom',
        fullWidth: true,
        labels: {
          boxWidth: 10,
          padding: 50
        }
      },
      scales: {
        xAxes: [{
          display: false,
          stacked: true
        }],
        yAxes: [{
          display: false,
          stacked: true
        }],
      }, // scales
    },


    ChartTypeBarOptions: {
      layout: {
        padding: {
          top: 12,
        },
      },
      responsive: true,
      legend: {
        display: false,
      },
      ticks: {
        autoSkip: false,
      },
      scales: {
        xAxes: [
          {
            categoryPercentage: 0.98,
            barPercentage: 0.98,
            gridLines: {
              stacked: true,
              display: false,
            },
            ticks: {
              autoSkip: false,
              fontSize: 11,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              stacked: true,
              display: false,
            },
          },
        ],
      },
      plugins: {
        datalabels: {
          backgroundColor: "transparent",
          color: "#000000bf",
          borderRadius: "2",
          borderWidth: "1",
          borderColor: "transparent",
          anchor: "end",
          align: "end",
          padding: 2,
          font: {
            size: 10,
            weight: 500,
          },
          formatter: (value, ctx) => {
            const label = ctx.chart.data.labels[ctx.dataIndex];
            if (label != undefined) {
              return value;
            } else {
              return value;
            }
          },
        },
      },
    },
    ChartTypePieOptions: {
      responsive: true,
      plugins: {
        datalabels: {
          backgroundColor: "transparent",
          color: "#000000bf",
          borderRadius: "2",
          borderWidth: "1",
          borderColor: "transparent",
          anchor: "end",
          align: "end",
          padding: 2,
          display: true,
          font: {
            size: 10,
            weight: 500,
          },
          formatter: (value, ctx) => {
            let sum = 0;
            let dataArr = ctx.chart.data.datasets[0].data;
            dataArr.map((data) => {
              sum += data;
            });
            let percentage = ((value * 100) / sum).toFixed(2) + "%";
            // return percentage;
            const label = ctx.chart.data.labels[ctx.dataIndex];
            if (label != undefined) {
              return label + "\n" + percentage;
              // return label;
            } else {
              return value;
            }
          },
        },
      },
      legend: {
        display: true,
        position: "bottom",
      },
      legendCallback: function (chart) {
        // Return the HTML string here.
      },
    },
    Colors: [
      {
        backgroundColor: "#10b759",
      },
      {
        backgroundColor: "#FFC20A",
      },

      {
        backgroundColor: "#dc3545",
      },
      {
        backgroundColor: "#7987a1",
      }
      // {
      //   backgroundColor: "rgb(3, 169, 243)",
      // },
      // {
      //   backgroundColor: "rgb(251, 150, 120)",
      // },
    ],
    SaleColors: [
      {
        backgroundColor: "#10b759",
      },
      {
        backgroundColor: "#00cccc",
      },

      {
        backgroundColor: "#F10875",
      },
      {
        backgroundColor: "#0168fa",
      }

    ],
    AgeSaleColors: [

      {
        backgroundColor: "#0168FA",


      },
      {
        backgroundColor: "#F11775",
      },


    ],
    RewardColors: [
      {
        backgroundColor: "#ffc107",
      },
      {
        backgroundColor: "#00cccc",
      },

      {
        backgroundColor: "#F10875",
      },
      {
        backgroundColor: "#0168fa",
      }

    ],
    VasColors: [
      {
        backgroundColor: "#FFC20A",
      },
      {
        backgroundColor: "#F10875",
      },

      {
        backgroundColor: "#5b47fb",
      },
      {
        backgroundColor: "#0168fa",
      }
      // {
      //   backgroundColor: "rgb(3, 169, 243)",
      // },
      // {
      //   backgroundColor: "rgb(251, 150, 120)",
      // },
    ],
    PieColors: [
      {
        backgroundColor: [
          "rgb(0, 194, 146)",
          "rgb(171, 140, 228)",
          "rgb(255, 180, 99)",
          "rgb(3, 169, 243)",
          "rgb(251, 150, 120)",
        ],
      },
    ],
    ChartTypePlotOptions: {
      fill: false,
      scales: {
        xAxes: [
          {
            gridLines: {
              stacked: true,
              display: false,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              stacked: true,
              display: false,
            },
          },
        ],
      },
      legend: {
        display: true,
        position: "bottom",
      },
      plugins: {
        datalabels: {
          backgroundColor: "transparent",
          color: "#000000bf",
          borderRadius: "2",
          borderWidth: "1",
          borderColor: "transparent",
          anchor: "end",
          align: "end",
          padding: 2,
          font: {
            size: 11,
            weight: 500,
          },
          formatter: (value, ctx) => {
            const label = ctx.chart.data.labels[ctx.dataIndex];
            if (label != undefined) {
              return value;
            } else {
              return value;
            }
          },
        },
      },
    },
    RewardTypeLabel: [],
    RewardTypeData: [],
    GenderLabel: [],
    GenderData: [],
    RegSourceLabel: [],
    RegSourceData: [],
    VisitorLabel: [],
    VisitorData: [],
    RewardLabel: [],
    RewardData: [],
    RedeemData: [],
    RedeemLabel: [],
    PaymentData: [],
    PaymentLabel: [],
    RewardPlotLabel: [],
    RewardPlotDataSet: [],
    RedeemPlotLabel: [],
    RedeemPlotDataSet: [],
    VisitorsPlotLabel: [],
    VisitorsPlotDataSet: [],
    SalePlotLabel: [],
    SalePlotDataSet: [],
    CardUserPlotLabel: [],
    CardUserPlotDataSet: [],
    PaymentsPlotLabel: [],
    PaymentsPlotDataSet: [],
    HourlyVisitLabel: [],
    HourlyVisitData: [],
    HourlySalesData: [],
    HourlyVisitNextLabel: [],
    HourlyVisitNextData: [],
  };
  public _CoreHelper: OCoreHelper = {
    ParentCode: null,
    CreateDate: null,
    CreatedByDisplayName: null,
    CreatedByIconUrl: null,
    CreatedByKey: null,
    Description: null,
    IconUrl: null,
    ModifyByDisplayName: null,
    ModifyByIconUrl: null,
    ModifyByKey: null,
    ModifyDate: null,
    Name: null,
    ParentName: null,
    PosterUrl: null,
    Reference: null,
    ReferenceKey: null,
    Sequence: null,
    StatusCode: null,
    StatusId: null,
    StatusName: null,
    SubParentCode: null,
    SubParentName: null,
    SystemName: null,
    TypeName: null,
    Value: null,
    StatusI: null,
    CreateDateS: null,
    ModifyDateS: null,
  };
  public _CoreCommon: OCoreCommon = {
    LogRequest: null,
    AccountTypeName: null,
    ImageUrl: null,
    ActiveAppVersionKey: null,
    ActiveAppKey: null,
    IpAddress: null,
    Count: 0,
    CreateDate: null,
    LastRequestDateTime: null,
    CreatedByDisplayName: null,
    AccountDisplayName: null,
    CreatedByIconUrl: null,
    CreatedByKey: null,
    Data: null,
    Description: null,
    HelperCode: null,
    HelperName: null,
    IconUrl: null,
    ModifyByDisplayName: null,
    ModifyByIconUrl: null,
    ModifyByKey: null,
    ModifyDate: null,
    Name: null,
    ParentCode: null,
    ParentKey: null,
    ParentName: null,
    PosterUrl: null,
    Reference: null,
    ReferenceKey: null,
    Sequence: null,
    SubItemsCount: null,
    StatusCode: null,
    StatusId: null,
    StatusName: null,
    SubParentCode: null,
    SubParentKey: null,
    SubParentName: null,
    SubValue: null,
    SystemName: null,
    TypeCode: null,
    TypeName: null,
    UserAccountDisplayName: null,
    UserAccountIconUrl: null,
    UserAccountKey: null,
    Value: null,
    StatusI: null,
    StatusB: null,
    StatusC: null,

    CreateDateS: null,
    ModifyDateS: null,
  };
  public _SubCoreCommon: OCoreCommon = {
    AccountTypeName: null,
    Count: 0,
    LogRequest: null,
    CreateDate: null,
    LastRequestDateTime: null,
    CreatedByDisplayName: null,
    CreatedByIconUrl: null,
    CreatedByKey: null,
    Data: null,
    Description: null,
    HelperCode: null,
    HelperName: null,
    IconUrl: null,
    ModifyByDisplayName: null,
    ModifyByIconUrl: null,
    ModifyByKey: null,
    AccountDisplayName: null,
    ModifyDate: null,
    Name: null,
    ParentCode: null,
    ParentKey: null,
    SubItemsCount: null,
    ParentName: null,
    PosterUrl: null,
    ImageUrl: null,
    Reference: null,
    ReferenceKey: null,
    ActiveAppKey: null,
    ActiveAppVersionKey: null,
    Sequence: null,
    StatusCode: null,
    IpAddress: null,
    StatusId: null,
    StatusName: null,
    SubParentCode: null,
    SubParentKey: null,
    SubParentName: null,
    SubValue: null,
    SystemName: null,
    TypeCode: null,
    TypeName: null,
    UserAccountDisplayName: null,
    UserAccountIconUrl: null,
    UserAccountKey: null,
    Value: null,
    StatusI: null,
    StatusB: null,
    StatusC: null,
    CreateDateS: null,
    ModifyDateS: null,
  };
  public _CoreParameter: OCoreParameter = {
    ReferenceId: 0,
    CommonParentCode: null,
    CommonParentKey: null,
    CommonParentName: null,
    Count: 0,
    CreateDate: null,
    CreatedByDisplayName: null,
    CreatedByIconUrl: null,
    CreatedByKey: null,
    Data: null,
    Description: null,
    HelperCode: null,
    HelperName: null,
    IconUrl: null,
    ModifyByDisplayName: null,
    SubItemsCount: null,
    ModifyByIconUrl: null,
    ModifyByKey: null,
    ModifyDate: null,
    Name: null,
    ParentCode: null,
    ParentKey: null,
    ParentName: null,
    PosterUrl: null,
    Reference: null,
    ReferenceKey: null,
    Sequence: null,
    StatusCode: null,
    StatusId: null,
    StatusName: null,
    SubCommonParentCode: null,
    SubCommonParentKey: null,
    SubCommonParentName: null,
    SubParentCode: null,
    SubParentKey: null,
    SubParentName: null,
    SubValue: null,
    SystemName: null,
    TypeCode: null,
    TypeName: null,
    UserAccountDisplayName: null,
    UserAccountIconUrl: null,
    UserAccountKey: null,
    Value: null,
    StatusI: null,
    CreateDateS: null,
    ModifyDateS: null,
  };
  public _SubCoreParameter: OCoreParameter = {
    CommonParentCode: null,
    CommonParentKey: null,
    CommonParentName: null,
    Count: 0,
    CreateDate: null,
    CreatedByDisplayName: null,
    CreatedByIconUrl: null,
    CreatedByKey: null,
    Data: null,
    Description: null,
    HelperCode: null,
    HelperName: null,
    IconUrl: null,
    ModifyByDisplayName: null,
    ModifyByIconUrl: null,
    ModifyByKey: null,
    ModifyDate: null,
    Name: null,
    ParentCode: null,
    ParentKey: null,
    ParentName: null,
    PosterUrl: null,
    SubItemsCount: null,
    Reference: null,
    ReferenceKey: null,
    Sequence: null,
    StatusCode: null,
    StatusId: null,
    StatusName: null,
    SubCommonParentCode: null,
    SubCommonParentKey: null,
    SubCommonParentName: null,
    SubParentCode: null,
    SubParentKey: null,
    SubParentName: null,
    SubValue: null,
    SystemName: null,
    TypeCode: null,
    TypeName: null,
    UserAccountDisplayName: null,
    UserAccountIconUrl: null,
    UserAccountKey: null,
    Value: null,
    StatusI: null,
    CreateDateS: null,
    ModifyDateS: null,
  };
  public _UserAccount: OUserDetails = {
    MerchantDisplayName: null,
    SecondaryEmailAddress: null,
    BankDisplayName: null,
    BankKey: null,
    OwnerName: null,
    SubOwnerAddress: null,
    SubOwnerLatitude: null,
    SubOwnerDisplayName: null,
    SubOwnerKey: null,
    SubOwnerLongitude: null,
    AccessPin: null,
    LastLoginDateS: null,
    AppKey: null,
    AppName: null,
    AppVersionKey: null,
    CreateDate: null,
    CreateDateS: null,
    CreatedByDisplayName: null,
    CreatedByIconUrl: null,
    CreatedByKey: null,
    Description: null,
    IconUrl: null,
    ModifyByDisplayName: null,
    ModifyByIconUrl: null,
    ModifyByKey: null,
    ModifyDate: null,
    ModifyDateS: null,
    PosterUrl: null,
    ReferenceKey: null,
    StatusCode: null,
    StatusI: null,
    StatusId: null,
    StatusName: null,
    AccountCode: null,
    AccountOperationTypeCode: null,
    AccountOperationTypeName: null,
    AccountTypeCode: null,
    AccountTypeName: null,
    Address: null,
    AppVersionName: null,
    ApplicationStatusCode: null,
    ApplicationStatusName: null,
    AverageValue: null,
    CityAreaKey: null,
    CityAreaName: null,
    CityKey: null,
    CityName: null,
    ContactNumber: null,
    CountValue: null,
    CountryKey: null,
    CountryName: null,
    DateOfBirth: null,
    DisplayName: null,
    EmailAddress: null,
    EmailVerificationStatus: null,
    EmailVerificationStatusDate: null,
    FirstName: null,
    GenderCode: null,
    GenderName: null,
    LastLoginDate: null,
    LastName: null,
    Latitude: null,
    Longitude: null,
    MobileNumber: null,
    Name: null,
    NumberVerificationStatus: null,
    NumberVerificationStatusDate: null,
    OwnerDisplayName: null,
    OwnerKey: null,
    Password: null,
    Reference: null,
    ReferralCode: null,
    ReferralUrl: null,
    RegionAreaKey: null,
    RegionAreaName: null,
    RegionKey: null,
    RegionName: null,
    RegistrationSourceCode: null,
    RegistrationSourceName: null,
    RequestKey: null,
    RoleKey: null,
    RoleName: null,
    SecondaryPassword: null,
    SystemPassword: null,
    UserName: null,
    WebsiteUrl: null,

    StateKey: null,
    StateName: null

  };

  public _RewardOverview: ORewardOverview = {
    RewardAmount: 0,
    RewardChargeAmount: 0,
    RewardPurchaseAmount: 0,
    RewardTransactions: 0,
    RewardUserAmount: 0,
    TUCPlusRewardAmount: 0,
    TUCPlusRewardChargeAmount: 0,
    TUCPlusRewardClaimedAmount: 0,
    TUCPlusRewardClaimedTransactions: 0,
    TUCPlusRewardPurchaseAmount: 0,
    TUCPlusRewardTransactions: 0,
    TUCPlusUserRewardAmount: 0,
  };
  public _RewardOverviewNext: ORewardOverview = {
    RewardAmount: 0,
    RewardChargeAmount: 0,
    RewardPurchaseAmount: 0,
    RewardTransactions: 0,
    RewardUserAmount: 0,
    TUCPlusRewardAmount: 0,
    TUCPlusRewardChargeAmount: 0,
    TUCPlusRewardClaimedAmount: 0,
    TUCPlusRewardClaimedTransactions: 0,
    TUCPlusRewardPurchaseAmount: 0,
    TUCPlusRewardTransactions: 0,
    TUCPlusUserRewardAmount: 0,
  };
  public _RewardTypeOverview: ORewardTypeOverview = {
    CardRewardAmount: 0,
    CardRewardPurchaseAmount: 0,
    CardRewardTransactions: 0,
    CashRewardAmount: 0,
    CashRewardPurchaseAmount: 0,
    CashRewardTransactions: 0,
    OtherRewardAmount: 0,
    OtherRewardPurchaseAmount: 0,
    OtherRewardTransactions: 0,
    Transactions: 0,
    RewardTypeData: [],
    RewardTypeLabels: [],

    CardRewardTransactionsPerc: 0,
    CashRewardTransactionsPerc: 0,

    AppUsersBank: [],
    AppUsersBankLabel: [],
    AppUsersBankDataPurchase: [],
    AppUsersBankDataTransactions: [],
    AppUsersBankDataUsers: [],

    BankCompare: [],
    BankCompareLabel: [],
    BankCompareDataUsers: [],
    BankCompareDataTransactions: [],
    BankCompareDataPurchase: [],

    AppUsersCardType: [],
    AppUsersCardTypeLabel: [],
    AppUsersCardTypeDataPurchase: [],
    AppUsersCardTypeDataTransactions: [],
    AppUsersCardTypeDataUsers: [],
  };
  public _RewardTypeOverviewNext: ORewardTypeOverview = {
    CardRewardAmount: 0,
    CardRewardPurchaseAmount: 0,
    CardRewardTransactions: 0,
    CashRewardAmount: 0,
    CashRewardPurchaseAmount: 0,
    CashRewardTransactions: 0,
    OtherRewardAmount: 0,
    OtherRewardPurchaseAmount: 0,
    OtherRewardTransactions: 0,
    RewardTypeData: [],
    RewardTypeLabels: [],
    Transactions: 0,

    CardRewardTransactionsPerc: 0,
    CashRewardTransactionsPerc: 0,

    AppUsersBank: [],
    AppUsersBankLabel: [],
    AppUsersBankDataPurchase: [],
    AppUsersBankDataTransactions: [],
    AppUsersBankDataUsers: [],

    BankCompare: [],
    BankCompareLabel: [],
    BankCompareDataUsers: [],
    BankCompareDataTransactions: [],
    BankCompareDataPurchase: [],

    AppUsersCardType: [],
    AppUsersCardTypeLabel: [],
    AppUsersCardTypeDataPurchase: [],
    AppUsersCardTypeDataTransactions: [],
    AppUsersCardTypeDataUsers: [],
  };
  public _RedeemOverview: ORedeemOverview = {
    RedeemAmount: 0,
    RedeemPurchaseAmount: 0,
    RedeemTransactions: 0,
  };
  public _RedeemOverviewNext: ORedeemOverview = {
    RedeemAmount: 0,
    RedeemPurchaseAmount: 0,
    RedeemTransactions: 0,
  };
  public _AppUsersOverview: OAppUsersOverview = {
    AppUsers: 0,
    AppUsersByAgeGroup: [],
    AppUsersCountByAgeGroup: [],
    AppUsersLabelsByAgeGroup: [],
    AppUsersPurchaseByAgeGroup: [],
    AppUsersVisitByAgeGroup: [],
    AppUsersDataByGender: [],
    AppUsersLabelsByGender: [],
    AppUsersFemale: 0,
    AppUsersMale: 0,
    AppUsersOther: 0,
    OwnAppUsers: 0,
    ReferralAppUsers: 0,
    RepeatingAppUsers: 0,
    UniqueAppUsers: 0,
  };
  public _AppUsersOverviewNext: OAppUsersOverview = {
    AppUsers: 0,
    AppUsersByAgeGroup: [],
    AppUsersCountByAgeGroup: [],
    AppUsersLabelsByAgeGroup: [],
    AppUsersPurchaseByAgeGroup: [],
    AppUsersVisitByAgeGroup: [],
    AppUsersDataByGender: [],
    AppUsersLabelsByGender: [],
    AppUsersFemale: 0,
    AppUsersMale: 0,
    AppUsersOther: 0,
    OwnAppUsers: 0,
    ReferralAppUsers: 0,
    RepeatingAppUsers: 0,
    UniqueAppUsers: 0,
  };
  public _UserCounts: OUserCounts = {
    RewardPercentage: 0,
    ThankUCashPlus: 0,
    ThankUCashPlusBalanceValidity: 0,
    ThankUCashPlusMinRedeemAmount: 0,
    ThankUCashPlusMinTransferAmount: 0,
    TotalCustomerNew: 0,
    TotalCustomerRepeating: 0,
    TotalCustomerUnique: 0,
    TotalAcquirer: 0,
    TotalCashier: 0,
    TotalCustomer: 0,
    TotalManager: 0,
    TotalMerchant: 0,
    TotalPssp: 0,
    TotalPtsp: 0,
    TotalRm: 0,
    TotalStore: 0,
    TotalTerminal: 0,
  };
  public _CardTypeSalesSummary: OCardTypeSalesSummary = {
    CardTypeLabels: [],
    CardTypeData: [],
    CardTypes: [],
  };
  _TerminalStatusCount: OTerminalStatusCount = {
    Total: 0,
    Unused: 0,
    Active: 0,
    Idle: 0,
    Dead: 0,
  };
  public _SalesOverview: OSalesOverview = {
    TotalTransaction: 0,
    TotalTransactionCustomer: 0,
    TotalTransactionInvoiceAmount: 0,
    TotalSuccessfulTransaction: 0,
    TotalSuccessfulTransactionCustomer: 0,
    TotalSuccessfulTransactionInvoiceAmount: 0,
    TotalFailedTransaction: 0,
    TotalFailedTransactionCustomer: 0,
    TotalFailedTransactionInvoiceAmount: 0,
    TotalCardTransaction: 0,
    TotalCardTransactionInvoiceAmount: 0,
    TotalCashTransaction: 0,
    TotalCashTransactionCustomer: 0,
    TotalCashTransactionInvoiceAmount: 0,

    FirstTransactionDate: null,
    LastTransactionDate: null,
    LastTransactionDateD: "",
    TotalCardTransactionCustomer: 0,
    TransactionTypeData: [],
    TransactionTypeLabels: [],
  };
  public _SalesSummary: OSalesSummary = {
    TotalTransaction: 0,
    TotalTransactionCustomer: 0,
    TotalTransactionInvoiceAmount: 0,
    TotalSuccessfulTransaction: 0,
    TotalSuccessfulTransactionCustomer: 0,
    TotalSuccessfulTransactionInvoiceAmount: 0,
    TotalFailedTransaction: 0,
    TotalFailedTransactionCustomer: 0,
    TotalFailedTransactionInvoiceAmount: 0,
    TotalCardTransaction: 0,
    TotalCardTransactionInvoiceAmount: 0,
    TotalCashTransaction: 0,
    TotalCashTransactionCustomer: 0,
    TotalCashTransactionInvoiceAmount: 0,

    FirstTransactionDate: null,
    LastTransactionDate: null,
    LastTransactionDateD: "",
    TotalCardTransactionCustomer: 0,
    TransactionTypeData: [],
    TransactionTypeLabels: [],
  };
  public _RewardsSummary: ORewardsSummary = {
    TotalTransaction: 0,
    TotalTransactionCustomer: 0,
    TotalTransactionInvoiceAmount: 0,

    TotalRewardTransaction: 0,
    TotalRewardTransactionCustomer: 0,
    TotalRewardTransactionAmount: 0,
    TotalRewardTransactionInvoiceAmount: 0,

    TotalTucRewardTransaction: 0,
    TotalTucRewardTransactionCustomer: 0,
    TotalTucRewardTransactionAmount: 0,
    TotalTucRewardTransactionInvoiceAmount: 0,

    TotalTucPlusRewardTransaction: 0,
    TotalTucPlusRewardTransactionCustomer: 0,
    TotalTucPlusRewardTransactionAmount: 0,
    TotalTucPlusRewardTransactionInvoiceAmount: 0,

    TotalTucPlusRewardClaimTransaction: 0,
    TotalTucPlusRewardClaimTransactionCustomer: 0,
    TotalTucPlusRewardClaimTransactionAmount: 0,
    TotalTucPlusRewardClaimTransactionInvoiceAmount: 0,

    TotalRedeemTransaction: 0,
    TotalRedeemTransactionCustomer: 0,
    TotalRedeemTransactionAmount: 0,
    TotalRedeemTransactionInvoiceAmount: 0,

    FirstTransactionDate: null,
    FirstTransactionDateD: "",
    LastTransactionDate: null,
    LastTransactionDateD: "",

    TucBalance: 0,
    TucPlusBalance: 0,
    TucBalanceCredit: 0,
    TucBalanceDebit: 0,
    TucBalanceTransaction: 0,
    TucPlusBalanceCredit: 0,
    TucPlusBalanceDebit: 0,
    TucPlusBalanceTransaction: 0,
    UserBalance: [],
  };

  public _SubAccountOverview: OAccountOverview = {
    TotalTransactions: 0,
    TotalSale: 0,

    TransactionFailed: 0,
    TransactionSuccess: 0,
    UnusedTerminals: 0,
    ReferredAppUsers: 0,
    ReferredAppUsersPurchase: 0,
    ReferredAppUsersVisit: 0,
    ReferredMerchantSale: 0,
    ReferredMerchantVisits: 0,
    ReferredMerchants: 0,
    ReferredReferredStores: 0,
    TerminalsOverview: [],
    StoresOverview: [],
    Merchants: 0,
    Stores: 0,
    Acquirers: 0,
    Terminals: 0,
    Ptsp: 0,
    Pssp: 0,
    Cashiers: 0,
    RewardCards: 0,
    RewardCardsUsed: 0,
    ThankUCashPlus: 0,
    ThankUCashPlusForMerchant: 0,
    ThankUCashPlusBalanceValidity: 0,
    ThankUCashPlusMinRedeemAmount: 0,
    ThankUCashPlusMinTransferAmount: 0,
    RewardPercentage: 0,
    CommissionPercentage: 0,
    Balance: 0,
    CashRewardAmount: 0,
    CashRewardPurchaseAmount: 0,
    CashRewardTransactions: 0,
    CardRewardAmount: 0,
    CardRewardPurchaseAmount: 0,
    CardRewardTransactions: 0,
    RewardTransactions: 0,
    RewardAmount: 0,
    RewardPurchaseAmount: 0,
    RewardLastTransaction: null,
    RedeemTransactions: 0,
    RedeemAmount: 0,
    RedeemPurchaseAmount: 0,
    RedeemLastTransaction: null,
    Transactions: 0,
    TransactionsPercentage: 0,
    NewTransactions: 0,
    NewTransactionsPercentage: 0,
    RepeatingTransactions: 0,
    RepeatingTransactionsPercentage: 0,
    ReferralTransactions: 0,
    ReferralTransactionsPercentage: 0,
    PurchaseAmount: 0,
    PurchaseAmountPercentage: 0,
    NewPurchaseAmount: 0,
    NewPurchaseAmountPercentage: 0,
    RepeatingPurchaseAmount: 0,
    RepeatingPurchaseAmountPercentage: 0,
    ReferralPurchaseAmount: 0,
    ReferralPurchaseAmountPercentage: 0,
    Commission: 0,
    LastCommissionDate: null,
    IssuerCommissionAmount: 0,
    LastIssuerCommissionDate: null,
    CommissionAmount: 0,
    SettlementCredit: 0,
    SettlementDebit: 0,
    AppUsers: 0,
    AppUsersPercentage: 0,
    OwnAppUsers: 0,
    OwnAppUsersPercentage: 0,
    RepeatingAppUsers: 0,
    RepeatingAppUsersPercentage: 0,
    ReferralAppUsers: 0,
    ReferralAppUsersPercentage: 0,
    AppUsersMale: 0,
    AppUsersFemale: 0,
    LastAppUserDate: null,
    LastTransaction: {
      Amount: 0,
      InvoiceAmount: 0,
      MerchantName: "",
      ReferenceId: 0,
      RewardAmount: 0,
      TransactionDate: null,
      TypeName: null,
    },
    GenderLabel: [],
    GenderData: [],
    RewardTypeLabel: [],
    RewardTypeData: [],
    VisitTrendLabel: [],
    VisitTrendData: [],
    AcquirerAmountDistribution: [],
    Charge: 0,
    ClaimedReward: 0,
    ActiveTerminals: 0,
    AppUsersByAge: [],
    AppUsersOther: 0,
    AppUsersPurchaseByAge: [],
    CardRewardPurchaseAmountOther: 0,
    CardRewardTransactionsOther: 0,
    ClaimedRewardTransations: 0,
    Credit: 0,
    DeadTerminals: 0,
    Debit: 0,
    FrequentBuyers: [],
    IdleTerminals: 0,
    LastTransactionDate: null,
    MerchantOverview: [],
    OtherRewardAmount: 0,
    OtherRewardPurchaseAmount: 0,
    OtherRewardTransactions: 0,
    PosOverview: [],
    RewardChargeAmount: 0,
    RewardUserAmount: 0,
    SettlementPending: 0,
    TUCPlusBalance: 0,
    TUCPlusPurchaseAmount: 0,
    TUCPlusReward: 0,
    TUCPlusRewardAmount: 0,
    TUCPlusRewardChargeAmount: 0,
    TUCPlusRewardClaimedAmount: 0,
    TUCPlusRewardClaimedTransactions: 0,
    TUCPlusRewardPurchaseAmount: 0,
    TUCPlusRewardTransactions: 0,
    TUCPlusUserRewardAmount: 0,
    ThankUAmount: 0,
    TransactionIssuerAmountCredit: 0,
    TransactionIssuerAmountDebit: 0,
    TransactionIssuerChargeCredit: 0,
    TransactionIssuerChargeDebit: 0,
    TransactionIssuerTotalCreditAmount: 0,
    TransactionIssuerTotalDebitAmount: 0,
    UniqueAppUsers: 0,
    UserAmount: 0,
  };

  public _AccountOverview: OAccountOverview = {
    TransactionFailed: 0,
    TransactionSuccess: 0,
    UnusedTerminals: 0,
    ReferredAppUsers: 0,
    ReferredAppUsersPurchase: 0,
    ReferredAppUsersVisit: 0,
    ReferredMerchantSale: 0,
    ReferredMerchantVisits: 0,
    ReferredMerchants: 0,
    ReferredReferredStores: 0,
    TerminalsOverview: [],
    StoresOverview: [],
    Merchants: 0,
    Stores: 0,
    Acquirers: 0,
    Terminals: 0,
    Ptsp: 0,
    Pssp: 0,
    Cashiers: 0,
    RewardCards: 0,
    RewardCardsUsed: 0,
    ThankUCashPlus: 0,
    ThankUCashPlusForMerchant: 0,
    ThankUCashPlusBalanceValidity: 0,
    ThankUCashPlusMinRedeemAmount: 0,
    ThankUCashPlusMinTransferAmount: 0,
    RewardPercentage: 0,
    CommissionPercentage: 0,
    Balance: 0,
    CashRewardAmount: 0,
    CashRewardPurchaseAmount: 0,
    CashRewardTransactions: 0,
    CardRewardAmount: 0,
    CardRewardPurchaseAmount: 0,
    CardRewardTransactions: 0,
    RewardTransactions: 0,
    RewardAmount: 0,
    RewardPurchaseAmount: 0,
    RewardLastTransaction: null,
    RedeemTransactions: 0,
    RedeemAmount: 0,
    RedeemPurchaseAmount: 0,
    RedeemLastTransaction: null,
    Transactions: 0,
    TransactionsPercentage: 0,
    NewTransactions: 0,
    NewTransactionsPercentage: 0,
    RepeatingTransactions: 0,
    RepeatingTransactionsPercentage: 0,
    ReferralTransactions: 0,
    ReferralTransactionsPercentage: 0,
    PurchaseAmount: 0,
    PurchaseAmountPercentage: 0,
    NewPurchaseAmount: 0,
    NewPurchaseAmountPercentage: 0,
    RepeatingPurchaseAmount: 0,
    RepeatingPurchaseAmountPercentage: 0,
    ReferralPurchaseAmount: 0,
    ReferralPurchaseAmountPercentage: 0,
    Commission: 0,
    LastCommissionDate: null,
    IssuerCommissionAmount: 0,
    LastIssuerCommissionDate: null,
    CommissionAmount: 0,
    SettlementCredit: 0,
    SettlementDebit: 0,
    AppUsers: 0,
    AppUsersPercentage: 0,
    OwnAppUsers: 0,
    OwnAppUsersPercentage: 0,
    RepeatingAppUsers: 0,
    RepeatingAppUsersPercentage: 0,
    ReferralAppUsers: 0,
    ReferralAppUsersPercentage: 0,
    AppUsersMale: 0,
    AppUsersFemale: 0,
    LastAppUserDate: null,
    LastTransaction: {
      Amount: 0,
      InvoiceAmount: 0,
      MerchantName: "",
      ReferenceId: 0,
      RewardAmount: 0,
      TransactionDate: null,
      TypeName: null,
    },
    GenderLabel: [],
    GenderData: [],
    RewardTypeLabel: [],
    RewardTypeData: [],
    VisitTrendLabel: [],
    VisitTrendData: [],
    AcquirerAmountDistribution: [],
    Charge: 0,
    ClaimedReward: 0,
    ActiveTerminals: 0,
    AppUsersByAge: [],
    AppUsersOther: 0,
    AppUsersPurchaseByAge: [],
    CardRewardPurchaseAmountOther: 0,
    CardRewardTransactionsOther: 0,
    ClaimedRewardTransations: 0,
    Credit: 0,
    DeadTerminals: 0,
    Debit: 0,
    FrequentBuyers: [],
    IdleTerminals: 0,
    LastTransactionDate: null,
    MerchantOverview: [],
    OtherRewardAmount: 0,
    OtherRewardPurchaseAmount: 0,
    OtherRewardTransactions: 0,
    PosOverview: [],
    RewardChargeAmount: 0,
    RewardUserAmount: 0,
    SettlementPending: 0,
    TUCPlusBalance: 0,
    TUCPlusPurchaseAmount: 0,
    TUCPlusReward: 0,
    TUCPlusRewardAmount: 0,
    TUCPlusRewardChargeAmount: 0,
    TUCPlusRewardClaimedAmount: 0,
    TUCPlusRewardClaimedTransactions: 0,
    TUCPlusRewardPurchaseAmount: 0,
    TUCPlusRewardTransactions: 0,
    TUCPlusUserRewardAmount: 0,
    ThankUAmount: 0,
    TransactionIssuerAmountCredit: 0,
    TransactionIssuerAmountDebit: 0,
    TransactionIssuerChargeCredit: 0,
    TransactionIssuerChargeDebit: 0,
    TransactionIssuerTotalCreditAmount: 0,
    TransactionIssuerTotalDebitAmount: 0,
    UniqueAppUsers: 0,
    UserAmount: 0,
    TerminalStatus: null

  };
  public _AccountOverviewNext: OAccountOverview = {
    ReferredAppUsers: 0,
    ReferredAppUsersPurchase: 0,
    ReferredAppUsersVisit: 0,
    ReferredMerchantSale: 0,
    ReferredMerchantVisits: 0,
    ReferredMerchants: 0,
    ReferredReferredStores: 0,
    TerminalsOverview: [],
    StoresOverview: [],
    Merchants: 0,
    Stores: 0,
    Acquirers: 0,
    Terminals: 0,
    Ptsp: 0,
    Pssp: 0,
    Cashiers: 0,
    RewardCards: 0,
    RewardCardsUsed: 0,
    ThankUCashPlus: 0,
    ThankUCashPlusForMerchant: 0,
    ThankUCashPlusBalanceValidity: 0,
    ThankUCashPlusMinRedeemAmount: 0,
    ThankUCashPlusMinTransferAmount: 0,
    RewardPercentage: 0,
    CommissionPercentage: 0,
    Balance: 0,
    CashRewardAmount: 0,
    CashRewardPurchaseAmount: 0,
    CashRewardTransactions: 0,
    CardRewardAmount: 0,
    CardRewardPurchaseAmount: 0,
    CardRewardTransactions: 0,
    RewardTransactions: 0,
    RewardAmount: 0,
    RewardPurchaseAmount: 0,
    RewardLastTransaction: null,
    RedeemTransactions: 0,
    RedeemAmount: 0,
    RedeemPurchaseAmount: 0,
    RedeemLastTransaction: null,
    Transactions: 0,
    TransactionsPercentage: 0,
    NewTransactions: 0,
    NewTransactionsPercentage: 0,
    RepeatingTransactions: 0,
    RepeatingTransactionsPercentage: 0,
    ReferralTransactions: 0,
    ReferralTransactionsPercentage: 0,
    PurchaseAmount: 0,
    PurchaseAmountPercentage: 0,
    NewPurchaseAmount: 0,
    NewPurchaseAmountPercentage: 0,
    RepeatingPurchaseAmount: 0,
    RepeatingPurchaseAmountPercentage: 0,
    ReferralPurchaseAmount: 0,
    ReferralPurchaseAmountPercentage: 0,
    Commission: 0,
    LastCommissionDate: null,
    IssuerCommissionAmount: 0,
    LastIssuerCommissionDate: null,
    CommissionAmount: 0,
    SettlementCredit: 0,
    SettlementDebit: 0,
    AppUsers: 0,
    AppUsersPercentage: 0,
    OwnAppUsers: 0,
    OwnAppUsersPercentage: 0,
    RepeatingAppUsers: 0,
    RepeatingAppUsersPercentage: 0,
    ReferralAppUsers: 0,
    ReferralAppUsersPercentage: 0,
    AppUsersMale: 0,
    AppUsersFemale: 0,
    LastAppUserDate: null,
    LastTransaction: {
      Amount: 0,
      InvoiceAmount: 0,
      MerchantName: "",
      ReferenceId: 0,
      RewardAmount: 0,
      TransactionDate: null,
      TypeName: null,
    },
    GenderLabel: [],
    GenderData: [],
    RewardTypeLabel: [],
    RewardTypeData: [],
    VisitTrendLabel: [],
    VisitTrendData: [],
    AcquirerAmountDistribution: [],
    Charge: 0,
    ClaimedReward: 0,
    ActiveTerminals: 0,
    AppUsersByAge: [],
    AppUsersOther: 0,
    AppUsersPurchaseByAge: [],
    CardRewardPurchaseAmountOther: 0,
    CardRewardTransactionsOther: 0,
    ClaimedRewardTransations: 0,
    Credit: 0,
    DeadTerminals: 0,
    Debit: 0,
    FrequentBuyers: [],
    IdleTerminals: 0,
    LastTransactionDate: null,
    MerchantOverview: [],
    OtherRewardAmount: 0,
    OtherRewardPurchaseAmount: 0,
    OtherRewardTransactions: 0,
    PosOverview: [],
    RewardChargeAmount: 0,
    RewardUserAmount: 0,
    SettlementPending: 0,
    TUCPlusBalance: 0,
    TUCPlusPurchaseAmount: 0,
    TUCPlusReward: 0,
    TUCPlusRewardAmount: 0,
    TUCPlusRewardChargeAmount: 0,
    TUCPlusRewardClaimedAmount: 0,
    TUCPlusRewardClaimedTransactions: 0,
    TUCPlusRewardPurchaseAmount: 0,
    TUCPlusRewardTransactions: 0,
    TUCPlusUserRewardAmount: 0,
    ThankUAmount: 0,
    TransactionIssuerAmountCredit: 0,
    TransactionIssuerAmountDebit: 0,
    TransactionIssuerChargeCredit: 0,
    TransactionIssuerChargeDebit: 0,
    TransactionIssuerTotalCreditAmount: 0,
    TransactionIssuerTotalDebitAmount: 0,
    UniqueAppUsers: 0,
    UserAmount: 0,
  };

  public _DateRangeDifference(DatePrev: string, DateNex: string): any {
    var _Diff: any = {};

    var seconds = Math.floor(
      (Date.parse(DateNex).valueOf() - Date.parse(DatePrev).valueOf()) / 1000
    );
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    _Diff.days = Math.floor(hours / 24);

    _Diff.hours = hours - _Diff.days * 24;
    _Diff.minutes = minutes - _Diff.days * 24 * 60 - hours * 60;
    _Diff.seconds =
      seconds - _Diff.days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
    return _Diff;
  }

  JData = [
    {
      featureType: "administrative.locality",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "administrative.locality",
      elementType: "geometry.fill",
      stylers: [
        {
          saturation: "100",
        },
        {
          lightness: "-53",
        },
      ],
    },
    {
      featureType: "landscape.man_made",
      elementType: "geometry.fill",
      stylers: [
        {
          visibility: "on",
        },
        {
          color: "#efefe9",
        },
      ],
    },
    {
      featureType: "landscape.natural",
      elementType: "geometry.fill",
      stylers: [
        {
          visibility: "on",
        },
        {
          color: "#ebe6e1",
        },
        {
          lightness: "0",
        },
      ],
    },
    {
      featureType: "landscape.natural.landcover",
      elementType: "geometry.fill",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "landscape.natural.terrain",
      elementType: "geometry.fill",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#cdec9d",
        },
      ],
    },
    {
      featureType: "poi.attraction",
      elementType: "geometry",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.attraction",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#ff0000",
        },
      ],
    },
    {
      featureType: "poi.business",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.business",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#ee9334",
        },
      ],
    },
    {
      featureType: "poi.government",
      elementType: "geometry.fill",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
        {
          lightness: "-22",
        },
        {
          saturation: "15",
        },
        {
          color: "#b8a1cd",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "on",
        },
        {
          saturation: "0",
        },
        {
          lightness: "-25",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [
        {
          visibility: "on",
        },
        {
          lightness: "-82",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.text.stroke",
      stylers: [
        {
          visibility: "on",
        },
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [
        {
          saturation: "0",
        },
        {
          lightness: "0",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [
        {
          saturation: "1",
        },
        {
          lightness: "11",
        },
        {
          gamma: "2.60",
        },
        {
          color: "#aa85e5",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          gamma: "6.13",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
        {
          saturation: "75",
        },
        {
          color: "#818fe6",
        },
        {
          lightness: "14",
        },
        {
          gamma: "1.61",
        },
      ],
    },
  ];

  GoogleAddressArrayToJson(address_components: any): any {
    var addressJson: any = {};
    for (let index = 0; index < address_components.length; index++) {
      const element = address_components[index];
      addressJson[element.types[0]] = element.long_name;
    }
    return addressJson;
  }

  //#region TerminalSorting

  POSActivityStatus: any = {
    Active: "active", //Last Transaction in last 7 days
    Idle: "idle", //Last transcation  before last 7 days
    Dead: "dead", //Last transaction before 1 month
    UnUsed: "unused", //no transaction on this terminal
  };

  SortedTerminals: any = {
    All: [],
    Active: [], //Last Transaction in last 7 days
    Idle: [], //Last transcation  before last 7 days
    Dead: [], //Last transaction before 1 month
    UnUsed: [], //no transaction on this terminal
  };

  ResetSortedTerminals(): void {
    this.SortedTerminals.Active = [];
    this.SortedTerminals.Idle = [];
    this.SortedTerminals.Dead = [];
    this.SortedTerminals.UnUsed = [];
  }

  public SortTerminals(Data: any[]): void {
    this.ResetSortedTerminals();

    this.SortedTerminals.All = Data;

    var SevenDayBefore = moment().startOf("day").subtract(6, "d");
    var ThirtyDayBefore = moment().startOf("day").subtract(30, "d");

    for (let index = 0; index < Data.length; index++) {

      const TClone = cloneDeep(Data[index]);

      var IsNotLastTransactionDate: boolean = (TClone.LastTransactionDate == undefined) || (TClone.LastTransactionDate == null);

      if (IsNotLastTransactionDate) {
        this.SortedTerminals.UnUsed.push(TClone);
        Data[index].ActivityStatus = "Unused";
        continue;
      }

      var TCloneDate = moment(TClone.LastTransactionDate);

      if (TCloneDate.isBefore(ThirtyDayBefore)) {
        this.SortedTerminals.Dead.push(TClone);
        Data[index].ActivityStatus = this.POSActivityStatus.Dead;
      } else if (TCloneDate.isBefore(SevenDayBefore)) {
        this.SortedTerminals.Idle.push(TClone);
        Data[index].ActivityStatus = this.POSActivityStatus.Idle;
      } else if (TCloneDate.isAfter(SevenDayBefore)) {
        this.SortedTerminals.Active.push(TClone);
        Data[index].ActivityStatus = this.POSActivityStatus.Active;
      } else {
        this.SortedTerminals.UnUsed.push(TClone);
        Data[index].ActivityStatus = this.POSActivityStatus.Unused;
      }
    }

  }

  //#endregion

  detailsUpdate: EventEmitter<any> = new EventEmitter();
  emitDetailsChangeEvent() {
    this.detailsUpdate.emit();
  }
  getDetailsChangeEmitter() {
    return this.detailsUpdate;
  }

  //#region dispatchevent
  isprocessingtoogle: EventEmitter<number> = new EventEmitter();
  toogleFormProcessingFlag(value: boolean): void {
    this.IsFormProcessing = value;
    this.isprocessingtoogle.emit(0);
  }
  //#endregion

  CalculateLastSevenDay(momentdate: any): any[] {
    var DateArray: any[] = [momentdate.startOf("day")];
    var DateArrayFormat: any[] = [
      this.GetDateTimeSChart(moment().startOf("day")),
    ];

    for (let index = 1; index < 7; index++) {
      var element = DateArray[index - 1].subtract(1, "d");
      DateArray.push(element);

      DateArrayFormat.push(this.GetDateTimeSChart(element._d));
    }

    return DateArrayFormat.reverse();
  }

  //#region calculate intermediate dates

  CalculateIntermediateDate(momentDateStart: any, momentDateEnd: any): any[] {

    var intermediatecount: number = 0;
    var currDate = momentDateStart.startOf("day");
    var lastDate = momentDateEnd.startOf("day");

    //#region push start date

    var DateArray: any[] = [moment(momentDateStart).startOf("day")];
    var DateArrayFormat: any[] = [
      this.GetDateTimeSChart(moment(momentDateStart).endOf("day")),
    ];

    //#endregion

    //#region push internal dates

    while (currDate.add(1, "days").diff(lastDate) < 0) {
      intermediatecount += 1;
      DateArray.push(currDate.clone().toDate());
      DateArrayFormat.push(this.GetDateTimeSChart(currDate.clone().toDate()));
    }

    //#endregion

    //#region push final dates

    if (intermediatecount != 0) {
      DateArray.push(lastDate.clone().toDate());
      DateArrayFormat.push(this.GetDateTimeSChart(lastDate.clone().toDate()));
    } else {
      //if start date and end date are different
      if (momentDateStart.startOf("day").isSame(momentDateEnd.startOf("day"))) {
        DateArray.push(lastDate.clone().toDate());
        DateArrayFormat.push(this.GetDateTimeSChart(lastDate.clone().toDate()));
      }
    }

    //#endregion

    return DateArrayFormat;
  }

  //#endregion

  //#region ReloadEventEmitF
  ReloadEventEmit: EventEmitter<number> = new EventEmitter();
  NotifEventEmit: EventEmitter<number> = new EventEmitter();

  CloneJson(Json: any): void {
    try {
      return JSON.parse(JSON.stringify(Json));
    } catch (error) {
      return null;
    }
  }

  deepCopy(sourceObject: any, destinationObject: any): void {

    for (const key in sourceObject) {
      if (typeof sourceObject[key] != "object") {
        destinationObject[key] = sourceObject[key];
      } else {
        destinationObject[key] = {};
        this.deepCopy(sourceObject[key], destinationObject[key]);
      }
    }
  }

  TerminalsBubbleSort(terminals: any[]) {
    let swapped;
    do {
      swapped = false;
      for (let i = 0; i < terminals.length; i++) {

        if (terminals[i + 1] == undefined) {
          continue;
        }

        if (moment(terminals[i].LastTransactionDate).isBefore(moment(terminals[i + 1].LastTransactionDate))) {
          let tmp = terminals[i];
          terminals[i] = terminals[i + 1];
          terminals[i + 1] = tmp;
          swapped = true;
        }
      }
    } while (swapped);

    return terminals;
  }

  public DataReloadEligibility(Type: any): boolean {
    return (Type == this.AppConfig.ListToggleOption.Search
      || Type == this.AppConfig.ListToggleOption.Page
      || Type == this.AppConfig.ListToggleOption.Refresh
      || Type == this.AppConfig.ListToggleOption.Limit);
    ;
  }

  // public AreTwoFilterSnapEqual(FilterSnapClone: any, ElementClone: any): boolean {
  //   try {

  //     return JSON.stringify(lodash.omit(FilterSnapClone, lodash.functions(FilterSnapClone))) ==
  //       JSON.stringify(lodash.omit(ElementClone, lodash.functions(ElementClone)));
  //   } catch (error) {
  //     return false;
  //   }
  // }

  public AreTwoFilterSnapEqual(FilterSnapClone: any, ElementClone: any, print?: string): boolean {
    var FilterSnapClone_C = cloneDeep(FilterSnapClone);
    var ElementClone_C = cloneDeep(ElementClone);

    FilterSnapClone_C.RefreshCount = 0;
    ElementClone_C.RefreshCount = 0;

    FilterSnapClone_C.RefreshData = undefined;
    ElementClone_C.RefreshData = undefined;

    FilterSnapClone_C.TotalRecords = 0;
    ElementClone_C.TotalRecords = 0;

    FilterSnapClone_C.SearchBaseConditions = undefined;
    ElementClone_C.SearchBaseConditions = undefined;

    // ElementClone_C.StatusName = undefined;
    // FilterSnapClone_C.StatusName = undefined;

    if (FilterSnapClone_C.Sort) {
      FilterSnapClone_C.Sort.SortDefaultName = undefined;
    }
    if (ElementClone_C.Sort) {
      ElementClone_C.Sort.SortDefaultName = undefined;
    }

    try {

      var compare = jsonDiff.diff(lodash.omit(FilterSnapClone_C, lodash.functions(FilterSnapClone_C)), lodash.omit(ElementClone_C, lodash.functions(ElementClone_C)));
      if (compare == undefined) {
        return true;
      }
      else {
        return false;
      }

    } catch (error) {
      return false;
    }
  }


  StopClickPropogation(): void {
    $(".dropdown-menu").click(() => {
      event.stopPropagation();
    });

    $(".select2").click(() => {
      setTimeout(() => {
        this.StopClickPropogationSearch();
      }, 50);
    });
  };


  StopClickPropogationTarget(): void {
    $(".target").click(() => {
      event.stopPropagation();
    });
  };

  StopClickPropogationSearch(): void {
    $(".select2-search__field").click(() => {
      event.stopPropagation();
    });
  };

  Toogle_Dataset_Visibility(lineChartData: any, dataset_label: String): void {
    for (let index = 0; index < lineChartData.length; index++) {
      const element = lineChartData[index];
      if (element.label == dataset_label) {
        element.hidden = !element.hidden;
        break;
      }
    }
  }


  GetStoreVisits() {
    var Data = {
      Task: "getaccountsaleshistoryhourly",
      ReferenceId: this.AppConfig.ActiveOwnerId,
      // SearchCondition: this.GetDateCondition('', 'Date', this.StartTime, this.EndTime)
      SearchCondition: this.GetDateCondition('', 'Date', moment(), moment())
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V2.TUCAnalytics, Data);
    _OResponse.subscribe(
      _Response => {
        if (_Response.Status == this.StatusSuccess) {
          var ChartResponse = _Response.Result.Data;
          this._UserOverviewPlot.HourlySalesData = [];
          var TPlotDataSet = [];
          var TSalesPlotDataSet = [];
          var DataSetItemVisit = {
            label: 'Visits',
            data: [],
            lineTension: 0.0,
            fill: false,
          };
          var DataSetItemSale = {
            label: 'Sale',
            data: []
          };
          for (let index = 0; index < 24; index++) {
            var RData = ChartResponse.find(x => x.Hour == index);
            if (RData != undefined && RData != null) {
              DataSetItemSale.data.push(RData.SuccessfulTransactionInvoiceAmount);
            }
            else {
              DataSetItemSale.data.push(0);
            }
          }

          TPlotDataSet.push(DataSetItemVisit);
          TSalesPlotDataSet.push(DataSetItemSale);
          this._UserOverviewPlot.HourlySalesData = TSalesPlotDataSet;
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      _Error => {
        this.HandleException(_Error);
      }
    );
  }

  getPendingRequestCount() {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getpendingcashoutcounts"
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.Console.V3.Co, Data);
    _OResponse.subscribe(
      _Response => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this.pendingReqCount = _Response.Result.Total
        }
        else {
          this.NotifyError(_Response.Message);
        }
      },
      _Error => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      });
  }

  //#region Create Data Array For Day 
  CreateDataArrayForDate() {

  }

  //#endregion

}

interface ONetworkResponse {
  zx: string;
  fx: string;
  vx: string;
}
